import { IconStyle } from 'holberton-school-intranet-api';
import * as React from 'react';
import { CSSProperties, ReactElement, ReactNode, useState } from 'react';

import Icon from './Icon';

interface IProps {
    children: ReactNode;
    className?: string;
    collapsed?: boolean;
    collapsible?: boolean;
    icon?: string;
    iconSpin?: boolean;
    iconStyle?: IconStyle;
    id?: string;
    onExpand?: () => Promise<void>;
    renderActions?: () => ReactElement;
    renderSubtitle?: () => ReactElement;
    style?: CSSProperties;
    title?: string;
    variant?: 'default' | 'primary' | 'info' | 'warning' | 'danger';
}

export default function Panel({
    children,
    className = '',
    collapsed: initialCollapsed = true,
    collapsible = false,
    icon,
    iconSpin,
    iconStyle = 'solid',
    id,
    onExpand,
    renderActions,
    renderSubtitle,
    style = {},
    title,
    variant = 'default',
}: IProps): ReactElement {
    const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);

    const variantClass = variant ? `panel-${variant}` : '';
    const fullClass = `panel ${variantClass} ${className}`;

    const onChevronClick = (): void => {
        if (collapsed && onExpand) {
            onExpand();
            setCollapsed(false);
        }
    };

    return (
        <div className={fullClass} id={id} style={style}>
            {title && (
                <div
                    className={`panel-heading${
                        renderActions ? ' panel-heading-actions' : ''
                    }`}
                >
                    <h3 className="d-inline-flex panel-title">
                        {icon && (
                            <Icon
                                className="panel-title-icon"
                                name={icon}
                                spin={iconSpin}
                                style={iconStyle}
                            />
                        )}
                        {collapsible && (
                            <a
                                aria-controls={`collapse-${id}`}
                                aria-expanded={!collapsed}
                                data-parent={`#${id}`}
                                data-toggle="collapse"
                                href={`#collapse-${id}`}
                                onClick={onChevronClick}
                                role="button"
                            >
                                <Icon
                                    className="mr-2"
                                    fixedWidth
                                    name="chevron-right"
                                />
                            </a>
                        )}
                        <div className="v-stack gap-2 m-auto">
                            <span>{title}</span>
                            {renderSubtitle && (
                                <div className="fs-5">{renderSubtitle()}</div>
                            )}
                        </div>
                    </h3>
                    {renderActions?.()}
                </div>
            )}
            {collapsible ? (
                <div
                    className={`panel-collapse collapse${
                        collapsed ? '' : ' in'
                    }`}
                    id={`collapse-${id}`}
                    role="tabpanel"
                >
                    {children}
                </div>
            ) : (
                children
            )}
        </div>
    );
}
