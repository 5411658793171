import * as React from 'react';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';

interface IProps {
    checked?: boolean;
    disabled?: boolean;
    label?: string;
    name?: string;
    onChange?: (checked: boolean, value: string) => void;
    placeholder?: string;
    type?: 'checkbox' | 'radio';
}

export default function TextCheckbox({
    checked,
    disabled = false,
    label,
    name,
    onChange,
    placeholder,
    type = 'checkbox',
}: IProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>();
    const textInputRef = useRef<HTMLInputElement>();
    const [value, setValue] = useState<string>('');

    const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        onChange?.(e.target.checked, value);
        if (e.target.checked) {
            if (!textInputRef.current) {
                return;
            }
            textInputRef.current.focus();
        }
    };

    const onTextInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        onChange?.(true, e.target.value);
        setValue(e.target.value);
    };

    const onTextInputFocus = (): void => {
        if (!inputRef.current) {
            return;
        }

        inputRef.current.checked = true;
        onChange?.(true, value);
    };

    return (
        <div className={`${type} align-items-center d-flex gap-2 m-0`}>
            <label>
                <input
                    checked={checked}
                    disabled={disabled}
                    name={name}
                    onChange={onInputChange}
                    ref={inputRef}
                    type={type}
                    value={value}
                />
                {label && <span>{label}</span>}
            </label>
            <input
                className="form-control input-sm"
                disabled={disabled}
                onChange={onTextInputChange}
                onFocus={onTextInputFocus}
                placeholder={placeholder}
                ref={textInputRef}
                type="text"
            />
        </div>
    );
}
