import {
    ICorrectionTask,
    ICorrectionTaskFile,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';

import { del } from '../../api/utils';
import { pluralize } from '../../utils';
import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';

interface IProps {
    correctionTask: ICorrectionTask;
    csrfToken: string;
    filesMax: number;
    filesMin: number;
    formats: string;
    maxSize: number;
    maxSizeHuman: string;
    removeFileUrl: string | null;
    submitUrl: string | null;
}

export default function TaskFilesUpload({
    correctionTask,
    csrfToken,
    filesMax,
    filesMin,
    formats,
    maxSize,
    maxSizeHuman,
    removeFileUrl,
    submitUrl,
}: IProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>();
    const [errors, setErrors] = useState<string[]>([]);
    const [files, setFiles] = useState<ICorrectionTaskFile[]>(
        correctionTask.files,
    );
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const formatsHuman = formats
        .split(/,\s*/)
        .map((s) => s.split('/')[1].toUpperCase())
        .join(', ');
    const filesLimitHuman =
        [filesMin, filesMax]
            .filter((x, i, a) => a.indexOf(x) === i)
            .join(' to ') + pluralize(filesMax, ' file', { strOnly: true });

    const readyToSubmit = (): boolean => {
        return errors.length === 0 && selectedFiles.length > 0;
    };

    const onFilesSelected = (e: ChangeEvent<HTMLInputElement>): void => {
        setErrors([]);
        const newErrors = [];

        const inputFiles = e.target.files;
        if (!inputFiles) {
            return;
        }

        const totalFiles = files.length + inputFiles.length;
        if (totalFiles < filesMin || totalFiles > filesMax) {
            newErrors.push(`Please select ${filesLimitHuman}`);
        }

        const filesArray = [];
        for (let i = 0; i < inputFiles.length; i++) {
            const file = inputFiles.item(i);
            if (file.size === 0) {
                newErrors.push(`File "${file.name}" is empty.`);
            }
            if (file.size > maxSize) {
                newErrors.push(`File "${file.name}" is too large.`);
            }
            filesArray.push(file);
        }
        setSelectedFiles(filesArray);

        setErrors(newErrors);
    };

    const onRemoveFile = async (file: ICorrectionTaskFile): Promise<void> => {
        if (!confirm('Are you sure?')) {
            return;
        }

        try {
            const result = await del<ICorrectionTask>(
                removeFileUrl,
                csrfToken,
                { id: file.id },
            );

            setFiles(result.files);
        } catch (error) {
            setErrors([error.message]);
        }
    };

    const onSubmit = async (): Promise<void> => {
        if (!readyToSubmit()) {
            return;
        }

        const formData = new FormData();
        formData.append('authenticity_token', csrfToken);
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('correction_task[files][]', selectedFiles[i]);
        }

        try {
            // Not using the `post` function because we cannot upload files
            // with json content type.
            const result = await fetch(submitUrl, {
                body: formData,
                method: 'POST',
            });

            const payload = await result.json();
            if (payload.error) {
                setErrors([payload.error]);
            } else {
                setFiles((payload as ICorrectionTask).files);
                inputRef.current.value = '';
                setSelectedFiles([]);
            }
        } catch (error) {
            setErrors([error.message]);
        }
    };

    return (
        <div className="vstack gap-3">
            <div>
                <h4 className="m-0">Upload {filesLimitHuman}</h4>
                <span className="text-muted fs-small">
                    {[formatsHuman, `Up to ${maxSizeHuman}`]
                        .filter(Boolean)
                        .join('. ')}
                </span>
            </div>

            {files.length > 0 && (
                <div className="correction-task--files-container">
                    {files.map((file, i) => (
                        <div
                            className="vstack align-items-center gap-2"
                            key={`file-${i}`}
                        >
                            <a href={file.url} rel="noreferrer" target="_blank">
                                {file.thumb_url ? (
                                    <img
                                        alt={file.filename}
                                        loading="lazy"
                                        src={file.thumb_url}
                                    />
                                ) : (
                                    <div className="d-grid align-items-center justify-content-center">
                                        <Icon
                                            name={file.icon_name}
                                            size={'4x'}
                                            style="regular"
                                        />
                                    </div>
                                )}
                            </a>

                            <span className="fs-small text-center text-muted user-select-all">
                                {file.filename}
                            </span>
                            {removeFileUrl && (
                                <button
                                    className="btn btn-link fw-500"
                                    onClick={(): void => {
                                        onRemoveFile(file);
                                    }}
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {submitUrl ? (
                <div className="input-group">
                    <input
                        accept={formats}
                        className="form-control"
                        disabled={files.length >= filesMax}
                        multiple
                        onChange={onFilesSelected}
                        ref={inputRef}
                        type="file"
                    />
                    <div className="input-group-btn">
                        <button
                            className="btn btn-primary"
                            disabled={!readyToSubmit()}
                            onClick={onSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            ) : (
                <div className="alert alert-warning">
                    Submissions are closed
                </div>
            )}

            {errors.length > 0 && (
                <div className="vstack gap-1">
                    {errors.map((error, i) => (
                        <ErrorInline
                            className="m-0"
                            error={error}
                            key={`error-${i}`}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
