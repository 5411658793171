import { ISidebarListBreakProps } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

export default function BreakItem({
    className,
    label,
}: ISidebarListBreakProps): ReactElement {
    return <hr className={className} title={label} />;
}
