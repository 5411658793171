import { INote } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useState } from 'react';

import { post } from '../../api/utils';
import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

interface IProps {
    creatable: boolean;
    createNoteURI: string;
    csrfToken: string;
    notes: INote[];
}

export default function Notes({
    creatable,
    createNoteURI,
    csrfToken,
    notes: initialNotes,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [newNote, setNewNote] = useState<string>('');
    const [notes, setNotes] = useState<INote[]>(initialNotes);

    const onChangeNewNote = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        setNewNote(e.target.value);
    };

    const onCreateNote = async (): Promise<void> => {
        setError(null);
        setLoading(true);

        try {
            const note = await post<INote>(createNoteURI, csrfToken, {
                note: { text: newNote.trim() },
            });
            setNewNote('');
            setNotes((v) => [...v, note]);
        } catch (err) {
            setError(err.message);
        }

        setLoading(false);
    };

    return (
        <div className="list-group">
            {notes.map((note) => (
                <div className="list-group-item" key={note.id}>
                    <pre
                        className="overflow-hidden well"
                        style={{ width: 'fit-content' }}
                    >
                        <code>{note.text}</code>
                    </pre>
                    <small className="align-items-center d-flex gap-2 text-muted">
                        <strong>{note.author.full_name}</strong>
                        <span>•</span>
                        <Tooltip
                            title={moment(note.created_at).format(
                                'MMM Do YYYY, h:mma',
                            )}
                        >
                            {moment(note.created_at).fromNow()}
                        </Tooltip>
                    </small>
                </div>
            ))}

            {creatable && (
                <div className="list-group-item">
                    <textarea
                        className="form-control h-auto"
                        disabled={loading}
                        onChange={onChangeNewNote}
                        placeholder="Add a note ..."
                        rows={4}
                        value={newNote}
                    />

                    <div className="align-items-center d-flex gap-3 justify-content-end mt-2">
                        <button
                            className="btn btn-primary"
                            disabled={loading || newNote.trim().length === 0}
                            onClick={onCreateNote}
                        >
                            <Icon
                                name={loading ? 'spinner' : 'paper-plane'}
                                pulse={loading}
                                text={loading ? 'Adding ...' : 'Add note'}
                            />
                        </button>

                        {error && <ErrorInline error={error} />}
                    </div>
                </div>
            )}
        </div>
    );
}
