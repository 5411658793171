import {
    EventAttendanceStatus,
    IEventRsvp,
    IEventRsvpAttendance,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { put } from '../../../api/utils';
import ErrorInline from '../../common/ErrorInline';
import Icon from '../../common/Icon';

import { IAction, IState, UpdateStatus } from './reducer';

interface IEventAttendanceStatus {
    icon: string;
    value: EventAttendanceStatus;
    variant: string;
}

export const ATTENDANCE_STATUSES: IEventAttendanceStatus[] = [
    { icon: 'check', value: 'Present', variant: 'success' },
    { icon: 'hourglass-half', value: 'Late', variant: 'warning' },
    { icon: 'xmark', value: 'Absent', variant: 'danger' },
];

interface IProps {
    attendance: IEventRsvpAttendance;
    dispatch: (action: IAction) => void;
    index: number;
    rsvp: IEventRsvp;
    state: IState;
}

export default function AttendanceInput({
    attendance,
    dispatch,
    index,
    rsvp,
    state,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const status = ATTENDANCE_STATUSES.find(
        (s) => s.value === attendance.status,
    );
    const otherStatuses = ATTENDANCE_STATUSES.filter(
        (s) => s.value !== attendance.status,
    );

    const onClickStatus = async ({
        value,
    }: IEventAttendanceStatus): Promise<void> => {
        if (loading) {
            return;
        }

        setError(null);
        setLoading(true);
        try {
            await put(attendance.uri, state.csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                event_attendance: { status: value.toLowerCase() },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            dispatch(new UpdateStatus(index, rsvp.id, value));
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    return (
        <>
            <div className="dropdown" style={{ width: 90 }}>
                <span
                    className="align-items-center d-flex dropdown-toggle justify-content-between"
                    data-toggle="dropdown"
                    role="button"
                >
                    <span className="align-items-center d-flex gap-2">
                        <Icon
                            className={loading ? '' : `text-${status.variant}`}
                            fixedWidth
                            name={loading ? 'circle-notch' : status.icon}
                            spin={loading}
                            text={attendance.status}
                        />
                    </span>
                    <Icon name="angle-down" />
                </span>

                <ul className="dropdown-menu" style={{ minWidth: 'auto' }}>
                    {otherStatuses.map((s) => (
                        <li className={loading ? 'disabled' : ''} key={s.value}>
                            <a
                                className="align-items-center d-flex gap-2"
                                onClick={(): Promise<void> => onClickStatus(s)}
                                role="button"
                            >
                                <Icon
                                    className={`text-${s.variant}`}
                                    fixedWidth
                                    name={s.icon}
                                    text={s.value}
                                />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            {error && <ErrorInline error={error} />}
        </>
    );
}
