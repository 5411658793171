import { IEventRsvp } from 'holberton-school-intranet-api';
import * as React from 'react';
import { Key, ReactElement, Reducer, useReducer } from 'react';

import { patch } from '../../../api/utils';
import Icon from '../../common/Icon';
import UniqueFieldForm from '../../common/UniqueFieldForm';
import DataTable from '../../common/data_table/DataTable';
import EventRsvpDataTableCell from '../common/EventRsvpDataTableCell';

import { IAction, IState, UpdateEventRsvps, reducer } from './reducer';

interface IProps {
    canEdit: boolean;
    csrfToken: string;
    eventRsvps: IEventRsvp[];
    scoreWeight: number;
    updateEventURI: string;
    updateScoresURI: string;
}

export default function Score({
    canEdit,
    csrfToken,
    eventRsvps,
    scoreWeight,
    updateEventURI,
    updateScoresURI,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        average:
            eventRsvps.reduce((acc, er) => acc + er.score, 0.0) /
            eventRsvps.length,
        eventRsvps,
        scoreWeight,
    });

    // Callbacks
    const bulkSetScore = async (
        score: string,
        ids: number[],
    ): Promise<void> => {
        // NOTE: Error is caught in the caller
        const res = await patch<IEventRsvp[]>(updateScoresURI, csrfToken, {
            /* eslint-disable @typescript-eslint/camelcase */
            event_rsvp: { score: parseFloat(score) },
            /* eslint-enable @typescript-eslint/camelcase */
            ids,
        });
        dispatch(new UpdateEventRsvps(res));
    };

    // Data table attributes
    const renderCell = (item: IEventRsvp): ReactElement => {
        const rsvp = state.eventRsvps.find((er) => er.id === item.id);

        return (
            <UniqueFieldForm
                canEdit={canEdit}
                csrfToken={csrfToken}
                max={100.0}
                min={0.0}
                name="score"
                onUpdate={(res: IEventRsvp): void =>
                    dispatch(new UpdateEventRsvps([res]))
                }
                requestBodyContainer="event_rsvp"
                step={0.1}
                type="number"
                updateURI={rsvp.uri}
                value={rsvp.score}
            />
        );
    };

    const bulks = [
        {
            hasInput: true,
            onSubmit: bulkSetScore,
            options: {
                max: 100.0,
                min: 0.0,
                required: true,
                step: 0.1,
                type: 'number',
            },
            title: 'Set score',
        },
    ];
    const columns = {
        columns: [1],
        keyExtractor: (col: number): Key => col,
        renderCell,
    };
    const filtering = {
        attribute: 'status',
        values: ['Yes', 'Pending', 'No'],
    };
    const grouping = {
        'Cohort': 'user.batch',
        'School location': 'user.batch.school_location',
    };
    const searchBy = ['user.full_name', 'user.id'];
    const sorting = { ID: 'user.id', Name: 'user.full_name' };

    return (
        <div className="row">
            {/* Left column */}
            <div className="col-sm-12 col-md-9 d-flex flex-column gap-3">
                <DataTable
                    bulks={bulks}
                    columns={columns}
                    csrfToken={csrfToken}
                    filtering={filtering}
                    grouping={grouping}
                    items={state.eventRsvps}
                    keyExtractor={(item: IEventRsvp): Key => item.id}
                    renderItem={(item: IEventRsvp): ReactElement => (
                        <EventRsvpDataTableCell eventRsvp={item} />
                    )}
                    searchBy={searchBy}
                    sorting={sorting}
                />
            </div>

            {/* Right column */}
            <div className="col-sm-12 col-md-3 d-flex flex-column gap-3">
                <div>
                    <h3 className="fs-3 mb-2 mt-0">Customize</h3>
                    <div className="align-items-center d-flex gap-3">
                        <span>Score weight</span>
                        <UniqueFieldForm
                            canEdit={canEdit}
                            csrfToken={csrfToken}
                            min={0.1}
                            name={'score_weight'}
                            requestBodyContainer={'event'}
                            step={0.1}
                            type={'number'}
                            updateURI={updateEventURI}
                            value={state.scoreWeight}
                        />
                    </div>
                </div>

                <hr className="w-full" />

                <div>
                    <h3 className="fs-3 mb-2 mt-0">About</h3>
                    <div className="d-flex flex-column gap-2 text-muted">
                        <span className="align-items-center d-flex gap-2">
                            <Icon fixedWidth name="chart-line" />
                            Average {state.average.toFixed(2)}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
