import { ISurveyQuestionAnswer } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { del } from '../../../api/utils';
import Icon from '../../common/Icon';
import Tooltip from '../../common/Tooltip';
import UniqueFieldForm from '../../common/UniqueFieldForm';

import { IAction, RemoveAnswer, UpdateAnswer } from './reducer';

interface IProps {
    answer: ISurveyQuestionAnswer;
    canEdit: boolean;
    csrfToken: string;
    destroyable: boolean;
    dispatch: (action: IAction) => void;
    type: 'checkbox' | 'radio' | null;
}

export default function AnswerEditor({
    answer,
    canEdit,
    csrfToken,
    destroyable,
    dispatch,
    type,
}: IProps): ReactElement {
    const onDestroyClick = async (): Promise<void> => {
        if (!destroyable || !confirm('Are you sure?')) {
            return;
        }

        await del(answer.uri, csrfToken);
        dispatch(new RemoveAnswer(answer));
    };

    const onUpdateAnswer = (updatedAnswer: ISurveyQuestionAnswer): void => {
        dispatch(new UpdateAnswer({ ...updatedAnswer }));
    };

    return (
        <div className="list-group-item">
            <div className="align-items-center d-flex gap-3">
                {type ? (
                    <input className="m-0" disabled type={type} />
                ) : (
                    <span>{answer.position}.</span>
                )}
                <UniqueFieldForm
                    canEdit={canEdit}
                    csrfToken={csrfToken}
                    maxLength={255}
                    name="title"
                    onUpdate={onUpdateAnswer}
                    requestBodyContainer="survey_question_answer"
                    updateURI={answer.uri}
                    value={answer.title}
                />
                {canEdit && destroyable && (
                    <Tooltip title="Remove answer">
                        <Icon
                            className="text-danger"
                            name="trash"
                            onClick={onDestroyClick}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    );
}
