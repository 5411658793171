import moment from 'moment';
import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import Icon from '../common/Icon';

interface IProps {
    label: string;
    value: number;
}

export default function Time({ label, value }: IProps): ReactElement {
    useEffect(() => {
        // We need to be precise, when you reply to a quiz, 56 minutes left is not an hour left
        moment.relativeTimeThreshold('m', 59);
    }, []);

    return (
        <span>
            <Icon name="clock" style="regular" /> <strong>{label}:</strong>{' '}
            {moment.duration(value, 'minutes').humanize()}
        </span>
    );
}
