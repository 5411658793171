import { ICheatingReport } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { post } from '../../api/utils';
import Icon from '../common/Icon';

import Explorer from './Explorer';
import KimbaComparisons from './kimba_comparisons/KimbaComparisons';

interface IProps {
    canCreate: boolean;
    cheatingReports: ICheatingReport[];
    createCheatingReportURI: string;
    csrfToken: string;
    searchURI: string;
}

export default function Index({
    canCreate,
    cheatingReports: initialCheatingReports,
    createCheatingReportURI,
    csrfToken,
    searchURI,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [cheatingReports, setCheatingReports] = React.useState<
        ICheatingReport[]
    >(initialCheatingReports);
    const [
        selectedCheatingReport,
        setSelectedCheatingReport,
    ] = useState<ICheatingReport | null>(initialCheatingReports[0] ?? null);

    const onCreateCheatingReport = async (): Promise<void> => {
        if (loading || !canCreate) {
            return;
        }

        setError(null);
        setLoading(true);

        try {
            const cheatingReport = await post<ICheatingReport>(
                createCheatingReportURI,
                csrfToken,
                {},
            );
            setCheatingReports((crs) => [cheatingReport, ...crs]);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {error && (
                <div className="alert alert-danger">
                    <span>{error}</span>
                </div>
            )}

            <div className="hstack align-items-start gap-3">
                <Explorer
                    canCreate={canCreate}
                    cheatingReports={cheatingReports}
                    loading={loading}
                    onCreateCheatingReport={onCreateCheatingReport}
                    onSelectCheatingReport={setSelectedCheatingReport}
                    selectedCheatingReport={selectedCheatingReport}
                />

                <div className="flex-grow-1">
                    {selectedCheatingReport ? (
                        <>
                            {selectedCheatingReport.kimba_comparisons.length ===
                            0 ? (
                                <div className="alert alert-info">
                                    <span>
                                        No Kimba comparisons found for this
                                        report.
                                    </span>
                                </div>
                            ) : (
                                <KimbaComparisons
                                    csrfToken={csrfToken}
                                    kimbaComparisons={
                                        selectedCheatingReport.kimba_comparisons
                                    }
                                    searchURI={searchURI}
                                />
                            )}
                        </>
                    ) : (
                        <div className="empty-state fs-5">
                            <Icon name="flag" />
                            <span className="empty-state-title">
                                No report selected
                            </span>
                            {!canCreate && (
                                <span className="empty-state-description">
                                    You are not permitted to create a new
                                    report. If you need one, please contact an
                                    administrator.
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
