import { ILtiResource } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    resource: ILtiResource;
}

export default function ResourceViewer({ resource }: IProps): ReactElement {
    const {
        /* eslint-disable @typescript-eslint/camelcase */
        lti_tool: { auth_url: authURL },
        /* eslint-enable @typescript-eslint/camelcase */
    } = resource;

    return (
        <div className="d-flex flex-column gap-3">
            <iframe
                className="border border-info"
                frameBorder="0"
                height="500"
                src={authURL}
                width="100%"
            />
        </div>
    );
}
