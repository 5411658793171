import {
    IKimbaComparison,
    IKimbaComparisonTask,
    IMatchPage,
} from 'holberton-school-intranet-api';
import produce from 'immer';

import { ISearchResult } from '../../search/SearchBar';

export interface IState {
    currentFile: string;
    currentKC: IKimbaComparison;
    currentTask: IKimbaComparisonTask;
    error: string | null;
    kimbaComparisons: IKimbaComparison[];
    loading: boolean;
    matchPage: IMatchPage | null;
    searchedUser: ISearchResult | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAction {}

export class StartLoading implements IAction {}

export class SetError implements IAction {
    constructor(public error: string | null) {}
}

export class SetMatchPage implements IAction {
    constructor(public page: IMatchPage) {}
}

export class SetCurrentTask implements IAction {
    constructor(public task: IKimbaComparisonTask) {}
}

export class SetCurrentFile implements IAction {
    constructor(public file: string) {}
}

export class SetSearchedUser implements IAction {
    constructor(public searchedUser: ISearchResult | null) {}
}

export default function reducer(state: IState, action: IAction): IState {
    if (action instanceof StartLoading) {
        return produce(state, (draft) => {
            draft.error = null;
            draft.loading = true;
        });
    }

    if (action instanceof SetError) {
        return produce(state, (draft) => {
            draft.error = action.error;
            draft.loading = false;
        });
    }

    if (action instanceof SetMatchPage) {
        return produce(state, (draft) => {
            draft.loading = false;
            draft.matchPage = action.page;
        });
    }

    if (action instanceof SetCurrentTask) {
        return produce(state, (draft) => {
            draft.currentTask = action.task;
            draft.currentKC = draft.kimbaComparisons.find(
                (kc) => kc.task.id === draft.currentTask.id,
            );
            draft.currentFile = draft.currentKC.filename;
        });
    }

    if (action instanceof SetCurrentFile) {
        return produce(state, (draft) => {
            draft.currentFile = action.file;
            draft.currentKC = draft.kimbaComparisons.find(
                (kc) =>
                    kc.task.id === draft.currentTask.id &&
                    kc.filename === draft.currentFile,
            );
        });
    }

    if (action instanceof SetSearchedUser) {
        return produce(state, (draft) => {
            draft.searchedUser = action.searchedUser;
        });
    }

    return state;
}
