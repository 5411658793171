import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { gtmUserCustomEvent } from '../../utils';
import FadeIn from '../common/FadeIn';

import { useContainerContext } from './ContainerContext';
import { notifyNotFocused } from './api-rest-adapter';
import { isInProgress } from './api-utils';

export default function FocusSupervisor(): ReactElement {
    const NOTIFY_ALERT_DURATION_THRESHOLD = 10; // seconds

    const context = useContainerContext();
    const {
        evaluationQuiz,
        notFocusDuration,
        progression,
        setNotFocusDuration,
    } = context;

    const [bluredAt, setBluredAt] = useState<number | null>(null);

    useEffect(() => {
        if (evaluationQuiz.allowExternalAccess) {
            // No need for supervision
            return;
        }

        const onBlur = (): void => {
            if (isInProgress(progression)) {
                gtmUserCustomEvent('evaluation_quiz_on_blur', {
                    evaluationQuizId: evaluationQuiz.id,
                    evaluationQuizQCategory: progression.question.category,
                    evaluationQuizQuestionId: progression.question.id,
                });

                setBluredAt(Date.now());
            }
        };

        const onFocus = async (): Promise<void> => {
            if (isInProgress(progression) && bluredAt !== null) {
                const duration = Math.round((Date.now() - bluredAt) / 1000);

                gtmUserCustomEvent('evaluation_quiz_on_focus', {
                    evaluationQuizId: evaluationQuiz.id,
                    evaluationQuizNotFocusedDuration: duration,
                    evaluationQuizQCategory: progression.question.category,
                    evaluationQuizQuestionId: progression.question.id,
                });

                setNotFocusDuration((prev: number) => {
                    const newValue = prev + duration;
                    // We don't await => send and forget. If it fails the submission of the question
                    // will send the value anyway
                    notifyNotFocused(context, duration);
                    return newValue;
                });
                setBluredAt(null);
            }
        };

        // We cannot use https://developer.mozilla.org/fr/docs/Web/API/Page_Visibility_API
        // because it does not take into account multiple screens
        window.addEventListener('blur', onBlur);
        window.addEventListener('focus', onFocus);

        return (): void => {
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('focus', onFocus);
        };
    }, [progression, bluredAt]);

    return (
        notFocusDuration > NOTIFY_ALERT_DURATION_THRESHOLD && (
            <FadeIn>
                <strong>Reminder:</strong> You are not allowed to access any
                resources for answering these questions
            </FadeIn>
        )
    );
}
