import * as React from 'react';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import Icon from '../common/Icon';

interface IProps {
    children: ReactNode;
    sensitive?: boolean;
    title: string;
}

export default function Card({
    children,
    sensitive = false,
    title,
}: IProps): ReactElement {
    const [censored, setCensored] = useState<boolean>(sensitive);

    useEffect(() => setCensored(sensitive), [sensitive]);

    const toggleCensored = (): void => setCensored((v) => !v);

    return (
        <div
            className="position-relative"
            style={{
                border: '1px solid currentColor',
                borderRadius: '.25em',
                fontSize: '1.175em',
                marginTop: '0.5em',
                padding: '.75em .5em .5em .5em',
            }}
        >
            <div
                className="align-items-center d-flex justify-content-between position-absolute"
                style={{
                    left: '.625em',
                    lineHeight: '1em',
                    padding: '0 .25em',
                    right: '.625em',
                    top: '-.6em',
                    zIndex: 2,
                }}
            >
                <span className="fw-600" style={{ background: 'white' }}>
                    {title}
                </span>
                {sensitive && (
                    <span
                        className="fw-600"
                        onClick={toggleCensored}
                        role="button"
                        style={{ background: 'white' }}
                    >
                        <Icon
                            fixedWidth
                            name={`eye${censored ? '' : '-slash'}`}
                        />
                    </span>
                )}
            </div>
            <div style={{ fontSize: '14px', height: 200, overflowY: 'auto' }}>
                {censored ? (
                    <div
                        className="align-items-center d-flex flex-column gap-3 justify-content-center"
                        style={{
                            background: '#ddd',
                            borderRadius: '.5em',
                            height: '100%',
                        }}
                    >
                        <Icon name="user-secret" size="4x" />
                        <span className="fw-500">Sensitive information</span>
                    </div>
                ) : (
                    children
                )}
            </div>
        </div>
    );
}
