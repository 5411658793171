/* eslint-disable @typescript-eslint/camelcase */
import {
    IBatchPlanningItem,
    IBatchTimeOff,
    IReleaseScope,
    ITimeChartEvent,
    SessionChangeDirection,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { pluralize } from '../../../utils';
import Icon from '../../common/Icon';
import Gantt from '../../common/gantt/Gantt';
import Scheduler from '../../common/scheduler/Scheduler';

import ProjectsTimeline from './timeline/ProjectsTimeline';

interface IProps {
    bpis: IBatchPlanningItem[];
    csrfToken: string;
    dailyWorkHours: number;
    plannerURI: string | null;
    timeOffs: IBatchTimeOff[];
}

export interface ISessionItem {
    bpi: IBatchPlanningItem;
    possibleChangeDirections: SessionChangeDirection[];
}

export interface ISession {
    endDate: string;
    items: ISessionItem[];
    releaseScope: IReleaseScope;
    startDate: string;
    workHours: number;
}

function getSessionItemPossibleChangeDirections(
    sessionsCount: number,
    sessionPos: number,
    itemsCount: number,
    itemPos: number,
): SessionChangeDirection[] {
    const directions: SessionChangeDirection[] = [];

    if (itemPos === 0 && sessionPos !== 0) {
        directions.push('up');
    }
    if (itemPos === itemsCount - 1 && sessionPos !== sessionsCount - 1) {
        directions.push('down');
    }

    return directions;
}

function bpisToSessions(bpis: IBatchPlanningItem[]): ISession[] {
    return bpis
        .reduce<ISession[]>((sessions, bpi) => {
            let session = sessions[sessions.length - 1];

            if (
                !session ||
                !moment(bpi.starts_at).isSame(session.startDate, 'day')
            ) {
                session = {
                    endDate: bpi.ends_at,
                    items: [],
                    releaseScope: bpi.release_scope,
                    startDate: bpi.starts_at,
                    workHours: 0,
                };
                sessions.push(session);
            }

            session.items.push({ bpi, possibleChangeDirections: [] });
            session.workHours += bpi.project.work_hours;

            return sessions;
        }, [])
        .map((session, sessionIndex, sessions) => ({
            ...session,
            items: session.items.map((item, itemIndex) => ({
                ...item,
                possibleChangeDirections: getSessionItemPossibleChangeDirections(
                    sessions.length,
                    sessionIndex,
                    session.items.length,
                    itemIndex,
                ),
            })),
        }));
}

function sessionsForScheduler(
    sessions: ISession[],
    timeOffs: IBatchTimeOff[],
): ITimeChartEvent[] {
    const events = [];
    const sessionWorkHours = (session): number =>
        session.items.reduce((acc, i) => acc + i.bpi.project.work_hours, 0);

    sessions.forEach((session, index) => {
        events.push(
            {
                className: '',
                color: '#00cd8c',
                durationHours: pluralize(sessionWorkHours(session), 'hour'),
                end_date: moment(
                    session.items[session.items.length - 1].bpi.ends_at,
                )
                    .add(1, 'day')
                    .format('DD-MM-YYYY'),
                id: session.startDate,
                start_date: moment(session.items[0].bpi.starts_at).format(
                    'DD-MM-YYYY',
                ),
                text: `Session ${index + 1} - ${sessionWorkHours(session)}h`,
                tooltip: '',
                type: 'task',
            },
            ...session.items.map(
                ({ bpi: { ends_at, id, project, starts_at, uri } }) => ({
                    className: '',
                    color: null,
                    durationHours: pluralize(project.work_hours, 'hour'),
                    end_date: moment(ends_at)
                        .add(1, 'day')
                        .format('DD-MM-YYYY'),
                    id: `${id}`,
                    parent: session.startDate,
                    start_date: moment(starts_at).format('DD-MM-YYYY'),
                    text: project.name,
                    tooltip: '',
                    type: 'task',
                    uri,
                }),
            ),
        );
    });

    timeOffs.forEach((timeOff) => {
        events.push({
            color: '#73C0CF',
            durationHours: pluralize(24, 'hour'),
            end_date: moment(timeOff.end_date)
                .add(1, 'day')
                .format('DD-MM-YYYY'),
            id: `timeOff-${timeOff.id}`,
            start_date: moment(timeOff.start_date).format('DD-MM-YYYY'),
            text: 'Time off',
            type: 'task',
        });
    });

    return events;
}

type Layout = 'Calendar' | 'Gantt' | 'Timeline';

export default function Projects({
    bpis,
    csrfToken,
    dailyWorkHours,
    plannerURI,
    timeOffs,
}: IProps): ReactElement {
    const [sessions, setSessions] = useState<ISession[]>(bpisToSessions(bpis));
    const [layout, setLayout] = useState<Layout>('Gantt');

    const onUpdate = (response: IBatchPlanningItem[]): void => {
        setSessions(bpisToSessions(response));
    };

    return (
        <div>
            <div className="d-flex gap-5 justify-content-end mt-2">
                <div className="btn-group">
                    {[
                        { icon: 'calendar', name: 'Calendar' },
                        { icon: 'chart-bar', name: 'Gantt' },
                        { icon: 'table-columns', name: 'Timeline' },
                    ].map((el) => (
                        <a
                            className={`btn btn-sm btn-${
                                layout === el.name
                                    ? 'primary'
                                    : 'outline-primary'
                            }`}
                            key={el.name}
                            onClick={(): void => setLayout(el.name as Layout)}
                        >
                            <Icon fixedWidth name={el.icon} text={el.name} />
                        </a>
                    ))}
                </div>

                {plannerURI && (
                    <a
                        className="btn btn-sm btn-danger"
                        data-confirm="Are you sure? It will override any manual changes you have made"
                        href={plannerURI}
                    >
                        <Icon name="rotate-left" text="Re-plan" />
                    </a>
                )}
            </div>

            <div className="mt-2">
                {layout === 'Calendar' && (
                    <Scheduler
                        data={sessionsForScheduler(sessions, timeOffs)}
                        endDate={sessions[sessions.length - 1].endDate}
                        startDate={sessions[0].startDate}
                        views={['day', 'week', 'month']}
                    />
                )}

                {layout === 'Gantt' && (
                    <Gantt
                        data={sessionsForScheduler(sessions, timeOffs)}
                        showToolbar={false}
                    />
                )}

                {layout === 'Timeline' && (
                    <ProjectsTimeline
                        csrfToken={csrfToken}
                        dailyWorkHours={dailyWorkHours}
                        onUpdate={onUpdate}
                        sessions={sessions}
                    />
                )}
            </div>
        </div>
    );
}
