import { EventRsvpStatus, IEventRsvp } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import CircleProfilePicture from '../../common/CircleProfilePicture';
import Icon from '../../common/Icon';

const RSVP_VARIANTS: Record<EventRsvpStatus, string> = {
    No: 'danger',
    Pending: 'info',
    Yes: 'success',
};

interface IProps {
    eventRsvp: IEventRsvp;
}

export default function EventRsvpDataTableCell({
    eventRsvp,
}: IProps): ReactElement {
    return (
        <>
            <Icon
                className={`fs-3 text-${RSVP_VARIANTS[eventRsvp.status]}`}
                fixedWidth
                name={
                    eventRsvp.status === 'No'
                        ? 'xmark'
                        : eventRsvp.status === 'Yes'
                        ? 'check'
                        : 'clock'
                }
                style={eventRsvp.status === 'Pending' ? 'regular' : 'solid'}
            />
            <div className="align-items-center d-flex gap-3">
                <CircleProfilePicture
                    border={false}
                    size={36}
                    tooltip={false}
                    user={eventRsvp.user}
                />
                <div className="d-flex flex-column">
                    <a
                        className="fw-500"
                        href={eventRsvp.user.uri}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {eventRsvp.user.full_name}
                    </a>
                    <small className="text-muted">#{eventRsvp.user.id}</small>
                </div>
            </div>
        </>
    );
}
