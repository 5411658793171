import * as React from 'react';
import { ReactElement } from 'react';

export default function ActivityCalendarGridLegend(): ReactElement {
    return (
        <>
            <h3 className="fs-3 mb-2 mt-0">Legend</h3>
            <div className="d-flex calendar-grid text-muted">
                <span className="mr-1 fs-5">Less</span>
                {Array.from(Array(5).keys()).map((c) => (
                    <div className={`activity-tile level-${c}`} key={c}></div>
                ))}
                <span className="ml-1 fs-5">More</span>
            </div>
        </>
    );
}
