import { IMatchUser } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../../common/Icon';

interface IProps {
    matchUser: IMatchUser;
}

export default function UserFileLinks({ matchUser }: IProps): ReactElement {
    return (
        <>
            <a href={matchUser.user_uri}>{matchUser.full_name}</a>{' '}
            <a href={matchUser.github_url} rel="noreferrer" target="_blank">
                <Icon name="github" style="brands" />
            </a>{' '}
            <a href={matchUser.file_uri}>
                <Icon name="download" title="Download source file" />
            </a>
        </>
    );
}
