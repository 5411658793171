import { IUserJourneyWeeklyWorkHabit } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import ActivityTile from './ActivityTile';
import WeeklyWorkHabitSummary from './WeeklyWorkHabitSummary';

export interface IProps {
    weeklyWorkHabit: IUserJourneyWeeklyWorkHabit;
}

export default function WeeklyWorkHabit({
    weeklyWorkHabit,
}: IProps): ReactElement {
    return (
        <>
            <div className="d-flex flex-wrap gap-3 mb-3 weekly-work-habit">
                <WeeklyWorkHabitSummary weeklyWorkHabit={weeklyWorkHabit} />

                <div>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}></th>
                                <th colSpan={2}>Monday</th>
                                <th colSpan={2}>Wednesday</th>
                                <th colSpan={2}>Friday</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from(Array(24).keys()).map((hour) => (
                                <tr key={`hh-${hour}`}>
                                    {hour % 4 === 0 && (
                                        <td className="time-slot" rowSpan={4}>
                                            {(hour + 2)
                                                .toString()
                                                .padStart(2, '0')}
                                            :00
                                        </td>
                                    )}
                                    {Array.from(Array(7).keys()).map(
                                        (weekdayIdx) => (
                                            <td
                                                key={`slot-${weekdayIdx}-${hour}`}
                                            >
                                                <ActivityTile
                                                    activityItem={weeklyWorkHabit.items?.find(
                                                        (i) =>
                                                            i.weekly_slot ===
                                                            (weekdayIdx + 1) *
                                                                100 +
                                                                hour,
                                                    )}
                                                />
                                            </td>
                                        ),
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <small className="float-end text-muted ">
                        Coordinated Universal Time (UTC)
                    </small>
                </div>
            </div>
        </>
    );
}
