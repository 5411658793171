import {
    ISurveyQuestion,
    ISurveyQuestionAnswerSet,
    ISurveyQuestionType,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, Reducer, useReducer } from 'react';

import { post } from '../../../api/utils';
import Icon from '../../common/Icon';

import QuestionEditor from './QuestionEditor';
import reducer, { AddQuestion, IAction, IState } from './reducer';

interface IProps {
    answerSets: ISurveyQuestionAnswerSet[];
    canEdit: boolean;
    createQuestionURI: string;
    csrfToken: string;
    questionTypes: ISurveyQuestionType[];
    surveyQuestions: ISurveyQuestion[];
}

export default function SurveyEditor({
    answerSets,
    canEdit,
    createQuestionURI,
    csrfToken,
    questionTypes,
    surveyQuestions,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        questions: surveyQuestions,
    });

    const onAddQuestion = async (): Promise<void> => {
        const question = await post<ISurveyQuestion>(
            createQuestionURI,
            csrfToken,
            {},
        );
        dispatch(new AddQuestion(question));
    };

    return (
        <div className="d-flex flex-column">
            {state.questions.map((question) => (
                <QuestionEditor
                    answerSets={answerSets}
                    canEdit={canEdit}
                    csrfToken={csrfToken}
                    dispatch={dispatch}
                    key={question.key}
                    question={question}
                    questionTypes={questionTypes}
                />
            ))}

            {canEdit && (
                <div>
                    <button className="btn btn-primary" onClick={onAddQuestion}>
                        <Icon name="plus" text="Add question" />
                    </button>
                </div>
            )}
        </div>
    );
}
