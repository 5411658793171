import React, {
    ReactElement,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

interface ISidebarContextProps {
    isCollapsed: boolean;
    toggleSidebar: () => void;
    collapseSidebar: () => void;
}

interface IProps {
    children: ReactNode;
}

const SidebarContext = createContext<ISidebarContextProps | undefined>(
    undefined,
);

export function SidebarProvider({ children }: IProps): ReactElement {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
        const savedState = localStorage.getItem('sidebar-collapsed');
        const isMobile = window.innerWidth < 768;
        return isMobile || savedState === 'true';
    });

    const toggleSidebar = (): void => setIsCollapsed(!isCollapsed);
    const collapseSidebar = (): void => setIsCollapsed(true);

    const updateViewSize = (): void => {
        const isMobile = window.innerWidth < 768;

        if (isMobile) {
            collapseSidebar();
        }
    };

    useEffect(() => {
        updateViewSize();

        window.addEventListener('resize', updateViewSize);
        return (): void => {
            window.removeEventListener('resize', updateViewSize);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('sidebar-collapsed', String(isCollapsed));
    }, [isCollapsed]);

    return (
        <SidebarContext.Provider
            value={{ collapseSidebar, isCollapsed, toggleSidebar }}
        >
            {children}
        </SidebarContext.Provider>
    );
}

export const useSidebar = (): ISidebarContextProps => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
