/*
 * This component displays a Textbox, placeholder and labels
 * and add/remove the 'disabled' attribute of the given action button.
 *
 * usage in html.erb
 * <%= react_component('common/TextboxGuard', {
 *          actionButtonId: 'the-action-button-id",
 *          ...
 *           }) %>
 *
 * <%= f.submit "My action", id: "the-action-button-id" %>
 */
import * as React from 'react';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

interface IProps {
    actionButtonId: string;
    name: string;
    label: string;
    maxLength: number;
    minLength: number;
    placeholder: string;
    value?: string;
}

const cleanText = (text: string): string => {
    // exclude emojis from the count
    return text.replace(/\p{Extended_Pictographic}/gu, '').trim();
};

const validateLength = (
    text: string,
    minLength: number,
    maxLength: number,
): boolean => {
    text = cleanText(text);
    return text.length >= minLength && text.length <= maxLength;
};

export default function TextboxGuard({
    actionButtonId,
    name,
    label,
    maxLength,
    minLength,
    placeholder,
    value,
}: IProps): ReactElement {
    const [textboxText, setTextboxText] = useState<string>(value || '');

    useEffect(() => {
        if (validateLength(textboxText, minLength, maxLength)) {
            document.getElementById(actionButtonId).removeAttribute('disabled');
        } else {
            document
                .getElementById(actionButtonId)
                .setAttribute('disabled', 'true');
        }
    }, [textboxText]);

    return (
        <div className="form-field">
            <label>{label}</label>
            <span
                className={`char-counter alert alert-${
                    validateLength(textboxText, minLength, maxLength)
                        ? 'success'
                        : 'danger'
                }`}
                style={{ float: 'right', marginBottom: '5px' }}
            >
                {maxLength - cleanText(textboxText).length}
            </span>
            <br />
            <textarea
                className="form-control"
                name={name}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
                    setTextboxText(e.target.value);
                }}
                placeholder={placeholder}
            >
                {value}
            </textarea>
        </div>
    );
}
