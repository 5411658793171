import React, { ReactElement, useEffect } from 'react';

declare global {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        SandTechHub: any;
    }
}

interface IProps {
    sdkJsUrl: string;
}

export default function EhubHeader({ sdkJsUrl }: IProps): ReactElement {
    useEffect(() => {
        const loadSDK = async (): Promise<void> => {
            try {
                const script = document.createElement('script');
                script.type = 'module';
                script.src = sdkJsUrl;

                script.onload = (): void => {
                    const SandTechHub = window.SandTechHub;
                    const ehub = new SandTechHub.SDK('savanna');
                    ehub.mountHeader('ehub-sdk');
                };

                const sdkDiv = document.getElementById('ehub-sdk');
                if (sdkDiv) {
                    sdkDiv.appendChild(script);
                }
            } catch (error) {
                throw new Error('Failed to load the SandTechHub SDK: ' + error);
            }
        };

        loadSDK();
    }, []);

    return (
        <div className="w-full">
            <div className="sdk" id="ehub-root">
                <div id="ehub-sdk"></div>
            </div>
        </div>
    );
}
