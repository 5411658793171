import { EvaluationQuizNavigationWay } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';

import { useContainerContext } from './ContainerContext';
import { navigate } from './api-rest-adapter';
import { isInProgress } from './api-utils';

interface IProps {
    way: EvaluationQuizNavigationWay;
}

export default function NavigationButton({ way }: IProps): ReactElement {
    const context = useContainerContext();
    const { progression, submitting } = context;

    if (!isInProgress(progression)) {
        return null;
    }

    const onClick = async (): Promise<void> => {
        await navigate(context, way);
    };

    let icon: string,
        title = '';
    let show = false;
    switch (way) {
        case 'Beginning':
            icon = 'angles-left';
            title = 'Beginning';
            show = progression.has_prev;
            break;
        case 'LastAnswered':
            icon = 'angles-right';
            title = 'Last answered';
            show = progression.has_next;
            break;
        case 'Next':
            icon = 'angle-right';
            title = 'Next';
            show = progression.has_next;
            break;
        case 'Prev':
            icon = 'angle-left';
            title = 'Previous';
            show = progression.has_prev;
            break;
    }

    return (
        show && (
            <button
                className="btn btn-info"
                disabled={submitting}
                onClick={onClick}
                title={title}
                type="button"
            >
                <Icon name={icon} />
            </button>
        )
    );
}
