import moment from 'moment';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { getTimeZone } from '../../../utils';
import DatetimeInput from '../../common/DatetimeInput';

interface IProps {
    endAt: string;
    startAt: string;
}

export default function StartEnd({
    endAt: initialEndAt,
    startAt: initialStartAt,
}: IProps): ReactElement {
    const [endAt, setEndAt] = useState<string>(initialEndAt);
    const [startAt, setStartAt] = useState<string>(initialStartAt);

    const isValid = (): boolean => {
        return moment(endAt).isAfter(startAt);
    };

    return (
        <div className="d-flex flex-column gap-2">
            <div className="align-items-baseline d-flex flex-wrap gap-3">
                <div className="field">
                    <label htmlFor="event_start_at">Start</label>
                    <DatetimeInput
                        id="event_start_at"
                        name="event[start_at]"
                        onChange={setStartAt}
                        value={startAt}
                    />
                </div>

                <div className={`field${isValid() ? '' : '_with_errors'}`}>
                    <label htmlFor="event_end_at">End</label>
                    <DatetimeInput
                        id="event_end_at"
                        name="event[end_at]"
                        onChange={setEndAt}
                        value={endAt}
                    />
                </div>
            </div>

            <small className="text-muted">{getTimeZone(moment())}</small>
        </div>
    );
}
