import { IUserJourneyActivity } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import { durationToString } from '../../utils';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

export interface IProps {
    activity: IUserJourneyActivity;
}

export default function Summary({ activity }: IProps): ReactElement {
    const lastActivityItem = activity.items
        .filter((i) => i?.user_engagements > 0)
        .pop();

    return (
        <>
            <h3 className="fs-3 mb-2 mt-0">Summary</h3>
            <div className="d-flex flex-column gap-2 text-muted">
                {lastActivityItem && (
                    <span className="align-items-center d-flex gap-2">
                        <Icon fixedWidth name="right-to-bracket" />
                        Last activity:{' '}
                        {moment(lastActivityItem.activity_date).format(
                            'YYYY-MM-DD',
                        )}
                    </span>
                )}

                <span className="align-items-center d-flex gap-2">
                    <Icon fixedWidth name="hourglass-half" />
                    Total duration: {durationToString(activity.total_duration)}
                </span>

                <span className="align-items-center d-flex gap-2">
                    <Icon fixedWidth name="chart-line" />
                    Total user engagements: {activity.total_user_engagements}
                </span>

                {lastActivityItem && (
                    <span className="align-items-center d-flex gap-2">
                        {lastActivityItem && (
                            <Tooltip title="Average duration per day">
                                {lastActivityItem.duration >=
                                activity.average_duration ? (
                                    <Icon
                                        className="text-success"
                                        fixedWidth
                                        name="arrow-up"
                                    />
                                ) : (
                                    <Icon
                                        className="text-error"
                                        fixedWidth
                                        name="arrow-down"
                                    />
                                )}
                            </Tooltip>
                        )}{' '}
                        Avg. duration per day:{' '}
                        {durationToString(activity.average_duration)}
                    </span>
                )}

                {lastActivityItem && (
                    <span className="align-items-center d-flex gap-2">
                        {lastActivityItem && (
                            <Tooltip title="Average user engagements per day">
                                {lastActivityItem.user_engagements >=
                                activity.average_user_engagements ? (
                                    <Icon
                                        className="text-success"
                                        fixedWidth
                                        name="arrow-up"
                                    />
                                ) : (
                                    <Icon
                                        className="text-error"
                                        fixedWidth
                                        name="arrow-down"
                                    />
                                )}
                            </Tooltip>
                        )}{' '}
                        Avg. user engagements:{' '}
                        {activity.average_user_engagements}
                    </span>
                )}
            </div>
        </>
    );
}
