import { IKimbaComparison, IMatchPage } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, Reducer, useEffect, useReducer } from 'react';

import { get } from '../../../api/utils';
import { pluralize } from '../../../utils';
import Icon from '../../common/Icon';
import Pagination from '../../common/Pagination';
import Panel from '../../common/Panel';

import Filters from './Filters';
import Matches from './Matches';
import reducer, {
    IAction,
    IState,
    SetError,
    SetMatchPage,
    StartLoading,
} from './reducer';

interface IProps {
    csrfToken: string;
    kimbaComparisons: IKimbaComparison[];
    searchURI: string;
}

export default function KimbaComparisons({
    csrfToken,
    kimbaComparisons,
    searchURI,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        currentFile: kimbaComparisons[0]?.filename,
        currentKC: kimbaComparisons[0],
        currentTask: kimbaComparisons[0]?.task,
        error: null,
        kimbaComparisons,
        loading: false,
        matchPage: null,
        searchedUser: null,
    });

    const fetchMatches = async (page = 1): Promise<void> => {
        dispatch(new StartLoading());

        /* eslint-disable @typescript-eslint/camelcase */
        const res = await get<IMatchPage>(
            state.currentKC.matches_uri,
            csrfToken,
            { page, user_id: state.searchedUser?.id },
        );
        /* eslint-enable @typescript-eslint/camelcase */

        dispatch(new SetMatchPage(res));
    };

    useEffect(() => {
        try {
            fetchMatches();
        } catch (err) {
            dispatch(new SetError(err.msg));
        }
    }, [state.currentKC, state.searchedUser]);

    return (
        <div className="d-flex flex-column gap-3">
            <Filters
                csrfToken={csrfToken}
                dispatch={dispatch}
                kimbaComparisons={kimbaComparisons}
                searchURI={searchURI}
                state={state}
            />

            <Panel title={pluralize(state.matchPage?.total || 0, 'Similarity')}>
                {state.loading ? (
                    <div className="panel-body">
                        <div className="d-flex justify-content-center p-3 text-center">
                            <Icon name="circle-notch" size="3x" spin />
                        </div>
                    </div>
                ) : (
                    <>
                        {state.matchPage?.matches.length > 0 ? (
                            <Matches state={state} />
                        ) : (
                            <div className="panel-body">
                                <div className="alert alert-info">
                                    No match for this task and this file
                                </div>
                            </div>
                        )}
                    </>
                )}

                {(state.matchPage?.pages || 0) > 1 && (
                    <div className="panel-footer">
                        <Pagination
                            current={state.matchPage.page}
                            disabled={state.loading}
                            onClick={fetchMatches}
                            pages={state.matchPage.pages}
                        />
                    </div>
                )}
            </Panel>
        </div>
    );
}
