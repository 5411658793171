import {
    ISurveyQuestion,
    ISurveyQuestionAnswer,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, ReactElement } from 'react';

import SearchInSelect from '../../common/SearchInSelect';
import TextCheckbox from '../../common/TextCheckbox';

import { FormValue } from './SurveyForm';

interface IProps {
    disabled: boolean;
    onChange: (id: number, value: FormValue) => void;
    question: ISurveyQuestion;
    value: FormValue;
}

export default function QuestionForm({
    disabled,
    onChange,
    question,
    value,
}: IProps): ReactElement {
    const qType =
        question.question_type === 'multiple_choices' ? 'checkbox' : 'radio';

    const isChecked = (answer: ISurveyQuestionAnswer): boolean => {
        if (value === null) {
            return false;
        }
        if (question.question_type === 'multiple_choices') {
            return (value as string[]).includes(answer.title);
        }
        return value === answer.title;
    };

    const isCheckedOther = (): boolean => {
        if (value === null) {
            return false;
        }

        const values =
            question.question_type === 'multiple_choices'
                ? (value as string[])
                : [value as string];
        const titles = question.survey_question_answers.map((a) => a.title);
        const inter = values.filter((v) => titles.includes(v));
        return JSON.stringify(inter) !== JSON.stringify(values);
    };

    const onChangeAnswer = (checked: boolean, val: string): void => {
        if (disabled) {
            return;
        }

        if (question.question_type === 'multiple_choices') {
            const array = value as string[];
            onChange(
                question.id,
                checked ? [...array, val] : array.filter((v) => v !== val),
            );
            return;
        }
        onChange(question.id, checked ? val : null);
    };

    return (
        <div className="panel panel-default overflow-visible">
            <div className="panel-heading">
                <h3 className="panel-title">
                    {question.title}
                    {question.required && (
                        <span className="ml-1" style={{ color: 'red' }}>
                            *
                        </span>
                    )}
                </h3>
                {question.description && (
                    <i className="fs-small">{question.description}</i>
                )}
            </div>

            {question.question_type === 'dropdown' ? (
                <div className="panel-body">
                    <SearchInSelect
                        className="form-control"
                        hasHiddenInput={false}
                        items={question.survey_question_answers.map((a) => ({
                            key: a.key,
                            label: a.title,
                            value: a.title,
                        }))}
                        onChange={(v: string): void => onChangeAnswer(true, v)}
                        placeholder="Select one"
                    />
                </div>
            ) : (
                <div className="list-group">
                    {question.survey_question_answers.map((answer) => (
                        <div className="list-group-item" key={answer.key}>
                            <div className={qType}>
                                <label>
                                    <input
                                        checked={isChecked(answer)}
                                        disabled={disabled}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ): void => {
                                            onChangeAnswer(
                                                e.target.checked,
                                                e.target.value,
                                            );
                                        }}
                                        type={qType}
                                        value={answer.title}
                                    />
                                    {answer.title}
                                </label>
                            </div>
                        </div>
                    ))}

                    {question.question_type === 'single_choice_other' && (
                        <div className="list-group-item">
                            <div className="my-2">
                                <TextCheckbox
                                    checked={isCheckedOther()}
                                    disabled={disabled}
                                    label="Other:"
                                    onChange={onChangeAnswer}
                                    placeholder="Plase specify"
                                    type={qType}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
