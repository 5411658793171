import { ISidebarDiscordProps } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

import { useSidebar } from './SidebarContext';

export default function DiscordItem({
    targetUrl,
    label,
    iconOpts = {},
    linkOpts = {},
}: ISidebarDiscordProps): ReactElement {
    const { isCollapsed } = useSidebar();
    const classes = iconOpts.customClasses || [];
    const discordLink = (
        <a href={targetUrl} {...linkOpts}>
            <div className="icon">
                <Icon
                    className={classes.join(' ')}
                    name="discord"
                    style="brands"
                />
            </div>
            <div
                className="text-muted flex-grow-1 pl-3 overflow-hidden text-nowrap"
                style={{ display: `${isCollapsed ? 'none' : 'block'}` }}
            >
                {label}
            </div>
        </a>
    );

    return (
        <li className="d-flex align-items-center" key={targetUrl}>
            {isCollapsed ? (
                <Tooltip placement="right" title={label}>
                    {discordLink}
                </Tooltip>
            ) : (
                discordLink
            )}
        </li>
    );
}
