import { ISurveyQuestion } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { post } from '../../../api/utils';
import ErrorInline from '../../common/ErrorInline';
import Icon from '../../common/Icon';

import QuestionForm from './QuestionForm';

interface IProps {
    csrfToken: string;
    questions: ISurveyQuestion[];
    submitURI: string;
}

export type FormValue = string | string[] | null;

export default function SurveyForm({
    csrfToken,
    questions,
    submitURI,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [form, setForm] = useState<Record<number, FormValue>>(
        questions.reduce<Record<number, FormValue>>(
            (h, q) => ({
                ...h,
                [q.id]: q.question_type === 'multiple_choices' ? [] : null,
            }),
            {},
        ),
    );
    const [hasSucceeded, setHasSucceeded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isFormValid = (): boolean => {
        Object.values(form).some((v) => v === null);
        return Object.entries(form).every(([id, value]) => {
            const q = questions.find((q) => `${q.id}` === `${id}`);
            if (!q.required) {
                return true;
            }
            if (value === null || value === undefined) {
                return false;
            }
            if (q.question_type === 'multiple_choices') {
                const values = value as string[];
                return values.some(() => true);
            }
            return value !== '';
        });
    };

    const onClickSubmit = async (): Promise<void> => {
        if (!isFormValid()) {
            return;
        }
        setError(null);
        setIsLoading(true);

        try {
            await post(submitURI, csrfToken, { answers: form });
            setHasSucceeded(true);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onQuestionChange = (id: number, value: FormValue): void => {
        setForm((f) => ({ ...f, [id]: value }));
    };

    return (
        <div className="d-flex flex-column gap-3">
            {hasSucceeded ? (
                <div className="alert alert-info">
                    <h3 className="mt-0">Thank you for your answer!</h3>
                    The page will refresh in just a few seconds ...
                </div>
            ) : (
                <>
                    {questions.map((question) => (
                        <QuestionForm
                            disabled={isLoading}
                            key={question.key}
                            onChange={onQuestionChange}
                            question={question}
                            value={form[question.id]}
                        />
                    ))}

                    <div className="align-items-center d-flex gap-3 justify-content-end">
                        {error && (
                            <ErrorInline className="mb-0" error={error} />
                        )}
                        <button
                            className="btn btn-primary"
                            disabled={isLoading || !isFormValid()}
                            onClick={onClickSubmit}
                        >
                            <Icon
                                name={
                                    isLoading ? 'circle-notch' : 'paper-plane'
                                }
                                spin={isLoading}
                                text={isLoading ? 'Submitting ...' : 'Submit'}
                            />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
