import { ICurriculumItemableSearchResult } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { pluralize } from '../../utils';
import Icon from '../common/Icon';

interface IProps {
    item: ICurriculumItemableSearchResult;
    renderActions?: () => ReactElement;
}

export default function CurriculumItemableSummaryCard({
    item,
    renderActions,
}: IProps): ReactElement {
    return (
        <div className="row">
            <div className="col-lg-10 col-sm-12">
                <div className="align-items-center d-flex gap-3">
                    {item.author_id && (
                        <Icon
                            name="lock"
                            title={`This project is visible only by you and your school location staff members (Authored by ${item.author_name})`}
                        />
                    )}

                    {item.online === false && (
                        <Icon
                            name="toggle-off"
                            title="This project is offline"
                        />
                    )}

                    <a
                        className="fw-bold td-none"
                        href={item.uri || item.link}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {item.name || item.label}
                    </a>

                    {item.tags?.length > 0 && (
                        <div className="align-items-center d-flex gap-2">
                            {item.tags.map((tag) => (
                                <span className="label label-default" key={tag}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                <small className="align-items-center d-flex gap-2 text-muted">
                    <span>#{item.id}</span>

                    {item.level && (
                        <>
                            <span>•</span>
                            <Icon name="turn-up" text={item.level} />
                        </>
                    )}
                    {item.work_hours !== undefined && (
                        <>
                            <span>•</span>
                            <Icon
                                name="clock"
                                style="regular"
                                text={pluralize(item.work_hours, 'hour')}
                            />
                        </>
                    )}
                    {item.correction_type && (
                        <>
                            {<span>•</span>}
                            <Icon
                                name="circle-check"
                                text={item.correction_type}
                            />
                        </>
                    )}
                    {item.tasks_count !== undefined && (
                        <>
                            {<span>•</span>}
                            <Icon
                                name="bars-progress"
                                text={pluralize(item.tasks_count, 'task')}
                            />
                        </>
                    )}
                </small>

                {item.curriculums_count > 0 && (
                    <small className="align-items-center d-flex gap-2 text-muted">
                        <>
                            <Icon
                                name="graduation-cap"
                                text={`Used in ${pluralize(
                                    item.curriculums_count,
                                    'curriculum',
                                )}`}
                            />
                        </>
                    </small>
                )}
            </div>

            <div className="col-lg-2 col-sm-12 text-right">
                {renderActions && renderActions()}
            </div>
        </div>
    );
}
