import { EvaluationQuizNavigationWay } from 'holberton-school-intranet-api';
import moment, { Moment } from 'moment';

export function capitalize(value: string): string {
    return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

export function dig<T, Res>(obj: T, key: string): Res {
    return key.split('.').reduce((o, k) => o[k], obj);
}

export function getTimeZone(moment: Moment): string {
    const longName = new Intl.DateTimeFormat(undefined, {
        timeZoneName: 'long',
    })
        .formatToParts(moment.toDate())
        .find((f) => f.type === 'timeZoneName').value;

    return [longName, `(${moment.format('ZZ')})`].join(' ');
}

export function isURL(str: string): boolean {
    let url;

    try {
        url = new URL(str);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

interface IPluralizeOptions {
    strOnly?: boolean;
    suffix?: string;
}

const DEFAULT_PLURALIZE_OPTIONS: IPluralizeOptions = {
    strOnly: false,
    suffix: 's',
};

export function pluralize(
    count: number,
    str: string,
    options?: IPluralizeOptions,
): string {
    const { strOnly, suffix } = { ...DEFAULT_PLURALIZE_OPTIONS, ...options };
    let actualStr = str;
    let actualSuffix = suffix;
    let result = '';

    if (str.endsWith('x')) {
        actualSuffix = 'es';
    } else if (str.endsWith('y')) {
        actualStr = str.slice(0, -1);
        actualSuffix = 'ies';
    }

    if (!strOnly) {
        result += `${new Intl.NumberFormat().format(count)} `;
    }

    return result + `${actualStr}${count === 1 ? '' : actualSuffix}`;
}

export function htmlToText(html: string): string {
    const d = document.createElement('div');
    d.innerHTML = html;

    return d.textContent || d.innerText || '';
}

export function durationToString(seconds: number): string {
    const m = moment.utc(seconds * 1000);
    const days = parseInt(m.format('D')) - 1;

    return (days > 0 ? `${days}d ` : '') + m.format('HH:mm:ss');
}

export function gtmUserCustomEvent(
    name: string,
    options?: {
        canal?: string;
        evaluationQuizId?: number;
        evaluationQuizAnswer?: string;
        evaluationQuizAnswers?: string[];
        evaluationQuizNavigateTo?: EvaluationQuizNavigationWay;
        evaluationQuizNotFocusedDuration?: number;
        evaluationQuizQCategory?: string; // Property name max length == 24 char when corresponding to Google Analytics custom dimension
        evaluationQuizQuestionId?: number;
        evaluationQuizStatus?: string;
        userId?: number;
        sandboxAction?: 'run' | 'wake' | 'restart' | 'stop' | 'ssh' | 'sftp';
        sandboxContainerSpecId?: number;
        sandboxMessage?: string;
        sandboxSearchQuery?: string;
    },
): void {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
    /* @ts-ignore */
    window.gtm_user_custom_event?.(name, options);
}
