import {
    ICheck,
    IManualSelfCorrectionRequest,
    IManualSelfCorrectionResponse,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { patch } from '../../api/utils';
import ErrorAlert from '../common/ErrorAlert';
import Switch from '../common/Switch';

interface IProps {
    check: ICheck;
    csrfToken: string;
    index: number;
    onCheckChange: (
        check: ICheck,
        response: IManualSelfCorrectionResponse | null,
    ) => void;
}

export default function ManualCheck({
    check,
    csrfToken,
    index,
    onCheckChange,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);

    const onCheck = async (checked: boolean): Promise<void> => {
        setError(null);
        onCheckChange({ ...check, isLoading: true, passed: checked }, null);

        try {
            const response = await patch<
                IManualSelfCorrectionResponse,
                IManualSelfCorrectionRequest
            >(check.update_correction_uri, csrfToken, {
                checked,
            });
            onCheckChange(
                { ...check, isLoading: false, passed: checked },
                response,
            );
        } catch (err) {
            setError(err.message);
            onCheckChange({ ...check, isLoading: false, passed: false }, null);
        }
    };

    return (
        <div className={`item ${index === 0 ? 'active' : ''}`}>
            {error && <ErrorAlert error={error} />}

            <label
                style={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    fontWeight: 'normal',
                    justifyContent: 'space-between',
                }}
            >
                <span dangerouslySetInnerHTML={{ __html: check.body }} />
                <Switch
                    checked={check.passed}
                    disabled={
                        check.isLoading || check.update_correction_uri === null
                    }
                    id={`check-${check.id}`}
                    onCheck={onCheck}
                />
            </label>
        </div>
    );
}
