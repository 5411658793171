import { SearchModel } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, FormEvent, ReactElement, useRef, useState } from 'react';

import Icon from '../common/Icon';

import {
    ISearchResponse,
    SearchResponseNormalized,
    searchModels,
} from './api-rest-adapter';

interface IProps {
    csrfToken: string;
    models: SearchModel[];
    onResult: (result: ISearchResponse) => void;
    placeholder?: string;
    searchURI: string;
    squaredBottom?: boolean;
}

export interface ISearchResult {
    badge?: string;
    id: number;
    label: string;
    link?: string;
}

export const resultFlatten = (
    results: SearchResponseNormalized,
): ISearchResult[] => {
    return Object.values(results).reduce((a, b) => a.concat(b.items), []);
};

export const resultLength = (results: SearchResponseNormalized): number => {
    return resultFlatten(results).length;
};

export default function SearchBar({
    csrfToken,
    models,
    onResult,
    placeholder = `Search for ${models.join(', ')}...`,
    searchURI,
    squaredBottom = false,
}: IProps): ReactElement {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const searchRef = useRef<HTMLInputElement>(null);

    const onClearSearch = (): void => {
        setSearchQuery('');
        onResult({ query: '', results: {} as SearchResponseNormalized });
        searchRef.current?.focus();
    };

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearchQuery(e.currentTarget.value);
        if (e.currentTarget.value === '') {
            onClearSearch();
        }
    };

    const onSubmitSearch = async (
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();

        if (searchQuery.length === 0) {
            return;
        }
        onResult({ query: '', results: {} as SearchResponseNormalized });
        setLoading(true);

        const queryParams = [
            `q=${encodeURIComponent(searchQuery)}`,
            models.map((m) => `models[]=${m}`).join('&'),
        ];
        const searchUri = `${searchURI}?${queryParams.join('&')}`;
        try {
            const apiResults = await searchModels(searchUri, csrfToken, models);
            onResult({ query: searchQuery, results: apiResults });
        } catch (err) {
            onResult({
                error: err.message,
                query: searchQuery,
                results: {} as SearchResponseNormalized,
            });
        }

        setLoading(false);
    };

    return (
        <>
            <form onSubmit={onSubmitSearch} role="search">
                <div className="input-group">
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            position: 'relative',
                        }}
                    >
                        <input
                            className="form-control"
                            onChange={onChangeSearch}
                            placeholder={placeholder}
                            ref={searchRef}
                            style={{
                                borderBottomLeftRadius: squaredBottom
                                    ? 0
                                    : '4px',
                                borderTopLeftRadius: '4px',
                            }}
                            type="search"
                            value={searchQuery}
                        />
                        {searchQuery && (
                            <span
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    right: '5px',
                                    zIndex: 3,
                                }}
                            >
                                <Icon
                                    color="#9EB2B9"
                                    fixedWidth
                                    name="circle-xmark"
                                    onClick={onClearSearch}
                                />
                            </span>
                        )}
                    </div>
                    <span className="input-group-btn">
                        <button
                            className="btn btn-default"
                            disabled={searchQuery.length === 0 || loading}
                            style={{
                                borderBottomRightRadius: squaredBottom
                                    ? 0
                                    : '4px',
                            }}
                            type="submit"
                        >
                            <Icon
                                color="#9EB2B9"
                                fixedWidth
                                name={loading ? 'spinner' : 'magnifying-glass'}
                                pulse={loading}
                            />
                        </button>
                    </span>
                </div>
            </form>
        </>
    );
}
