import { IBlock, ICurriculumItem } from 'holberton-school-intranet-api';
import * as React from 'react';
import { Dispatch, ReactElement, useState } from 'react';

import { del, patch, post } from '../../api/utils';
import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';
import Select from '../common/select/Select';
import CurriculumItemableSummaryCard from '../curriculum_itemables/CurriculumItemableSummaryCard';

import {
    CreateBlock,
    DestroyBlock,
    DestroyCurriculumItem,
    IAction,
    IState,
    UpdateBlock,
    UpdateCurriculumItem,
} from './reducer';

interface IProps {
    canEdit: boolean;
    dispatch: Dispatch<IAction>;
    item: ICurriculumItem;
    onLock: (isLocked: boolean) => void;
    state: IState;
}

export default function BuilderContentRow({
    canEdit,
    dispatch,
    item,
    onLock,
    state,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onClickRemove = async (): Promise<void> => {
        setError(null);
        setIsLoading(true);

        try {
            const res = await del<ICurriculumItem[]>(item.uri, state.csrfToken);
            dispatch(new DestroyCurriculumItem(res));
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const onSubmitBlock = async (block: IBlock): Promise<void> => {
        if (!block || block.id === item.block.id) {
            return;
        }

        setError(null);
        setIsLoading(true);
        try {
            const updatedItem = { ...item, block: block };
            await patch(updatedItem.uri, state.csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                curriculum_item: {
                    block_id: block.id,
                },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            dispatch(new UpdateCurriculumItem(updatedItem));
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    };

    const onCreateBlock = async (name: string): Promise<void> => {
        // Errors are caught in children component
        const response = await post<IBlock>(
            state.createBlockURI,
            state.csrfToken,
            {
                /* eslint-disable @typescript-eslint/camelcase */
                block: {
                    name,
                    online: true,
                    track_id: state.trackID,
                },
                /* eslint-enable @typescript-eslint/camelcase */
            },
        );
        dispatch(new CreateBlock(response));
    };

    const onDestroyBlock = async (b: IBlock): Promise<void> => {
        // Errors are caught in children component
        await del(b.uri, state.csrfToken);
        dispatch(new DestroyBlock(b));
    };

    const onEditBlock = async (block: IBlock, name: string): Promise<void> => {
        // Errors are caught in children component
        await patch(block.uri, state.csrfToken, { block: { name } });
        dispatch(new UpdateBlock({ ...block, name }));
    };

    return (
        <>
            <td className="text-center">{item.position}</td>
            <td>
                <CurriculumItemableSummaryCard item={item.item} />
                {error && <ErrorInline error={error} />}
            </td>

            <td>
                <Select
                    disabled={!canEdit || isLoading}
                    onCreateOption={onCreateBlock}
                    onDestroyOption={onDestroyBlock}
                    onEditOption={onEditBlock}
                    onHide={(): void => onLock(false)}
                    onShow={(): void => onLock(true)}
                    onSubmit={onSubmitBlock}
                    options={state.blocks.map((block) => ({
                        destroyable: !state.items.some(
                            (i) => i.block.id === block.id,
                        ),
                        label: block.name,
                        value: block,
                    }))}
                    placement="right"
                    value={item.block}
                >
                    {isLoading ? (
                        <Icon name="spinner" pulse />
                    ) : (
                        <span className="fw-bold">{item.block.name}</span>
                    )}
                </Select>
            </td>

            <td className="text-center">
                {canEdit && !isLoading && (
                    <Icon
                        className="text-danger"
                        name="trash"
                        onClick={onClickRemove}
                    />
                )}
            </td>
        </>
    );
}
