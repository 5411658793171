import {
    ICurriculumItem,
    ICurriculumItemableSearchResult,
    ISearchModel,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { Dispatch, ReactElement, useState } from 'react';

import { post } from '../../../api/utils';
import ErrorInline from '../../common/ErrorInline';
import Icon from '../../common/Icon';
import CurriculumItemableSummaryCard from '../../curriculum_itemables/CurriculumItemableSummaryCard';
import { CreateCurriculumItem, IAction, IState, SetLock } from '../reducer';

interface IProps {
    canBeAdded: boolean;
    dispatch: Dispatch<IAction>;
    item: ICurriculumItemableSearchResult;
    model: ISearchModel;
    state: IState;
}

export default function BuilderSearchItem({
    canBeAdded,
    dispatch,
    item,
    model,
    state,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onClickAdd = async (
        item: ICurriculumItemableSearchResult,
    ): Promise<void> => {
        setError(null);
        setLoading(true);
        dispatch(new SetLock(true));

        try {
            const response = await post<ICurriculumItem[]>(
                state.createItemURI,
                state.csrfToken,
                /* eslint-disable @typescript-eslint/camelcase */
                {
                    curriculum_item: {
                        curriculum_id: state.curriculumID,
                        curriculum_itemable_id: item.id,
                        curriculum_itemable_type: model.camel,
                    },
                },
                /* eslint-enable @typescript-eslint/camelcase */
            );
            dispatch(new CreateCurriculumItem(response));
        } catch (err) {
            setError(err.message);
        }

        dispatch(new SetLock(false));
        setLoading(false);
    };

    return (
        <div className="list-group-item">
            <CurriculumItemableSummaryCard
                item={item}
                renderActions={(): ReactElement => (
                    <>
                        {canBeAdded && (
                            <a
                                className={`btn${
                                    state.isLocked ? ' disabled' : ''
                                }`}
                                onClick={(): Promise<void> =>
                                    !state.isLocked && onClickAdd(item)
                                }
                            >
                                <Icon
                                    fixedWidth
                                    name={loading ? 'spinner' : 'plus'}
                                    pulse={loading}
                                />
                            </a>
                        )}

                        {!canBeAdded && (
                            <a className="btn disabled text-info">
                                <Icon fixedWidth name="ban" />
                            </a>
                        )}
                    </>
                )}
            />

            {error && <ErrorInline error={error} />}
        </div>
    );
}
