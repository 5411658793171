import { IUser } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { post } from '../../../api/utils';
import { gtmUserCustomEvent } from '../../../utils';
import ErrorInline from '../../common/ErrorInline';
import Icon from '../../common/Icon';
import SearchAndSelect from '../../search/SearchAndSelect';
import { ISearchResult } from '../../search/SearchBar';
import UserPicker from '../../users/UserPicker';

interface IProps {
    csrfToken: string;
    requestTeamPartnerURI: string;
    searchURI: string;
    suggestedPeersURI: string;
    teamCanBeSmaller: boolean;
    teamSize: number;
}

export default function RequestTeamMember({
    csrfToken,
    requestTeamPartnerURI,
    searchURI,
    suggestedPeersURI,
    teamCanBeSmaller,
    teamSize,
}: IProps): ReactElement {
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [partners, setPartners] = useState<Array<ISearchResult | null>>(
        Array(teamSize - 1).fill(null),
    );

    const onSubmitPartners = async (): Promise<void> => {
        if (loading || !readyToSubmit) {
            return;
        }

        setLoading(true);
        try {
            await post(requestTeamPartnerURI, csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                partner_ids: partners
                    .filter((p) => p !== null)
                    .map((p) => p.id),
                /* eslint-enable @typescript-eslint/camelcase */
            });
            window.location.reload();
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const onUserPicked = (user: IUser): void => {
        gtmUserCustomEvent('project_select_similar_peer');

        const index = partners.findIndex((p) => !p);
        if (index === -1) {
            return;
        }

        setPartners((v) => {
            const res = [...v];
            res.splice(index, 1, { id: user.id, label: user.full_name });
            return res;
        });
    };

    const onSelect = (item: ISearchResult | null, index: number): void => {
        setPartners((v) => {
            const newPartners = [...v];
            newPartners.splice(index, 1, item);
            return newPartners;
        });
    };

    useEffect(() => {
        setError(null);
        const realPartners = partners.filter((p) => p !== null);
        if (
            realPartners.length === 0 ||
            (!teamCanBeSmaller && realPartners.length !== partners.length)
        ) {
            setReadyToSubmit(false);
            return;
        }

        let ready = true;
        if (
            new Set(realPartners.map((p) => p.id)).size !== realPartners.length
        ) {
            setError('You cannot select the same partner twice');
            ready = false;
        }
        setReadyToSubmit(ready);
    }, [partners]);

    return (
        <>
            <UserPicker
                csrfToken={csrfToken}
                hideUserIds={partners
                    .filter((p) => p !== null)
                    .map((item) => item.id)}
                icon="bookmark"
                label="Suggested peers:"
                onSelectUser={(user: IUser): void => {
                    onUserPicked(user);
                }}
                suggestedPeersURI={suggestedPeersURI}
            />
            <div className="list-group">
                {partners.map((partner, i) => (
                    <div
                        className="list-group-item"
                        key={`${partner?.id}-${i}`}
                    >
                        <SearchAndSelect
                            csrfToken={csrfToken}
                            model="users"
                            onClear={async (): Promise<void> => {
                                onSelect(null, i);
                            }}
                            onSelect={async (
                                item: ISearchResult,
                            ): Promise<void> => {
                                onSelect(item, i);
                            }}
                            placeholder="Search for a partner"
                            searchURI={searchURI}
                            selectedItem={partner}
                        />
                    </div>
                ))}
            </div>

            <div className="align-items-center d-flex flex-row-reverse flex-wrap gap-3">
                <button
                    className="btn btn-primary"
                    disabled={loading || !readyToSubmit}
                    onClick={onSubmitPartners}
                >
                    <Icon
                        fixedWidth
                        name={loading ? 'circle-notch' : 'paper-plane'}
                        spin={loading}
                        text="Send the request"
                    />
                </button>

                {error && <ErrorInline className="mb-0" error={error} />}
            </div>
        </>
    );
}
