import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    color?: string;
    size?: number;
}

export default function Spinner({
    color = '#333',
    size = 18,
}: IProps): ReactElement {
    const bounceStyle = {
        WebkitAnimation: 'sk-bouncedelay 1.4s infinite ease-in-out both',
        animation: 'sk-bouncedelay 1.4s infinite ease-in-out both',
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
        height: `${size}px`,
        width: `${size}px`,
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div
                style={{
                    ...bounceStyle,
                    WebkitAnimationDelay: '-0.32s',
                    animationDelay: '-0.32s',
                }}
            />
            <div
                style={{
                    ...bounceStyle,
                    WebkitAnimationDelay: '-0.16s',
                    animationDelay: '-0.16s',
                }}
            />
            <div style={bounceStyle} />
        </div>
    );
}
