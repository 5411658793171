import { IProjectMetadata } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import DateTime from '../common/DateTime';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

interface IProps {
    metadata: IProjectMetadata;
}
export default function ProjectMetadata({ metadata }: IProps): ReactElement {
    return (
        <ul className="list-group metadata" id="project-metadata">
            {metadata.level && (
                <li className="list-group-item">
                    <Icon fixedWidth name="turn-up" /> {metadata.level}
                </li>
            )}

            {metadata.author && (
                <li className="list-group-item">
                    <Icon fixedWidth name="user" /> By: {metadata.author}
                </li>
            )}

            {metadata.weight !== undefined && (
                <li className="list-group-item">
                    <Icon fixedWidth name="gear" /> Weight: {metadata.weight}
                </li>
            )}

            {metadata.has_ai_grader && (
                <li className="list-group-item">
                    <Icon fixedWidth name="magic-wand-sparkles" /> AI grader
                </li>
            )}

            {metadata.manual_review && (
                <li className="list-group-item">
                    <Icon fixedWidth name="check" /> Manual review behavior:{' '}
                    {metadata.manual_review.behavior}{' '}
                    <Tooltip title={metadata.manual_review.description}>
                        <Icon name="circle-question" />
                    </Tooltip>
                </li>
            )}

            {metadata.correction?.synchronous_manual_review !== undefined && (
                <li className="list-group-item">
                    <Icon fixedWidth name="toggle-on" />
                    {metadata.correction.synchronous_manual_review ? (
                        <>
                            {' '}
                            Synchronous manual review{' '}
                            <Tooltip title="Synchronous (Interview mode): Learner and reviewer need to review the project together.">
                                <Icon name="circle-question" />
                            </Tooltip>
                        </>
                    ) : (
                        <> Asynchronous manual review</>
                    )}
                </li>
            )}

            {metadata.team && (
                <li className="list-group-item">
                    <Icon fixedWidth name="users" /> Project to be done in teams
                    of {metadata.team.in_team_of} people
                    {metadata.team.members && (
                        <> (your team: {metadata.team.members.join(', ')})</>
                    )}
                </li>
            )}

            {metadata.task_level_review_type && (
                <li className="list-group-item">
                    <Icon fixedWidth name="square-check" />{' '}
                    {metadata.task_level_review_type}
                </li>
            )}

            {metadata.bpi && (
                <li className="list-group-item">
                    <Icon fixedWidth name="calendar" />{' '}
                    {metadata.bpi.current ? (
                        <>
                            {metadata.bpi.started ? (
                                <>
                                    Ongoing project - started{' '}
                                    <DateTime value={metadata.bpi.starts_at} />
                                    , must end by
                                    <DateTime value={metadata.bpi.ends_at} />
                                </>
                            ) : (
                                <>
                                    Project will start{' '}
                                    <DateTime value={metadata.bpi.starts_at} />,
                                    must end by{' '}
                                    <DateTime value={metadata.bpi.ends_at} />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {metadata.bpi.in_second_deadline ? (
                                <>
                                    Ongoing second chance project - started{' '}
                                    <DateTime value={metadata.bpi.starts_at} />,
                                    must end by{' '}
                                    <DateTime
                                        value={metadata.bpi.second_deadline_at}
                                    />
                                </>
                            ) : (
                                <>
                                    Project over - took place from{' '}
                                    <DateTime value={metadata.bpi.starts_at} />{' '}
                                    to <DateTime value={metadata.bpi.ends_at} />
                                </>
                            )}
                        </>
                    )}
                    {metadata.bpi.completion !== undefined && (
                        <>
                            - you{"'"}re done with{' '}
                            <span id="student_task_done_percentage">
                                {metadata.bpi.completion}
                            </span>
                            % of tasks.
                        </>
                    )}
                </li>
            )}

            {metadata.correction?.auto_correction_available_at && (
                <li className="list-group-item">
                    <Icon fixedWidth name="check" /> Checker{' '}
                    {metadata.correction.released ? 'was ' : 'will be '}
                    released at{' '}
                    <DateTime
                        value={metadata.correction.auto_correction_available_at}
                    />
                </li>
            )}

            {metadata.correction?.requires_manual_correction && (
                <li className="list-group-item">
                    <Icon fixedWidth name="square-check" />
                    {metadata.correction.manual_done_at ? (
                        <>
                            {' '}
                            Manual QA review was done{' '}
                            {metadata.correction.manual_done_by
                                ? `by ${metadata.correction.manual_done_by} `
                                : ''}
                            on{' '}
                            <DateTime
                                value={metadata.correction.manual_done_at}
                            />
                        </>
                    ) : (
                        <>
                            {' '}
                            <strong>Manual QA review must be done</strong>{' '}
                            (request it when you are done with the project)
                        </>
                    )}
                </li>
            )}

            {metadata.correction?.synchronous_manual_review && (
                <li className="list-group-item">
                    <Icon fixedWidth name="toggle-on" />{' '}
                    <strong>Synchronous manual review: </strong> you and the
                    reviewer need to review the project together
                </li>
            )}

            {metadata.correction?.requires_auto_correction && (
                <li className="list-group-item">
                    <Icon fixedWidth name="square-check" /> An auto review will
                    be launched at the deadline
                </li>
            )}
        </ul>
    );
}
