import moment, { Moment } from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import Select from './select/Select';

interface IProps {
    id: string;
    name: string;
    onChange?: (value: string) => void;
    timeStep?: number;
    value: string;
}

function timeOptions(timeStep?: number): Moment[] {
    const res: Moment[] = [];
    const step = timeStep || 15;

    for (let minutes = 0; minutes < 60 * 24; minutes += step) {
        res.push(moment({ hour: minutes / 60, minutes: minutes % 60 }));
    }
    return res;
}

const getDateTimeStr = (date: string, time: string): string => {
    const t = moment(time, 'LT').format('HH:mm');
    return moment(`${date}T${t}`).format();
};
const isValidDate = (str: string): boolean =>
    moment(str, 'YYYY-MM-DD').isValid();
const isValidTime = (str: string): boolean => moment(str, 'LT').isValid();

export default function DatetimeInput({
    id: inputId,
    name: inputName,
    onChange,
    timeStep,
    value,
}: IProps): ReactElement {
    const [date, setDate] = useState<string>(
        moment(value).format('YYYY-MM-DD'),
    );
    const [time, setTime] = useState<string>(moment(value).format('LT'));

    useEffect(() => {
        onChange?.(getDateTimeStr(date, time));
    }, [date, time]);

    const onDateChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setDate(e.target.value);
    };

    const onTimeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setTime(e.target.value);
    };

    return (
        <>
            <div className="d-flex gap-2">
                <div className={isValidDate(date) ? '' : 'field_with_errors'}>
                    <input
                        className="form-control"
                        onChange={onDateChange}
                        type="date"
                        value={date}
                    />
                </div>

                <Select
                    onSubmit={setTime}
                    options={timeOptions(timeStep).map((opt) => ({
                        label: opt.format('LT'),
                        value: opt.format('LT'),
                    }))}
                    searchable={false}
                >
                    <div
                        className={isValidTime(time) ? '' : 'field_with_errors'}
                    >
                        <input
                            className="form-control"
                            onChange={onTimeChange}
                            type="text"
                            value={time}
                        />
                    </div>
                </Select>
            </div>

            {isValidDate(date) && isValidTime(time) && (
                <input
                    id={inputId}
                    name={inputName}
                    type="hidden"
                    value={getDateTimeStr(date, time)}
                />
            )}
        </>
    );
}
