import {
    IUserJourney,
    IUserJourneyActivityItem,
    IUserJourneySession,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { get } from '../../api/utils';
import ErrorAlert from '../common/ErrorAlert';
import Icon from '../common/Icon';
import Spinner from '../common/Spinner';

import ActivityCalendarGrid from './ActivityCalendarGrid';
import ActivityCalendarGridLegend from './ActivityCalendarGridLegend';
import Session from './Session';
import Summary from './Summary';
import WeeklyWorkHabit from './WeeklyWorkHabit';

export interface IProps {
    csrfToken: string;
    userJourneyURI: string;
}

export default function Container({
    csrfToken,
    userJourneyURI,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [errorSession, setErrorSession] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSession, setLoadingSession] = useState<boolean>(false);
    const [
        selectedActivityItem,
        setSelectedActivityItem,
    ] = useState<IUserJourneyActivityItem | null>(null);
    const [userJourney, setUserJourney] = useState<IUserJourney | null>(null);
    const [session, setSession] = useState<IUserJourneySession | null>(null);

    const handleSelectActivityItem = useCallback(
        async (activityItem: IUserJourneyActivityItem): Promise<void> => {
            if (loadingSession) {
                return;
            }

            setSelectedActivityItem(activityItem);

            try {
                setErrorSession(null);
                setLoadingSession(true);
                const response = await get<IUserJourneySession>(
                    activityItem.sessions_uri,
                    csrfToken,
                );
                setSession(response);
            } catch (err) {
                setErrorSession(err.message);
            } finally {
                setLoadingSession(false);
            }
        },
        [],
    );

    const loadUserJourney = async (): Promise<void> => {
        try {
            setError(null);
            setLoading(true);
            const response = await get<IUserJourney>(userJourneyURI, csrfToken);
            setUserJourney(response);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadUserJourney();
    }, []);

    return (
        <div className="user-journey">
            {loading && (
                <div className="gap">
                    <Spinner />
                </div>
            )}
            {error && (
                <div className="gap">
                    <ErrorAlert error={error} />
                </div>
            )}

            {!loading && !error && userJourney && (
                <>
                    <div className="row">
                        <div className="col-sm-12 col-md-3 col-md-push-9 d-flex flex-column gap-3 mb-3">
                            <Summary activity={userJourney.activity} />
                            <ActivityCalendarGridLegend />
                        </div>

                        <div className="col-sm-12 col-md-9 col-md-pull-3 d-flex flex-column gap-1">
                            <WeeklyWorkHabit
                                weeklyWorkHabit={userJourney.weekly_work_habit}
                            />

                            <div className="calendar-grid mb-3">
                                <h3 className="fs-3 mb-0 mt-0">Activity</h3>
                                <ActivityCalendarGrid
                                    activity={userJourney.activity}
                                    onSelectActivityItem={
                                        handleSelectActivityItem
                                    }
                                    selectedActivityItem={selectedActivityItem}
                                />
                            </div>

                            {!selectedActivityItem ? (
                                <div className="alert alert-info ml-2 mr-2">
                                    Select a date to load sessions{' '}
                                    <Icon className="ml-2" name="arrow-up" />
                                </div>
                            ) : (
                                <>
                                    {loadingSession && (
                                        <>
                                            <h3 className="fs-3 mb-2 mt-0 text-info">
                                                {moment(
                                                    selectedActivityItem.activity_date,
                                                ).format('dddd, MMMM Do YYYY')}
                                                :{' '}
                                            </h3>
                                            <div className="gap">
                                                <Spinner />
                                            </div>
                                        </>
                                    )}
                                    {errorSession && (
                                        <>
                                            <h3 className="fs-3 mb-2 mt-0 text-danger">
                                                Error loading session
                                            </h3>
                                            <ErrorAlert error={errorSession} />
                                        </>
                                    )}
                                    {!loadingSession &&
                                        !errorSession &&
                                        session && (
                                            <Session
                                                activityDate={
                                                    selectedActivityItem.activity_date
                                                }
                                                csrfToken={csrfToken}
                                                session={session}
                                            />
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
