import {
    IProject,
    IProjectNextProjectDetails,
    IProjectProgress,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { get, post } from '../../api/utils';
import { gtmUserCustomEvent } from '../../utils';
import ErrorAlert from '../common/ErrorAlert';
import useInterval from '../lib/useInterval';

import ProjectEncouragement from './ProjectEncouragement';
import { IProjectPeerReview } from './ProjectReadyForReviewBody';
import ProjectReviewBody from './ProjectReviewBody';
import ProjectCircle from './pathway/ProjectCircle';

interface IProps {
    correction: {
        startReviewURI: string;
        statusURI: string;
    };
    csrfToken: string;
    nextProject: {
        details: IProjectNextProjectDetails | null;
        fetchURI: string;
    };
    pollingInterval: number | null;
    peerReview: IProjectPeerReview | null;
    progress: IProjectProgress;
    project: IProject;
    scoresFrozen: boolean;
    skipURI: string;
    taskLevelReviewTypeHumanized: string | null;
}

export default function ProjectReview({
    correction,
    csrfToken,
    nextProject,
    pollingInterval,
    peerReview,
    progress,
    project,
    scoresFrozen,
    skipURI,
    taskLevelReviewTypeHumanized,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [updatedProject, setUpdatedProject] = useState<IProject>(project);
    const [updatedProgress, setUpdatedProgress] = useState<IProjectProgress>(
        progress,
    );

    const onProgressChanged = (e: CustomEvent<IProjectProgress>): void => {
        setUpdatedProgress(e.detail);
        setUpdatedProject((prev) => ({
            ...prev,
            completion: e.detail.summary.completion,
            score: e.detail.summary.score,
        }));
    };

    const refreshStatus = async (): Promise<void> => {
        if (
            refreshing ||
            updatedProgress.auto_review.inability_to_execute_reason !==
                'in_progress'
        ) {
            return;
        }

        try {
            setRefreshing(true);

            // Notify React components so they can do whatever they want
            document.dispatchEvent(
                new CustomEvent('project_progress_changed', {
                    detail: await get<IProjectProgress>(
                        correction.statusURI,
                        csrfToken,
                    ),
                }),
            );
        } catch (err) {
            setError(err.message);
        } finally {
            setRefreshing(false);
        }
    };

    useEffect(() => {
        document.addEventListener(
            'project_progress_changed',
            onProgressChanged,
        );

        return (): void => {
            document.removeEventListener(
                'project_progress_changed',
                onProgressChanged,
            );
        };
    }, []);

    useEffect(() => {
        updatedProgress.tasks.forEach((task) => {
            const {
                id,
                score_info: { passed, score },
            } = task;

            // Update task footer
            const scoreElement = document.getElementById(
                `task-${id}-score-info-score`,
            );
            if (scoreElement) {
                scoreElement.parentElement.className = passed
                    ? 'text-success'
                    : 'text-primary';
                scoreElement.innerHTML = score.toString();
            }
        });
    }, [updatedProgress]);

    useInterval(() => {
        refreshStatus();
    }, pollingInterval);

    const onStartReview = async (): Promise<void> => {
        setError(null);
        setLoading(true);
        gtmUserCustomEvent('project_start_review');
        try {
            setUpdatedProgress(
                await post<IProjectProgress>(
                    correction.startReviewURI,
                    csrfToken,
                    {},
                ),
            );
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h3 className="panel-title">Score</h3>
            </div>
            <div className="panel-body">
                <div className="align-items-center d-flex gap-5">
                    <div
                        className="pathway"
                        style={{ padding: '10px 30px 40px 30px' }}
                    >
                        <ProjectCircle
                            project={updatedProject}
                            scale={1.25}
                            withShadow={true}
                        />
                    </div>

                    <div style={{ flexBasis: '100%' }}>
                        <p className="mb-2 text-primary">
                            {scoresFrozen &&
                                updatedProject.completion < 100 && (
                                    <span>Reviews are closed</span>
                                )}

                            {(!scoresFrozen ||
                                updatedProject.completion >= 100) && (
                                <ProjectEncouragement
                                    project={updatedProject}
                                    reviewTypeHumanized={
                                        taskLevelReviewTypeHumanized
                                    }
                                />
                            )}
                        </p>

                        {error && (
                            <div className="mb-2">
                                <ErrorAlert error={error} />
                            </div>
                        )}

                        <ProjectReviewBody
                            csrfToken={csrfToken}
                            fullManualReview={!taskLevelReviewTypeHumanized}
                            loading={loading}
                            nextProjectDetails={nextProject.details}
                            onStartReview={!scoresFrozen && onStartReview}
                            peerReview={!scoresFrozen && peerReview}
                            progress={updatedProgress}
                            skipURI={skipURI}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
