import {
    IUserJourneyEvent,
    IUserJourneySessionItem,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { get } from '../../api/utils';
import { durationToString } from '../../utils';
import DateTime from '../common/DateTime';
import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';

export interface IProps {
    csrfToken: string;
    sessionItem: IUserJourneySessionItem;
}

export default function SessionItem({
    csrfToken,
    sessionItem,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<IUserJourneyEvent[] | null>(null);

    const loadEvents = async (): Promise<void> => {
        if (events !== null) {
            setError(null);
            setLoading(false);
            setEvents(null);
            return;
        }

        try {
            setError(null);
            setLoading(true);
            const response = await get<IUserJourneyEvent[]>(
                sessionItem.events_uri,
                csrfToken,
            );
            setEvents(response);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <span onClick={loadEvents} style={{ cursor: 'pointer' }}>
                <Icon
                    fixedWidth
                    name={events ? 'square-minus' : 'square-plus'}
                    style="regular"
                />
                <code className="ml-1">{sessionItem.session_id}</code>
                <DateTime
                    showDate={false}
                    showSeconds={true}
                    value={sessionItem.start_at}
                />
                <span className="ml-1">-</span>
                <Icon className="ml-1" name="clock" style="regular" />{' '}
                {durationToString(sessionItem.duration)}
                <Icon className="ml-1" name="chart-line" />{' '}
                {sessionItem.user_engagements}
            </span>

            {loading && (
                <div
                    style={{
                        padding: '7px 0px 20px 25px',
                    }}
                >
                    <Icon
                        fixedWidth
                        name="spinner"
                        pulse
                        text="Loading events..."
                    />
                </div>
            )}

            <div>
                {error && <ErrorInline error={error} />}
                {!loading && !error && events && (
                    <div
                        style={{
                            padding: '7px 0px 0px 25px',
                        }}
                    >
                        <table className="table table-striped table-condensed">
                            <thead>
                                <tr>
                                    <th style={{ width: '20%' }}>Time UTC</th>
                                    <th style={{ width: '40%' }}>Event Name</th>
                                    <th>Page</th>
                                </tr>
                            </thead>
                            <tbody>
                                {events.map((e) => (
                                    <tr
                                        key={`${e.event_timestamp}-${e.event_name}`}
                                    >
                                        <td>
                                            <DateTime
                                                showDate={false}
                                                showSeconds={true}
                                                value={e.event_date_time}
                                            />
                                        </td>
                                        <td>{e.event_name}</td>
                                        <td>{e.page_path}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    );
}
