import {
    IEvent,
    IEventDuplicateValidationRules,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, ReactElement, useState } from 'react';

import { post } from '../../../api/utils';
import { pluralize } from '../../../utils';
import ErrorInline from '../../common/ErrorInline';
import Icon from '../../common/Icon';

interface IProps {
    csrfToken: string;
    frequencies: {
        label: string;
        value: string;
    }[];
    onEvents: (events: IEvent[]) => void;
    uri: string;
    validationRules: IEventDuplicateValidationRules;
}

export default function Form({
    csrfToken,
    frequencies,
    onEvents,
    uri,
    validationRules,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [frequency, setFrequency] = useState<string>(frequencies[0].value);
    const [occurrences, setOccurrences] = useState<number>(1);

    const onOccurrencesChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const newValue = parseInt(e.currentTarget.value);

        if (Number.isNaN(newValue)) {
            setOccurrences(validationRules.occurrences.min);
        } else {
            setOccurrences(newValue);
        }
    };

    const onSubmit = async (): Promise<void> => {
        if (loading) {
            return;
        }

        setError(null);
        setLoading(true);

        try {
            const createdEvents = await post<IEvent[]>(uri, csrfToken, {
                duplicate: { frequency, occurrences },
            });
            onEvents(createdEvents);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-2">
                <label>Occurrences</label>
                <div className="align-items-center d-flex gap-2 ml-2">
                    <span>Copy</span>
                    <input
                        className="form-control w-auto"
                        max={validationRules.occurrences.max}
                        min={validationRules.occurrences.min}
                        onChange={onOccurrencesChange}
                        type="number"
                        value={occurrences}
                    />
                    <span>
                        {pluralize(occurrences, 'time', { strOnly: true })}
                    </span>
                </div>
            </div>

            <div className="d-flex flex-column gap-2">
                <label>Frequency</label>
                <div className="align-items-center d-flex gap-3 ml-2">
                    {frequencies.map((f) => (
                        <span
                            className={`toggle-token${
                                f.value === frequency ? ' active' : ''
                            }`}
                            key={f.value}
                            onClick={(): void => setFrequency(f.value)}
                            role="button"
                        >
                            {f.label}
                        </span>
                    ))}
                </div>
            </div>

            <div className="align-items-center d-flex gap-3 justify-content-end">
                {error && <ErrorInline error={error} />}

                <button
                    className="btn btn-primary"
                    disabled={loading}
                    onClick={onSubmit}
                >
                    <Icon
                        name={loading ? 'circle-notch' : 'check'}
                        spin={loading}
                        text={loading ? 'Submitting' : 'Submit'}
                    />
                </button>
            </div>
        </div>
    );
}
