import { IUserJourneyActivityItem } from 'holberton-school-intranet-api';
import { Moment } from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import { durationToString } from '../../utils';
import Tooltip from '../common/Tooltip';

export interface IProps {
    activityItem?: IUserJourneyActivityItem;
    date?: Moment;
    onSelectActivityItem?: (activityItem: IUserJourneyActivityItem) => void;
    selectedActivityItem?: IUserJourneyActivityItem;
}

export default function ActivityTile({
    activityItem,
    date,
    onSelectActivityItem,
    selectedActivityItem,
}: IProps): ReactElement {
    if (activityItem && activityItem.duration > 0) {
        const tooltipLines = [];
        date &&
            tooltipLines.push(`<b>${date.format('dddd, MMMM Do YYYY')}</b>`);
        activityItem.duration &&
            tooltipLines.push(
                `Duration: ${durationToString(activityItem.duration)}`,
            );
        activityItem.user_engagements &&
            tooltipLines.push(
                `User engagements: ${activityItem.user_engagements}`,
            );

        return (
            <Tooltip html title={tooltipLines.join('<br/>')}>
                <div
                    className={`activity-tile level-${activityItem.level}${
                        activityItem === selectedActivityItem ? ' selected' : ''
                    }`}
                    onClick={(): void => {
                        onSelectActivityItem(activityItem);
                    }}
                    role={onSelectActivityItem ? 'button' : ''}
                ></div>
            </Tooltip>
        );
    }

    return <div className="activity-tile"></div>;
}
