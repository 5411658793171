import {
    ILtiContentItem,
    ILtiDeepLinkResponse,
    ILtiTool,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import ErrorAlert from '../common/ErrorAlert';

import ContentItemsTable, { OnSelect, isSelected } from './ContentItemsTable';

interface IProps {
    enrichableFieldName: string;
    enrichableName: string;
    eventName: string;
    tools: ILtiTool[];
}

const DEBUG = false;

function fieldId(
    enrichableName: IProps['enrichableName'],
    fieldName: string,
): string {
    return `${enrichableName}-${fieldName}`;
}

function fieldName(
    enrichableName: IProps['enrichableName'],
    enrichableFieldName: IProps['enrichableFieldName'],
    fieldName: string,
): string {
    return `${enrichableName}[${enrichableFieldName}][][${fieldName}]`;
}

export default function ResourceSelector({
    enrichableFieldName,
    enrichableName,
    eventName,
    tools,
}: IProps): ReactElement {
    const [contentItem, setContentItem] = useState<ILtiContentItem | null>(
        null,
    );
    const [error, setError] = useState<string | null>(null);
    const [info, setInfo] = useState<string | null>(null);
    const [
        deepLinkResponse,
        setDeepLinkResponse,
    ] = useState<ILtiDeepLinkResponse | null>(null);
    const [tool, setTool] = useState<ILtiTool | null>(null);
    const [warn, setWarn] = useState<string | null>(null);

    useEffect(() => {
        const listener: EventListener = (
            e: CustomEvent<ILtiDeepLinkResponse | undefined>,
        ) => {
            const { detail } = e;
            if (!detail) {
                setError(
                    'The LTI Tool replied with an unexpected response. Please select another tool or another resource.',
                );
                setTool(null);
            } else {
                const {
                    'https://purl.imsglobal.org/spec/lti-dl/claim/errorlog': errorLog,
                    'https://purl.imsglobal.org/spec/lti-dl/claim/errormsg': errorMessage,
                    'https://purl.imsglobal.org/spec/lti-dl/claim/log': logMessage,
                    'https://purl.imsglobal.org/spec/lti-dl/claim/msg': message,
                } = detail;

                setInfo(message || logMessage || null);

                if (errorLog || errorMessage) {
                    setError(errorMessage || errorLog);
                    setTool(null);
                } else {
                    setDeepLinkResponse(detail);
                }
            }
        };
        window.document.addEventListener(eventName, listener, false);

        return (): void =>
            window.document.removeEventListener(eventName, listener);
    }, [tool]);

    useEffect(() => {
        if (!contentItem) {
            setWarn(null);
        } else if (!contentItem.url) {
            setWarn(
                'This resource will not be displayed directly since it does not have a defined URL. You might want to select another one.',
            );
        }
    }, [contentItem]);

    const onChange: React.FormEventHandler<HTMLSelectElement> = (e) => {
        setContentItem(null);
        setError(null);
        setInfo(null);
        setDeepLinkResponse(null);
        setTool(
            tools.find((t) => t.id.toString() === e.currentTarget.value) ||
                null,
        );
        setWarn(null);
    };

    const onSelectContentItem: OnSelect = (item) => {
        setContentItem((prev) => (isSelected(item, prev) ? null : item));
    };

    return (
        <div className="d-flex flex-column gap-3">
            <div className="field">
                <label htmlFor={fieldId(enrichableName, 'lti_tool_id')}>
                    Select a provider
                </label>
                <select
                    className="form-control"
                    id={fieldId(enrichableName, 'lti_tool_id')}
                    name={fieldName(
                        enrichableName,
                        enrichableFieldName,
                        'lti_tool_id',
                    )}
                    onChange={onChange}
                    value={tool?.id || ''}
                >
                    <option value="" />
                    {tools.map((ltiTool) => (
                        <option key={ltiTool.id.toString()} value={ltiTool.id}>
                            {ltiTool.name}
                        </option>
                    ))}
                </select>
            </div>

            {info && <div className="alert alert-info">{info}</div>}

            {error && <ErrorAlert error={error} />}

            {tool && !deepLinkResponse && (
                <iframe
                    className="border border-info"
                    frameBorder="0"
                    height="500"
                    src={tool.auth_url}
                    width="100%"
                />
            )}

            {deepLinkResponse && (
                <>
                    {DEBUG && (
                        <pre>{JSON.stringify(deepLinkResponse, null, 4)}</pre>
                    )}

                    <span className="d-block fw-bold">
                        Click on an item to select it
                    </span>

                    <ContentItemsTable
                        items={
                            deepLinkResponse[
                                'https://purl.imsglobal.org/spec/lti-dl/claim/content_items'
                            ]
                        }
                        onSelect={onSelectContentItem}
                        selectedItem={contentItem}
                    />

                    {contentItem && (
                        <input
                            className="form-control"
                            id={fieldId(enrichableName, 'content_item')}
                            name={fieldName(
                                enrichableName,
                                enrichableFieldName,
                                'content_item',
                            )}
                            type="hidden"
                            value={JSON.stringify(contentItem)}
                        />
                    )}
                </>
            )}

            {warn && <p className="fw-bold text-warning">{warn}</p>}
        </div>
    );
}
