import {
    IBatchPlanningItem,
    MilestoneType,
    ReleaseScope,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { pluralize } from '../../../../utils';
import Icon from '../../../common/Icon';
import Tooltip from '../../../common/Tooltip';
import { ISession } from '../Projects';

interface IProps {
    dailyWorkHours: number;
    onSubmitMilestone?: (
        bpi: IBatchPlanningItem,
        type: MilestoneType,
        date: string,
        scope?: ReleaseScope,
    ) => Promise<void>;
    session: ISession;
}

interface IState<T> {
    editing: boolean;
    loading: boolean;
    value: T;
}

function isValid(state: IState<string>): boolean {
    return moment(state.value).isValid();
}

export default function SessionHeader({
    dailyWorkHours,
    onSubmitMilestone,
    session,
}: IProps): ReactElement {
    const [release, setRelease] = useState<IState<string>>({
        editing: false,
        loading: false,
        value: session.startDate,
    });
    const [deadline, setDeadline] = useState<IState<string>>({
        editing: false,
        loading: false,
        value: session.endDate,
    });
    const [scope, setScope] = useState<IState<ReleaseScope>>({
        editing: false,
        loading: false,
        value: session.releaseScope.name,
    });

    const duration = moment(session.endDate).diff(session.startDate, 'day') + 1;

    // Update state if the BPI changes from another group
    useEffect(() => {
        setRelease((v) => ({ ...v, value: session.startDate }));
    }, [session.startDate]);

    // Update state if the BPI changes from another group
    useEffect(() => {
        setDeadline((v) => ({ ...v, value: session.endDate }));
    }, [session.endDate]);

    const onChangeRelease = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setRelease((v) => ({ ...v, value }));
    };
    const onChangeDeadline = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setDeadline((v) => ({ ...v, value }));
    };
    const onChangeScope = (e: ChangeEvent<HTMLSelectElement>): void => {
        const value = e.target.value as ReleaseScope;
        setScope((v) => ({ ...v, value }));
    };

    const onSubmitRelease = async (): Promise<void> => {
        if (moment(session.startDate).diff(release.value, 'days')) {
            setRelease((v) => ({ ...v, loading: true }));
            await onSubmitMilestone(
                session.items[0].bpi,
                'start',
                release.value,
                session.releaseScope.name,
            );
        }
        setRelease((v) => ({ ...v, editing: false, loading: false }));
    };
    const onSubmitDeadline = async (): Promise<void> => {
        if (moment(session.endDate).diff(deadline.value, 'days')) {
            setDeadline((v) => ({ ...v, loading: true }));
            await onSubmitMilestone(
                session.items[session.items.length - 1].bpi,
                'end',
                deadline.value,
            );
        }
        setDeadline((v) => ({ ...v, editing: false, loading: false }));
    };
    const onSubmitScope = async (): Promise<void> => {
        if (scope.value !== session.releaseScope.name) {
            setScope((v) => ({ ...v, loading: true }));
            await onSubmitMilestone(
                session.items[0].bpi,
                'start',
                session.startDate,
                scope.value,
            );
        }
        setScope((v) => ({ ...v, editing: false, loading: false }));
    };

    return (
        <>
            <div className="align-items-center d-flex justify-content-around">
                <div className="align-items-center d-flex gap-3">
                    {release.editing ? (
                        <form className="align-items-center d-flex gap-3">
                            <div
                                className={`mt-0 field${
                                    isValid(release) ? '' : '_with_errors'
                                }`}
                            >
                                <input
                                    className="form-control"
                                    disabled={release.loading}
                                    min={moment().format('YYYY-MM-DD')}
                                    onChange={onChangeRelease}
                                    type="date"
                                    value={release.value}
                                />
                            </div>
                            {release.loading ? (
                                <Icon fixedWidth name="spinner" pulse />
                            ) : (
                                <>
                                    {isValid(release) && (
                                        <Icon
                                            fixedWidth
                                            name="check"
                                            onClick={onSubmitRelease}
                                        />
                                    )}
                                    <Icon
                                        className="text-danger"
                                        fixedWidth
                                        name="xmark"
                                        onClick={(): void =>
                                            setRelease((v) => ({
                                                ...v,
                                                editing: false,
                                                loading: false,
                                            }))
                                        }
                                    />
                                </>
                            )}
                        </form>
                    ) : (
                        <>
                            <span className="text-center">
                                <h3 className="m-0 text-success">
                                    {moment(session.startDate).format('MMM D')}
                                </h3>
                                <small className="text-muted">
                                    {moment(session.startDate).year()}
                                </small>
                            </span>
                            {!moment().isAfter(session.startDate) &&
                                onSubmitMilestone && (
                                    <Icon
                                        name="pencil"
                                        onClick={(): void =>
                                            setRelease((v) => ({
                                                ...v,
                                                editing: true,
                                            }))
                                        }
                                    />
                                )}
                        </>
                    )}
                </div>

                <div className="text-center">
                    <Icon name="arrow-right-long" size="2x" />
                    <div className="text-muted">
                        <Icon name="clock" style="regular" />{' '}
                        {pluralize(duration, 'day')} -{' '}
                        {pluralize(duration * dailyWorkHours, 'hour')}
                    </div>
                </div>

                <div className="align-items-center d-flex gap-3">
                    {deadline.editing ? (
                        <form className="align-items-center d-flex gap-3">
                            <div
                                className={`mt-0 field${
                                    isValid(deadline) ? '' : '_with_errors'
                                }`}
                            >
                                <input
                                    className="form-control"
                                    disabled={deadline.loading}
                                    min={moment
                                        .max(moment(session.startDate))
                                        .format('YYYY-MM-DD')}
                                    onChange={onChangeDeadline}
                                    type="date"
                                    value={deadline.value}
                                />
                            </div>
                            {deadline.loading ? (
                                <Icon fixedWidth name="spinner" pulse />
                            ) : (
                                <>
                                    {isValid(deadline) && (
                                        <Icon
                                            fixedWidth
                                            name="check"
                                            onClick={onSubmitDeadline}
                                        />
                                    )}
                                    <Icon
                                        className="text-danger"
                                        fixedWidth
                                        name="xmark"
                                        onClick={(): void =>
                                            setDeadline((v) => ({
                                                ...v,
                                                editing: false,
                                                loading: false,
                                            }))
                                        }
                                    />
                                </>
                            )}
                        </form>
                    ) : (
                        <>
                            <span className="text-center">
                                <h3 className="m-0 text-danger">
                                    {moment(session.endDate).format('MMM D')}
                                </h3>
                                <small className="text-muted">
                                    {moment(session.endDate).year()}
                                </small>
                            </span>
                            {!moment().isAfter(session.endDate, 'day') &&
                                onSubmitMilestone && (
                                    <Icon
                                        name="pencil"
                                        onClick={(): void =>
                                            setDeadline((v) => ({
                                                ...v,
                                                editing: true,
                                            }))
                                        }
                                    />
                                )}
                        </>
                    )}
                </div>
            </div>

            {scope.editing ? (
                <form className="align-items-center d-flex gap-3">
                    <select
                        className="form-control"
                        disabled={scope.loading}
                        onChange={onChangeScope}
                        value={scope.value}
                    >
                        <option value="everyone">Mandatory</option>
                        <option value="completed">Optional</option>
                    </select>
                    {scope.loading ? (
                        <Icon fixedWidth name="spinner" pulse />
                    ) : (
                        <>
                            <Icon
                                fixedWidth
                                name="check"
                                onClick={onSubmitScope}
                            />
                            <Icon
                                className="text-danger"
                                fixedWidth
                                name="xmark"
                                onClick={(): void =>
                                    setScope((v) => ({
                                        ...v,
                                        editing: false,
                                        loading: false,
                                    }))
                                }
                            />
                        </>
                    )}
                </form>
            ) : (
                <div className="align-items-center d-flex justify-content-between mt-2">
                    <div className="d-flex gap-3">
                        {session.releaseScope.name === 'completed'
                            ? 'Optional'
                            : 'Mandatory'}
                        <Tooltip title={session.releaseScope.description}>
                            <Icon name="circle-question" />
                        </Tooltip>
                    </div>
                    {!moment().isAfter(session.endDate, 'day') &&
                        onSubmitMilestone && (
                            <Icon
                                name="pencil"
                                onClick={(): void =>
                                    setScope((v) => ({ ...v, editing: true }))
                                }
                            />
                        )}
                </div>
            )}
        </>
    );
}
