import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import { gtmUserCustomEvent } from '../../utils';
import Spinner from '../common/Spinner';

import { useContainerContext } from './ContainerContext';
import { fetchInitialData, skip, start } from './api-rest-adapter';
import { isNotStarted } from './api-utils';

export default function Start(): ReactElement {
    const context = useContainerContext();
    const { progression, submitting } = context;

    useEffect(() => {
        (async function(): Promise<void> {
            await fetchInitialData(context);
        })();
    }, []);

    if (
        context.evaluationQuizCorrection.done ||
        !progression ||
        !isNotStarted(progression)
    ) {
        return null;
    }

    const onStartClick = async (): Promise<void> => {
        await start(context);
        gtmUserCustomEvent('evaluation_quiz_start', {
            evaluationQuizId: context.evaluationQuiz.id,
        });
    };

    const onSkipClick = async (): Promise<void> => {
        await skip(context);
        gtmUserCustomEvent('evaluation_quiz_skip', {
            evaluationQuizId: context.evaluationQuiz.id,
        });
    };

    return (
        <div className="overview">
            <div
                className="description"
                dangerouslySetInnerHTML={{ __html: progression.html }}
            />

            <div>
                {submitting && <Spinner />}

                {!submitting && (
                    <div>
                        <button
                            className="btn btn-lg btn-primary"
                            disabled={submitting}
                            onClick={onStartClick}
                        >
                            {progression.action}
                        </button>

                        {progression.can_skip && (
                            <button
                                className="btn btn-lg btn-info"
                                disabled={submitting}
                                onClick={onSkipClick}
                            >
                                Skip
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
