import {
    ISurveyQuestion,
    ISurveyQuestionAnswer,
    ISurveyQuestionAnswerSet,
    ISurveyQuestionType,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, ReactElement } from 'react';

import { del, patch, post } from '../../../api/utils';
import Icon from '../../common/Icon';
import Switch from '../../common/Switch';
import TextCheckbox from '../../common/TextCheckbox';
import Tooltip from '../../common/Tooltip';
import UniqueFieldForm from '../../common/UniqueFieldForm';

import AnswerEditor from './AnswerEditor';
import { AddAnswer, IAction, RemoveQuestion, UpdateQuestion } from './reducer';

interface IProps {
    answerSets: ISurveyQuestionAnswerSet[];
    canEdit: boolean;
    csrfToken: string;
    dispatch: (action: IAction) => void;
    question: ISurveyQuestion;
    questionTypes: ISurveyQuestionType[];
}

export default function QuestionEditor({
    answerSets,
    canEdit,
    csrfToken,
    dispatch,
    question,
    questionTypes,
}: IProps): ReactElement {
    const type =
        question.question_type === 'multiple_choices' ? 'checkbox' : 'radio';

    const onAddAnswer = async (): Promise<void> => {
        const answer = await post<ISurveyQuestionAnswer>(
            question.answers_uri,
            csrfToken,
            {},
        );
        dispatch(new AddAnswer(question, answer));
    };

    const onChangeAnswerSet = async (
        e: ChangeEvent<HTMLSelectElement>,
    ): Promise<void> => {
        const response = await patch<ISurveyQuestion>(question.uri, csrfToken, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            survey_question: { answer_set: e.target.value },
        });
        dispatch(new UpdateQuestion({ ...response }));
    };

    const onChangeQuestionType = async (
        e: ChangeEvent<HTMLSelectElement>,
    ): Promise<void> => {
        const response = await patch<ISurveyQuestion>(question.uri, csrfToken, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            survey_question: { question_type: e.target.value },
        });
        dispatch(new UpdateQuestion({ ...response }));
    };

    const onDestroyClick = async (): Promise<void> => {
        if (!confirm('Are you sure?')) {
            return;
        }

        await del(question.uri, csrfToken);
        dispatch(new RemoveQuestion(question));
    };

    const onRemoveDescription = async (): Promise<void> => {
        const response = await patch<ISurveyQuestion>(question.uri, csrfToken, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            survey_question: { description: null },
        });
        dispatch(new UpdateQuestion({ ...response }));
    };

    const onRequiredCheck = async (checked: boolean): Promise<void> => {
        const response = await patch<ISurveyQuestion>(question.uri, csrfToken, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            survey_question: { required: checked },
        });
        dispatch(new UpdateQuestion({ ...response }));
    };

    const onUpdateQuestion = (updatedQuestion: ISurveyQuestion): void => {
        dispatch(new UpdateQuestion({ ...updatedQuestion }));
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading panel-heading-actions">
                <div className="flex-grow-1">
                    <h3 className="panel-title">
                        <UniqueFieldForm
                            canEdit={canEdit}
                            csrfToken={csrfToken}
                            maxLength={255}
                            name="title"
                            onUpdate={onUpdateQuestion}
                            requestBodyContainer="survey_question"
                            updateURI={question.uri}
                            value={question.title}
                        />
                    </h3>
                    <i className="align-items-center d-flex fs-small gap-2 lh-base mt-1">
                        {question.description !== null && (
                            <>
                                <UniqueFieldForm
                                    canEdit={canEdit}
                                    csrfToken={csrfToken}
                                    editing={question.description === ''}
                                    isValid={(value: string): boolean =>
                                        value !== ''
                                    }
                                    maxLength={255}
                                    name="description"
                                    onCancel={(): void => {
                                        onUpdateQuestion({
                                            ...question,
                                            description:
                                                question.description === ''
                                                    ? null
                                                    : question.description,
                                        });
                                    }}
                                    onUpdate={onUpdateQuestion}
                                    requestBodyContainer="survey_question"
                                    updateURI={question.uri}
                                    value={question.description}
                                />
                                {canEdit && (
                                    <Tooltip title="Remove description">
                                        <Icon
                                            className="text-danger"
                                            name="trash"
                                            onClick={onRemoveDescription}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                        {canEdit && question.description === null && (
                            <a
                                onClick={(): void => {
                                    onUpdateQuestion({
                                        ...question,
                                        description: '',
                                    });
                                }}
                                role="button"
                            >
                                Add a description
                            </a>
                        )}
                    </i>
                </div>
                <div className="d-flex flex-column gap-1">
                    <h6 className="m-0">Question type</h6>
                    <select
                        className="form-control input-sm"
                        disabled={!canEdit}
                        onChange={onChangeQuestionType}
                        value={question.question_type}
                    >
                        {questionTypes.map((questionType) => (
                            <option
                                key={questionType.value}
                                value={questionType.value}
                            >
                                {questionType.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="d-flex flex-column gap-1">
                    <h6 className="m-0">Answer set</h6>
                    <select
                        className="form-control input-sm"
                        disabled={!canEdit}
                        onChange={onChangeAnswerSet}
                        value={question.answer_set || ''}
                    >
                        <option value="">None</option>
                        {answerSets.map((answerSet) => (
                            <option
                                key={answerSet.value}
                                value={answerSet.value}
                            >
                                {answerSet.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {question.answer_set === null ? (
                <div className="list-group">
                    {question.survey_question_answers.map((answer) => (
                        <AnswerEditor
                            answer={answer}
                            canEdit={canEdit}
                            csrfToken={csrfToken}
                            destroyable={
                                question.survey_question_answers.length > 1
                            }
                            dispatch={dispatch}
                            key={answer.key}
                            type={
                                [
                                    'single_choice',
                                    'single_choice_other',
                                    'multiple_choices',
                                ].includes(question.question_type)
                                    ? type
                                    : null
                            }
                        />
                    ))}

                    {question.question_type === 'single_choice_other' && (
                        <div className="list-group-item">
                            <TextCheckbox
                                disabled
                                label="Other:"
                                name={question.key}
                                placeholder="Please specify"
                                type={type}
                            />
                        </div>
                    )}

                    {canEdit && (
                        <div className="list-group-item">
                            <a onClick={onAddAnswer} role="button">
                                <Icon name="plus" text="Add answer" />
                            </a>
                        </div>
                    )}
                </div>
            ) : (
                <div className="panel-body">
                    <i>
                        {
                            answerSets.find(
                                (as) => as.value === question.answer_set,
                            ).description
                        }
                    </i>
                </div>
            )}
            <div className="panel-footer">
                <div className="align-items-center d-flex gap-5 justify-content-end">
                    <div className="align-items-center d-flex gap-2">
                        <Switch
                            checked={question.required}
                            disabled={!canEdit}
                            onCheck={onRequiredCheck}
                        />
                        Required
                    </div>
                    {canEdit && (
                        <>
                            <span>|</span>
                            <Tooltip title="Remove question">
                                <Icon
                                    className="text-danger"
                                    name="trash"
                                    onClick={onDestroyClick}
                                />
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
