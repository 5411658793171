import {
    IHomepageBlock,
    IHomepageBlockRequest,
    IProjectDistribution,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { get } from '../../api/utils';
import ErrorAlert from '../common/ErrorAlert';
import Icon from '../common/Icon';
import Panel from '../common/Panel';
import Spinner from '../common/Spinner';

import Chart, { IChartOptions } from './Chart';
import CurrentActivities from './CurrentActivities';
import LeastActive from './LeastActive';
import LowScore from './LowScore';
import SchoolLocationSelector from './SchoolLocationSelector';

interface IProps {
    block: IHomepageBlock;
    collapsed: boolean;
    csrfToken: string;
    id: string;
    loadURI: string;
}

function buildChartOptions(
    projectsDistribution: IProjectDistribution[],
): IChartOptions {
    return {
        chart: {
            height: '20%',
            type: 'column',
        },
        credits: { enabled: false },
        legend: { enabled: true },
        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function(): void {
                            location.href = this.options.key;
                        },
                    },
                },
            },
        },
        series: [
            {
                data: projectsDistribution.map((pp) => ({
                    key: pp.bpi.uri,
                    y: pp.count.current,
                })),
                name: 'Current',
            },
            {
                data: projectsDistribution.map((pp) => ({
                    key: pp.bpi.uri,
                    y: pp.count.done,
                })),
                name: 'Done',
            },
        ],
        title: { text: null },
        tooltip: {
            formatter: function(): string {
                return `<strong>Project</strong>: ${this.x}<br><strong># Learners</strong>: ${this.y}`;
            },
        },
        xAxis: {
            categories: projectsDistribution.map((pp) => pp.bpi.project_name),
            labels: {
                formatter: function(): string {
                    const href = projectsDistribution.find(
                        (pp) => pp.bpi.project_name === this.value,
                    )?.bpi?.project_uri;
                    return `<a href="${href}">${this.value}</a>`;
                },
            },
        },
        yAxis: {
            allowDecimals: false,
            tickPositioner: function(): number[] {
                return this.tickPositions.filter((p: number) => p <= this.max);
            },
            title: null,
        },
    };
}

export default function HomepageBlock({
    block: initialBlock,
    collapsed,
    csrfToken,
    id,
    loadURI,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [block, setBlock] = useState<IHomepageBlock>(initialBlock);
    const [selectedLocation, setSelectedLocation] = useState<
        IHomepageBlock['locations'][0] | null
    >(initialBlock.locations.length > 0 ? initialBlock.locations[0] : null);

    const onExpand = async (): Promise<void> => {
        try {
            setError(null);
            setLoading(true);
            const response = await get<IHomepageBlock, IHomepageBlockRequest>(
                loadURI,
                csrfToken,
                {
                    /* eslint-disable @typescript-eslint/camelcase */
                    bc_ids: block.bc_ids,
                    /* eslint-enable @typescript-eslint/camelcase */
                },
            );
            setBlock(response);
            setSelectedLocation(
                response.locations.length > 0 ? response.locations[0] : null,
            );
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (collapsed) {
            return;
        }
        onExpand();
    }, []);

    return (
        <Panel
            collapsed={collapsed}
            collapsible
            id={id}
            key={block.title}
            onExpand={onExpand}
            title={block.title}
        >
            <div className="d-flex flex-column gap-3 panel-body">
                {loading && <Spinner />}

                {error && <ErrorAlert error={error} />}

                {block.locations.length > 1 && (
                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                        {block.locations.map((location) => (
                            <SchoolLocationSelector
                                key={location.school_location.id}
                                name={location.school_location.name}
                                onSelect={(): void =>
                                    setSelectedLocation(location)
                                }
                                selected={
                                    selectedLocation &&
                                    location.school_location.id ===
                                        selectedLocation.school_location.id
                                }
                            />
                        ))}
                    </div>
                )}

                {selectedLocation && (
                    <>
                        {selectedLocation.projects_distribution ? (
                            <Chart
                                options={buildChartOptions(
                                    selectedLocation.projects_distribution,
                                )}
                            />
                        ) : (
                            <div className="empty-state border-0">
                                <Icon name="chart-bar" />
                                <span className="empty-state-title">
                                    No data to display in this version
                                </span>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-sm-4">
                                <CurrentActivities
                                    currentActivities={
                                        selectedLocation.current_activities
                                    }
                                />
                            </div>
                            <div className="col-sm-4">
                                <LeastActive
                                    leastActiveUsers={
                                        selectedLocation.least_active_users
                                    }
                                />
                            </div>
                            <div className="col-sm-4">
                                <LowScore
                                    lowScoreUsers={
                                        selectedLocation.low_score_users
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Panel>
    );
}
