import {
    EventAttendanceStatus,
    IEventAttendance,
    IEventRsvp,
} from 'holberton-school-intranet-api';
import produce from 'immer';

interface INamable {
    id: number;
    full_name?: string;
    name: string;
}

interface IGroup {
    items: IEventRsvp[];
    label: string;
}
export interface IState {
    attendances: IEventAttendance[];
    csrfToken: string;
    renameAttendanceURI: string;
    updateAttendanceURI: string;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IAction {}
/* eslint-enable @typescript-eslint/no-empty-interface */

export class AddAttendance implements IAction {
    constructor(public attendance: IEventAttendance) {}
}

export class RenameAttendance implements IAction {
    constructor(public index: number, public label: string) {}
}

export class SetLastAttendance implements IAction {
    constructor(public attendance: IEventAttendance) {}
}

export class UpdateStatus implements IAction {
    constructor(
        public index: number,
        public rsvpId: number,
        public status: EventAttendanceStatus,
    ) {}
}

export function reducer(state: IState, action: IAction): IState {
    if (action instanceof RenameAttendance) {
        return produce(state, (draft) => {
            draft.attendances[action.index].label = action.label;
        });
    }

    if (action instanceof AddAttendance) {
        return produce(state, (draft) => {
            draft.attendances.push(action.attendance);
        });
    }

    if (action instanceof SetLastAttendance) {
        return produce(state, (draft) => {
            const index = draft.attendances.length - 1;
            for (const k in action.attendance.status_by_event_rsvp_id) {
                draft.attendances[index].status_by_event_rsvp_id[k] =
                    action.attendance.status_by_event_rsvp_id[k];
            }
        });
    }

    if (action instanceof UpdateStatus) {
        return produce(state, (draft) => {
            draft.attendances[action.index].status_by_event_rsvp_id[
                action.rsvpId
            ].status = action.status;
        });
    }

    return state;
}
