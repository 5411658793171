import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { ISearchSettings } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { connectRange, connectRefinementList } from 'react-instantsearch-core';
import {
    InstantSearch,
    connectInfiniteHits,
    connectSearchBox,
} from 'react-instantsearch-dom';

import Icon from '../../../common/Icon';
import RangeSlider from '../../../common/RangeSlider';
import { BuilderSearchItemRenderer } from '../BuilderSearch';

import Hits, { IHit, IProps as IHitsProps } from './Hits';
import RefinementList from './RefinementList';
import SearchBox from './SearchBox';
import Unrefinable from './Unrefinable';

// https://www.algolia.com/doc/api-reference/widgets/hits/react/#connector
const CustomHits = connectInfiniteHits<IHitsProps, IHit>(
    ({ hasMore, hits, refineNext, renderItem }: IHitsProps) => (
        <>
            {hits.length > 0 && (
                <div className="list-group-item p-0">
                    <Hits
                        hasMore={hasMore}
                        hits={hits}
                        refineNext={refineNext}
                        renderItem={renderItem}
                    />
                </div>
            )}
            {hits.length === 0 && (
                <div className="list-group-item text-center text-warning">
                    No items match your criteria
                </div>
            )}
        </>
    ),
);

// https://www.algolia.com/doc/api-reference/widgets/range-input/react/#connector
const CustomRangeSlider = connectRange(
    ({ canRefine, currentRefinement, max, min, refine }) => {
        if (!canRefine) {
            return <Unrefinable />;
        }

        return (
            <div className="ml-2" style={{ maxWidth: 300 }}>
                <RangeSlider
                    current={currentRefinement}
                    max={max}
                    min={min}
                    onChange={(values): void => {
                        try {
                            refine(values);
                        } catch (err) {
                            // Since calls are async, we can filter on an obsolete dataset
                            // and errors like ""You can't provide max value greater than range."" can occur
                            // https://github.com/algolia/react-instantsearch/issues/2616 (still open)
                            // In any case, we fail silently because we don't want the UI to crash
                        }
                    }}
                />
            </div>
        );
    },
);

// https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/#connector
const CustomRefinementList = connectRefinementList(RefinementList);

// https://www.algolia.com/doc/api-reference/widgets/search-box/react/#connector
const CustomSearchBox = connectSearchBox(SearchBox);

export interface IProps {
    renderItem: BuilderSearchItemRenderer;
    searchSettings: ISearchSettings;
}

export default function BuilderSearchAlgolia({
    renderItem,
    searchSettings,
}: IProps): ReactElement {
    const [client] = useState<SearchClient>(
        algoliasearch(searchSettings.app_id, searchSettings.key),
    );

    return (
        <InstantSearch
            indexName={searchSettings.index_name}
            searchClient={client}
        >
            <div className="list-group-item">
                <CustomSearchBox />

                <div className="collapse" id="advanced-filters">
                    <h5 className="mt-4">
                        <Icon fixedWidth name="globe" /> Public
                    </h5>
                    <div className="px-2">
                        <CustomRefinementList attribute="public" />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="eye" /> Online
                    </h5>
                    <div className="px-2">
                        <CustomRefinementList attribute="online" />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="turn-up" /> Level
                    </h5>
                    <div className="px-2">
                        <CustomRefinementList attribute="level" />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="tags" /> Tags
                    </h5>
                    <div className="px-2">
                        <CustomRefinementList
                            attribute="tags"
                            limit={5}
                            showMore={true}
                            showMoreLimit={100}
                        />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="circle-check" /> Correction type
                    </h5>
                    <div className="px-2">
                        <CustomRefinementList attribute="correction_type" />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="bars-progress" /> Tasks count
                    </h5>
                    <div className="px-2">
                        <CustomRangeSlider attribute="tasks_count" />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="clock" style="regular" /> Work
                        hours
                    </h5>
                    <div className="px-2">
                        <CustomRangeSlider attribute="work_hours" />
                    </div>

                    <h5 className="mt-4">
                        <Icon fixedWidth name="graduation-cap" /> Curriculums
                        count
                    </h5>
                    <div className="px-2">
                        <CustomRangeSlider attribute="curriculums_count" />
                    </div>
                </div>
            </div>

            <CustomHits renderItem={renderItem} />
        </InstantSearch>
    );
}
