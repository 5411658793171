import {
    IUserJourneyActivity,
    IUserJourneyActivityItem,
} from 'holberton-school-intranet-api';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import ActivityTile from './ActivityTile';

export interface IProps {
    activity: IUserJourneyActivity;
    onSelectActivityItem: (activityItem: IUserJourneyActivityItem) => void;
    selectedActivityItem: IUserJourneyActivityItem | null;
}

interface IMonth {
    shortName: string;
    weeks: Moment[];
    year: number;
}

const buildMonths = (activity: IUserJourneyActivity): IMonth[] => {
    return activity.items.reduce<IMonth[]>(
        (months: IMonth[], activityItem: IUserJourneyActivityItem) => {
            const activityDate = moment(activityItem.activity_date, 'YYYYMMDD');
            const startOfWeek = activityDate.clone().startOf('week');
            const monthShortName = startOfWeek.format('MMM');
            const year = parseInt(startOfWeek.format('YYYY'));

            const lastMonth =
                months.length !== 0 ? months[months.length - 1] : null;

            if (
                lastMonth !== null &&
                lastMonth.shortName === monthShortName &&
                lastMonth.year === year
            ) {
                const week = lastMonth.weeks.find((w) => {
                    return (
                        w.format('YYYYMMDD') === startOfWeek.format('YYYYMMDD')
                    );
                });

                if (!week) {
                    lastMonth.weeks.push(startOfWeek);
                }

                return months;
            } else {
                return [
                    ...months,
                    {
                        shortName: monthShortName,
                        weeks: [startOfWeek],
                        year: year,
                    },
                ];
            }
        },
        [],
    );
};

export default function ActivityCalendarGrid({
    activity,
    onSelectActivityItem,
    selectedActivityItem,
}: IProps): ReactElement {
    const [months, setMonths] = useState<IMonth[]>();

    useEffect(() => {
        setMonths(buildMonths(activity));
    }, []);

    return (
        <div className="d-flex">
            <ul className="weekdays">
                {moment
                    .weekdays()
                    .filter((weekday, idx) => {
                        return idx % 2 === 1;
                    })
                    .map((weekday) => (
                        <li key={weekday}>{weekday}</li>
                    ))}
            </ul>
            {months && (
                <div className="flex-grow-1 overflow-auto d-flex flex-row-reverse justify-content-between calendar-grid">
                    {/* empty div, flex-row-reverse and autoflow-auto to auto-scroll to the most recent activity */}
                    <div></div>
                    <table>
                        <thead>
                            <tr>
                                {months.map((month) => (
                                    <th
                                        colSpan={month.weeks.length}
                                        key={`${month.year}-${month.shortName}`}
                                    >
                                        {month.weeks.length > 1
                                            ? month.shortName
                                            : ''}
                                        {month.weeks.length > 3 &&
                                            month.shortName === 'Jan' && (
                                                <span className="ml-1">
                                                    {month.year}
                                                </span>
                                            )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {moment.weekdays().map((weekday, idx) => (
                                <tr className={weekday} key={weekday}>
                                    {months.map((month) =>
                                        month.weeks.map((w) => (
                                            <td
                                                key={w
                                                    .clone()
                                                    .add(idx, 'day')
                                                    .format('YYYYMMDD')}
                                            >
                                                <ActivityTile
                                                    activityItem={activity.items.find(
                                                        (activityItem) => {
                                                            return (
                                                                activityItem.activity_date ===
                                                                w
                                                                    .clone()
                                                                    .add(
                                                                        idx,
                                                                        'day',
                                                                    )
                                                                    .format(
                                                                        'YYYYMMDD',
                                                                    )
                                                            );
                                                        },
                                                    )}
                                                    date={w
                                                        .clone()
                                                        .add(idx, 'day')}
                                                    onSelectActivityItem={
                                                        onSelectActivityItem
                                                    }
                                                    selectedActivityItem={
                                                        selectedActivityItem
                                                    }
                                                />
                                            </td>
                                        )),
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
