import { IUser } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { get } from '../../api/utils';
import CircleProfilePicture from '../common/CircleProfilePicture';
import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';

interface IProps {
    csrfToken: string;
    hideUserIds: number[];
    icon: string;
    label: string;
    onSelectUser: (user: IUser) => void;
    suggestedPeersURI: string;
}

export default function UserPicker({
    csrfToken,
    hideUserIds,
    icon,
    label,
    onSelectUser,
    suggestedPeersURI,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [suggestedPeers, setSuggestedPeers] = useState<IUser[] | null>(null);

    useEffect(() => {
        const loadSuggestedPeers = async (): Promise<void> => {
            try {
                setError(null);
                setLoading(true);
                const response = await get<IUser[]>(
                    suggestedPeersURI,
                    csrfToken,
                );
                setSuggestedPeers(response);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        loadSuggestedPeers();
    }, []);

    const suggestedPeersFiltered = (): IUser[] =>
        suggestedPeers.filter((peers) => !hideUserIds.includes(peers.id));

    return (
        <div className="align-items-start d-flex gap-3 mb-2">
            <div
                className="gap-2 hstack mb-3 pt-3"
                style={{ whiteSpace: 'nowrap' }}
            >
                <Icon className="text-primary" name={icon} />
                <span>{label}</span>
            </div>
            {loading && (
                <Icon className="mt-4" fixedWidth name="spinner" pulse />
            )}
            {error && <ErrorInline className="mt-3" error={error} />}

            {!loading && !error && suggestedPeers && (
                <>
                    {suggestedPeersFiltered().length === 0 ? (
                        <span className="border-left border-left-info text-muted p-2">
                            No peer with a similar activity profile could be
                            found. No need to worry though! The more you are
                            active on the platform, the easier it will be to
                            suggest you peers to work with. Keep up the good
                            work!
                        </span>
                    ) : (
                        <div className="gap-3 d-flex flex-wrap">
                            {suggestedPeersFiltered().map((user) => (
                                <div
                                    className="btn btn-sm btn-default gap-2 hstack"
                                    key={user.id}
                                    onClick={(): void => {
                                        onSelectUser(user);
                                    }}
                                >
                                    <CircleProfilePicture
                                        border={false}
                                        color="primary"
                                        size={30}
                                        tooltip={true}
                                        user={user}
                                    />
                                    <span>{user.full_name}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
