import {
    ICurrentUser,
    IGroupActivityGroup,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';

import JoinButton, { OnJoinCompletedHandler } from './JoinButton';
import LeaveButton from './LeaveButton';
import { isGroupFull } from './utils';

interface IProps {
    csrfToken: string;
    currentUser: ICurrentUser;
    group: IGroupActivityGroup;
    groupMaxSize: number;
    joinGroupURI: string;
    leaveGroupURI: string;
    onJoinCompleted: OnJoinCompletedHandler;
    onLeaveCompleted: OnJoinCompletedHandler;
    studentCanChangeGroup: boolean;
}

export default function GroupActions({
    csrfToken,
    currentUser,
    group,
    groupMaxSize,
    joinGroupURI,
    leaveGroupURI,
    onJoinCompleted,
    onLeaveCompleted,
    studentCanChangeGroup,
}: IProps): ReactElement {
    const userInGroup = group.users.find((u) => u.id === currentUser.id);

    const canJoin =
        currentUser.student &&
        studentCanChangeGroup &&
        !userInGroup &&
        !isGroupFull(group, groupMaxSize) &&
        group.users.length > 0;
    const canLeave =
        currentUser.student && studentCanChangeGroup && userInGroup;

    return (
        <div className="btn-group">
            {group.video_room_url && (
                <a
                    className="btn btn-default btn-sm"
                    href={group.video_room_url}
                    rel="noreferrer"
                    target="_blank"
                >
                    <Icon name="video" text="Access the video room" />
                </a>
            )}

            {canJoin && (
                <JoinButton
                    csrfToken={csrfToken}
                    group={group}
                    joinGroupURI={joinGroupURI}
                    onCompleted={onJoinCompleted}
                />
            )}

            {canLeave && (
                <LeaveButton
                    csrfToken={csrfToken}
                    leaveGroupURI={leaveGroupURI}
                    onCompleted={onLeaveCompleted}
                />
            )}
        </div>
    );
}
