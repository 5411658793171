import {
    IUserTransferTargetBatch,
    IUserTransferTargetCurriculum,
    IUserTransferTargetPeriod,
    IUserTransferTargetTrack,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

export interface IProps {
    targets: IUserTransferTargetCurriculum[];
}

export default function UserTransferTargetSelector({
    targets,
}: IProps): ReactElement {
    const [selectedCurriculum, setSelectedCurriculum] = useState<
        IUserTransferTargetCurriculum
    >(targets.find((c) => c.current) || targets[0]);
    const [
        selectedTrack,
        setSelectedTrack,
    ] = useState<IUserTransferTargetTrack | null>(null);
    const [
        selectedBatch,
        setSelectedBatch,
    ] = useState<IUserTransferTargetBatch | null>(null);
    const [
        selectedPeriod,
        setSelectedPeriod,
    ] = useState<IUserTransferTargetPeriod | null>(null);

    const periodsForSelection = (): IUserTransferTargetPeriod[] => {
        if (selectedBatch) {
            /* Only the first period if:
               - curriculum doesn't have tracks with access
               - selected track is not the current one
            */
            if (
                selectedCurriculum.tracks.length === 0 ||
                (selectedTrack && selectedTrack.current)
            ) {
                return selectedBatch.periods;
            }
            return selectedBatch.periods.filter((p) => p.index === 0);
        }
        return [];
    };

    useEffect(() => {
        setSelectedTrack(
            selectedCurriculum.tracks.find((t) => t.current) ||
                selectedCurriculum.tracks[0] ||
                null,
        );

        setSelectedBatch(selectedCurriculum.batches[0]);
    }, [selectedCurriculum]);

    useEffect(() => {
        if (selectedBatch) {
            setSelectedPeriod(
                periodsForSelection().find((p) => p.current) ||
                    selectedBatch.periods[0],
            );
        }
    }, [selectedBatch]);

    return (
        <>
            <div className="field">
                <label htmlFor="select-curriculum">Curriculum: </label>
                <select
                    className="form-control"
                    id="select-curriculum"
                    name="user_transfer_request[curriculum_id]"
                    onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                        setSelectedCurriculum(
                            targets.find(
                                (c) => c.id.toString() === e.target.value,
                            ),
                        );
                    }}
                    required={true}
                    value={selectedCurriculum.id}
                >
                    {targets.map((curriculum) => (
                        <option
                            key={curriculum.id.toString()}
                            value={curriculum.id}
                        >
                            {`${curriculum.name}${
                                curriculum.current ? ' (current)' : ''
                            }`}
                        </option>
                    ))}
                </select>
            </div>

            {selectedTrack && (
                <div className="field">
                    <label htmlFor="select-track">Track: </label>
                    <select
                        className="form-control"
                        id="select-track"
                        name="user_transfer_request[track_id]"
                        onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                            setSelectedTrack(
                                selectedCurriculum.tracks.find(
                                    (t) => t.id.toString() === e.target.value,
                                ),
                            );
                        }}
                        required={true}
                        value={selectedTrack.id}
                    >
                        {selectedCurriculum.tracks.map((track) => (
                            <option key={track.id.toString()} value={track.id}>
                                {`${track.name} ${
                                    track.current ? '(current)' : ''
                                }`}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {selectedBatch && (
                <div className="field">
                    <label htmlFor="select-batch">Cohort: </label>
                    <select
                        className="form-control"
                        id="select-batch"
                        name="user_transfer_request[batch_id]"
                        onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                            setSelectedBatch(
                                selectedCurriculum.batches.find(
                                    (b) => b.id.toString() === e.target.value,
                                ),
                            );
                        }}
                        required={true}
                        value={selectedBatch.id}
                    >
                        {selectedCurriculum.batches.map((batch) => (
                            <option key={batch.id.toString()} value={batch.id}>
                                {batch.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {selectedPeriod && (
                <div className="field">
                    <label htmlFor="select-period">Period: </label>
                    <select
                        className="form-control"
                        id="select-period"
                        name="user_transfer_request[period_index]"
                        onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                            setSelectedPeriod(
                                periodsForSelection().find(
                                    (p) =>
                                        p.index.toString() === e.target.value,
                                ),
                            );
                        }}
                        required={true}
                        value={selectedPeriod.index}
                    >
                        {periodsForSelection().map((period) => (
                            <option
                                key={period.index.toString()}
                                value={period.index}
                            >
                                {`${period.name}
                                starting
                                ${moment(period.start_date).format(
                                    'dddd Do MMMM YYYY',
                                )}`}
                                {period.project_name &&
                                    ` with the project: ${period.project_name}`}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </>
    );
}
