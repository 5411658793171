import { ICurriculumItemableSearchResult } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useRef } from 'react';
import { HighlightResult } from 'react-instantsearch-core';

import Icon from '../../../common/Icon';
import { BuilderSearchItemRenderer } from '../BuilderSearch';

export interface IHit extends ICurriculumItemableSearchResult {
    _highlightResult: HighlightResult<IHit>;
    objectID: string;
}

export interface IProps {
    hasMore: boolean;
    hits: IHit[];
    refineNext: () => void;
    renderItem: BuilderSearchItemRenderer;
}

export default function Hits({
    hasMore,
    hits,
    refineNext,
    renderItem,
}: IProps): ReactElement {
    const sentinelRef = useRef<HTMLDivElement>(null);

    const onSentinelIntersection = (
        entries: IntersectionObserverEntry[],
    ): void => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && hasMore) {
                refineNext();
            }
        });
    };

    useEffect(() => {
        if (sentinelRef.current) {
            const observer = new IntersectionObserver(onSentinelIntersection);
            observer.observe(sentinelRef.current);

            return (): void => observer.disconnect();
        }
    }, [onSentinelIntersection]);

    return (
        <div
            className="list-group mb-0"
            style={{
                maxHeight: '500px',
                overflowY: 'scroll',
            }}
        >
            {hits.map((hit) => renderItem(hit, hit.model))}

            {hasMore && (
                <div className="list-group-item text-center" ref={sentinelRef}>
                    <Icon name="spinner" pulse />
                </div>
            )}
        </div>
    );
}
