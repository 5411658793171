import { IBatchTimeOff } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useState } from 'react';

import { del, post } from '../../../../api/utils';
import { pluralize } from '../../../../utils';
import ErrorInline from '../../../common/ErrorInline';
import Icon from '../../../common/Icon';
import {
    AddTimeOff,
    IAction,
    IState,
    RemoveTimeOff,
    SetLoading,
} from '../reducer';

interface IProps {
    dispatch: (action: IAction) => void;
    state: IState;
}

const isValid = (dateStr: string): boolean => moment(dateStr).isValid();

export default function TimeOffs({ dispatch, state }: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [loadingID, setLoadingID] = useState<number | null>(null);
    const [end, setEnd] = useState<string>(moment().format('YYYY-MM-DD'));
    const [start, setStart] = useState<string>(moment().format('YYYY-MM-DD'));

    const onChangeStart = (e: ChangeEvent<HTMLInputElement>): void => {
        setStart(e.target.value);
    };

    const onChangeEnd = (e: ChangeEvent<HTMLInputElement>): void => {
        setEnd(e.target.value);
    };

    const onClickRemove = async (timeOff: IBatchTimeOff): Promise<void> => {
        setError(null);
        setLoadingID(timeOff.id);
        dispatch(new SetLoading(true));

        try {
            await del(timeOff.uri, state.csrfToken);
            dispatch(new RemoveTimeOff(timeOff));
        } catch (err) {
            setError(err.message);
        }

        setLoadingID(null);
        dispatch(new SetLoading(false));
    };

    const onSubmit = async (): Promise<void> => {
        if (!isValid(start) || !isValid(end) || moment(end).isBefore(start)) {
            return;
        }
        setError(null);
        dispatch(new SetLoading(true));

        try {
            const timeOff = await post<IBatchTimeOff>(
                state.createBatchTimeOffURI,
                state.csrfToken,
                {
                    /* eslint-disable @typescript-eslint/camelcase */
                    batch_time_off: {
                        batch_id: state.batchID,
                        end_date: end,
                        start_date: start,
                    },
                    /* eslint-enable @typescript-eslint/camelcase */
                },
            );
            dispatch(new AddTimeOff(timeOff));
            setIsEditing(false);
        } catch (err) {
            setError(err.message);
        }

        dispatch(new SetLoading(false));
    };

    return (
        <>
            <div
                aria-controls="collapse-timeOffs"
                aria-expanded={false}
                data-target="#collapse-timeOffs"
                data-toggle="collapse"
                role="button"
            >
                <Icon name="chevron-right" />
                <strong className="ml-2">Time off</strong>
                <small className="ml-2 text-muted">
                    {pluralize(
                        state.timeOffs.reduce(
                            (total, t) =>
                                total +
                                moment(t.end_date).diff(t.start_date, 'days') +
                                1,
                            0,
                        ),
                        'day',
                    )}
                </small>
                <span className="badge float-end">{state.timeOffs.length}</span>
            </div>
            <div className="collapse mt-3" id="collapse-timeOffs">
                {state.timeOffs.map((timeOff) => (
                    <div
                        className="align-items-center border-gray border-top d-flex justify-content-between p-2"
                        key={timeOff.id}
                    >
                        <small className="align-items-center d-flex text-muted gap-2">
                            {moment(timeOff.start_date).format('MMM Do YYYY')}
                            {timeOff.end_date !== timeOff.start_date && (
                                <>
                                    <Icon name="arrow-right-long" />
                                    {moment(timeOff.end_date).format(
                                        'MMM Do YYYY',
                                    )}
                                </>
                            )}
                        </small>
                        {loadingID === timeOff.id ? (
                            <Icon name="spinner" pulse />
                        ) : (
                            <Icon
                                className="text-danger"
                                name="trash"
                                onClick={(): Promise<void> =>
                                    onClickRemove(timeOff)
                                }
                            />
                        )}
                    </div>
                ))}
                <div className="border-gray border-top p-3">
                    {isEditing ? (
                        <div>
                            <div className="d-flex flex-column gap-2">
                                <div
                                    className={`field${
                                        isValid(start) ? '' : '_with_errors'
                                    }`}
                                >
                                    <label htmlFor="planner-time-off-start-date-input">
                                        From
                                    </label>
                                    <input
                                        className="form-control"
                                        disabled={state.loading}
                                        id="planner-time-off-start-date-input"
                                        onChange={onChangeStart}
                                        type="date"
                                        value={start}
                                    />
                                </div>
                                <div
                                    className={`field${
                                        isValid(end) &&
                                        !moment(end).isBefore(start)
                                            ? ''
                                            : '_with_errors'
                                    }`}
                                >
                                    <label htmlFor="planner-time-off-end-date-input">
                                        To
                                    </label>
                                    <input
                                        className="form-control"
                                        disabled={state.loading}
                                        id="planner-time-off-end-date-input"
                                        onChange={onChangeEnd}
                                        type="date"
                                        value={end}
                                    />
                                </div>
                            </div>

                            <div className="align-items-center d-flex justify-content-end gap-5 mt-4">
                                {state.loading ? (
                                    <Icon name="spinner" pulse />
                                ) : (
                                    <>
                                        <Icon
                                            className="text-danger"
                                            name="xmark"
                                            onClick={(): void => {
                                                setError(null);
                                                setIsEditing(false);
                                            }}
                                        />
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={onSubmit}
                                        >
                                            Add
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <a
                            onClick={(): void => setIsEditing(true)}
                            role="button"
                        >
                            + Add
                        </a>
                    )}

                    {error && <ErrorInline error={error} />}
                </div>
            </div>
        </>
    );
}
