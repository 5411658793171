import * as React from 'react';
import { ReactElement, useState } from 'react';

import { gtmUserCustomEvent } from '../../utils';
import Icon from '../common/Icon';
import Spinner from '../common/Spinner';

import { useContainerContext } from './ContainerContext';
import { retake } from './api-rest-adapter';

export default function End(): ReactElement {
    const [endHasBeenTracked, setEndHasBeenTracked] = useState(false);
    const context = useContainerContext();
    const { displayHomeButton, progression, submitting } = context;
    const quizResultState = {
        'correct': { color: 'text-success', icon: 'circle-check' },
        'not answered': { color: 'text-warning', icon: 'circle-minus' },
        'wrong': { color: 'text-danger', icon: 'circle-xmark' },
    };

    if (!progression || !['finished', 'overdue'].includes(progression.status)) {
        return null;
    }

    if (!endHasBeenTracked) {
        setEndHasBeenTracked(true);
        gtmUserCustomEvent('evaluation_quiz_end', {
            evaluationQuizId: context.evaluationQuiz.id,
            evaluationQuizStatus: progression.status,
        });
    }

    const onRetakeClick = async (): Promise<void> => {
        if (submitting) {
            return;
        }
        await retake(context);
        context.setFirstAttempt(false);
        context.setDone(false);
        gtmUserCustomEvent('evaluation_quiz_retake', {
            evaluationQuizId: context.evaluationQuiz.id,
        });
    };

    return (
        <div className="overview">
            <div
                className="description"
                dangerouslySetInnerHTML={{ __html: progression.html }}
            />

            {progression.retakable && (
                <>
                    {submitting ? (
                        <Spinner />
                    ) : (
                        <div className="mb-2 text-left vstack">
                            {progression.score !== undefined && (
                                <span>
                                    Your current score: {progression.score}%
                                </span>
                            )}
                            <span>
                                Click{' '}
                                <a
                                    onClick={onRetakeClick}
                                    style={{ cursor: 'pointer' }}
                                >
                                    here
                                </a>{' '}
                                to retake the quiz for a chance to increase your
                                score.
                            </span>
                        </div>
                    )}
                </>
            )}
            {progression.quiz_overview && (
                <>
                    <h3 className="text-left">Overview</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="text-center">Question</th>
                                <th className="text-center">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {progression.quiz_overview.map((quizResult) => (
                                <tr key={quizResult.question_index}>
                                    <td>
                                        <div className="d-flex gap-1 text-left">
                                            {quizResult.question_index}.
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        quizResult.question_title,
                                                }}
                                            ></span>
                                        </div>
                                    </td>
                                    <td className="align-middle">
                                        <div
                                            className={`d-flex align-items-center text-left ${
                                                quizResultState[
                                                    quizResult.result
                                                ].color
                                            }`}
                                        >
                                            <Icon
                                                name={
                                                    quizResultState[
                                                        quizResult.result
                                                    ].icon
                                                }
                                                text={quizResult.result}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}

            {displayHomeButton && (
                <a className="btn btn-lg btn-primary" href="/">
                    Go back to home
                </a>
            )}
        </div>
    );
}
