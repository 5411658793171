import { SchedulerStatic } from 'dhtmlx-scheduler';
import { ITimeChartEvent } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useEffect, useRef } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_year_view';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_container_autoresize.js';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_tooltip.js';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const scheduler = window.scheduler as SchedulerStatic;

export type SchedulerView = 'day' | 'week' | 'month' | 'year';

export type SchedulerData = ITimeChartEvent & {
    renderPreview?: () => ReactElement;
};

interface IProps {
    data: SchedulerData[];
    endDate: string;
    onClick?: (event: SchedulerData) => void;
    onViewChange?: (mode: string, date: string) => void;
    startDate: string;
    uri?: string;
    views?: SchedulerView[];
}

function configureScheduler(
    start: Date,
    end: Date,
    uri: string,
    views: SchedulerView[],
): void {
    /* eslint-disable @typescript-eslint/camelcase */
    scheduler.tooltip.config.delta_x = 0;
    scheduler.tooltip.config.delta_y = 0;

    scheduler.config.dblclick_create = false;
    scheduler.config.details_on_dblclick = false;
    scheduler.config.drag_create = false;
    scheduler.config.drag_in = false;
    scheduler.config.drag_move = false;
    scheduler.config.drag_out = false;
    scheduler.config.drag_resize = false;
    scheduler.config.edit_on_create = false;
    scheduler.config.header = ['date', 'prev', 'today', 'next'];
    if (views.length > 1) {
        scheduler.config.header = [...views, ...scheduler.config.header];
    }
    if (uri) {
        scheduler.config.load_date = '%Y-%m-%d';
        scheduler.config.show_loading = true;
    }
    scheduler.config.readonly = true;
    scheduler.config.start_on_monday = false;
    scheduler.config.touch = true;

    scheduler.templates.event_class = (start, end, event): string =>
        event.className;
    scheduler.templates.month_day = (date): string => {
        const dayStr = scheduler.date.date_to_str('%j')(date);
        let className = null;

        if (moment(date).isBefore(start, 'day')) {
            className = 'before-start';
        } else if (moment(date).isAfter(end, 'day')) {
            className = 'after-end';
        }

        className = className ? ` class="${className}"` : '';
        return `<div${className}>${dayStr}</div>`;
    };
    scheduler.templates.tooltip_text = (start, end, event): string =>
        event.tooltip;
    scheduler.templates.year_date = scheduler.date.date_to_str('%Y');
    scheduler.templates.year_scale_date = (date): string => {
        return scheduler.date.date_to_str('%D')(date)[0];
    };
    /* eslint-enable @typescript-eslint/camelcase */
}

export default function Scheduler({
    data,
    endDate,
    onClick,
    onViewChange,
    startDate,
    uri,
    views = ['day', 'week', 'month'],
}: IProps): ReactElement {
    const schedulerContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        configureScheduler(new Date(startDate), new Date(endDate), uri, views);
        scheduler.init(
            schedulerContainerRef.current,
            new Date(startDate),
            views[views.length - 1],
        );
        const clickHandler = scheduler.attachEvent('onClick', (id) => {
            const event = scheduler.getEvent(id) as SchedulerData;

            if (onClick) {
                onClick(event);
            } else if (event.uri) {
                const location = event.uri;
                document.location.href = location;
            }
        });
        let viewChangeHandler = null;
        if (onViewChange) {
            viewChangeHandler = scheduler.attachEvent(
                'onViewChange',
                onViewChange,
            );
        }

        return (): void => {
            scheduler.detachEvent(clickHandler);
            viewChangeHandler && scheduler.detachEvent(viewChangeHandler);
        };
    }, []);

    useEffect(() => {
        configureScheduler(new Date(startDate), new Date(endDate), uri, views);
        scheduler.clearAll();
        if (uri) {
            scheduler.setLoadMode('month');
            scheduler.load(uri);
        } else {
            scheduler.parse(data);
        }
        scheduler.render();
    }, [data, endDate, startDate]);

    // We need to give an arbitrary height to the scheduler container
    return <div ref={schedulerContainerRef} style={{ height: '42px' }} />;
}
