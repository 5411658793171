import * as React from 'react';
import {
    ChangeEvent,
    FormEvent,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from 'react';

import { patch } from '../../api/utils';

import Icon from './Icon';

interface IProps<T> {
    canEdit?: boolean;
    children?: ReactNode;
    csrfToken: string;
    editing?: boolean;
    isValid?: (value: string | number) => boolean;
    max?: string | number;
    maxLength?: number;
    min?: string | number;
    name: string;
    onCancel?: () => void;
    onError?: (error: string | null) => void;
    onUpdate?: (response: T) => void;
    requestBodyContainer?: string;
    step?: string | number;
    type?: string;
    updateURI: string;
    value: string | number;
}

export default function UniqueFieldForm<T>({
    canEdit = true,
    children,
    csrfToken,
    editing: initialEditing = false,
    isValid,
    max,
    maxLength,
    min,
    name,
    onCancel: onCancelProp,
    onError,
    onUpdate,
    requestBodyContainer,
    step,
    type = 'text',
    updateURI,
    value: initialValue,
}: IProps<T>): ReactElement {
    const [editing, setEditing] = useState<boolean>(initialEditing);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string | number>(initialValue);
    const [inputValue, setInputValue] = useState<string | number>(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const onCancel = (): void => {
        onCancelProp?.();
        if (isValid && !isValid(initialValue)) {
            return;
        }

        setInputValue(initialValue);
        setEditing(false);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setInputValue(e.target.value);
    };

    const onSubmit = async (e?: FormEvent): Promise<void> => {
        e.preventDefault();
        if (isValid && !isValid(inputValue)) {
            return;
        }

        if (inputValue === initialValue) {
            setEditing(false);
            return;
        }

        onError?.(null);
        setIsLoading(true);

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let body: any = { [name]: inputValue };
            if (requestBodyContainer) {
                body = { [requestBodyContainer]: body };
            }

            const response = await patch<T>(updateURI, csrfToken, body);
            onUpdate?.(response);
            setValue(response[name]);
            setEditing(false);
        } catch (err) {
            onError?.(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {editing ? (
                <form
                    className="align-items-center d-inline-flex gap-3 w-full"
                    onSubmit={onSubmit}
                >
                    <input
                        autoFocus={true}
                        className="form-control"
                        disabled={isLoading}
                        max={max}
                        maxLength={maxLength}
                        min={min}
                        onChange={onChange}
                        step={step}
                        type={type}
                        value={inputValue}
                    />

                    <small className="d-inline-flex gap-2">
                        {isLoading ? (
                            <Icon name="circle-notch" spin />
                        ) : (
                            <>
                                <Icon name="check" onClick={onSubmit} />
                                <Icon
                                    className="text-danger"
                                    name="xmark"
                                    onClick={onCancel}
                                />
                            </>
                        )}
                    </small>
                </form>
            ) : (
                <>
                    {children || value}
                    {canEdit && (
                        <small>
                            <Icon
                                className="ml-2"
                                name="pencil"
                                onClick={(): void => setEditing(true)}
                            />
                        </small>
                    )}
                </>
            )}
        </>
    );
}
