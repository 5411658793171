import { IEventAttendance, IEventRsvp } from 'holberton-school-intranet-api';
import * as React from 'react';
import { Key, ReactElement, Reducer, useReducer } from 'react';

import { patch, post } from '../../../api/utils';
import Tooltip from '../../common/Tooltip';
import UniqueFieldForm from '../../common/UniqueFieldForm';
import DataTable from '../../common/data_table/DataTable';
import EventRsvpDataTableCell from '../common/EventRsvpDataTableCell';

import AttendanceInput, { ATTENDANCE_STATUSES } from './AttendanceInput';
import {
    AddAttendance,
    IAction,
    IState,
    RenameAttendance,
    SetLastAttendance,
    reducer,
} from './reducer';

interface IProps {
    csrfToken: string;
    eventAttendances: IEventAttendance[];
    eventRsvps: IEventRsvp[];
    renameAttendanceURI: string;
    startAttendanceURI: string;
    updateAttendanceURI: string;
}

export default function Attendance({
    csrfToken,
    eventAttendances,
    eventRsvps,
    renameAttendanceURI,
    startAttendanceURI,
    updateAttendanceURI,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        attendances: eventAttendances,
        csrfToken,
        renameAttendanceURI,
        updateAttendanceURI,
    });

    // Callbacks
    const bulkMarkAs = async (v: string, ids: number[]): Promise<void> => {
        // NOTE: Error is caught in the caller
        const res = await patch<IEventAttendance>(
            state.updateAttendanceURI,
            state.csrfToken,
            {
                /* eslint-disable @typescript-eslint/camelcase */
                event_attendance: { status: v.toLowerCase() },
                /* eslint-enable @typescript-eslint/camelcase */
                ids,
            },
        );
        dispatch(new SetLastAttendance(res));
    };

    const onCreateAttendance = async (): Promise<void> => {
        // NOTE: Error is caught in the caller
        const res = await post<IEventAttendance>(
            startAttendanceURI,
            state.csrfToken,
            {},
        );
        dispatch(new AddAttendance(res));
    };

    // Data table attributes
    const renderCell = (
        rsvp: IEventRsvp,
        index: number,
        attendance: IEventAttendance,
    ): ReactElement => (
        <AttendanceInput
            attendance={attendance.status_by_event_rsvp_id[rsvp.id]}
            dispatch={dispatch}
            index={index}
            rsvp={rsvp}
            state={state}
        />
    );
    const renderHeading = (
        attendance: IEventAttendance,
        index: number,
    ): ReactElement => (
        <UniqueFieldForm
            csrfToken={state.csrfToken}
            name={attendance.label}
            onUpdate={(res: IEventAttendance): void =>
                dispatch(new RenameAttendance(index, res.label))
            }
            requestBodyContainer="rename"
            updateURI={state.renameAttendanceURI}
            value={attendance.label}
        >
            <Tooltip title={`Taken by ${attendance.author.full_name}`}>
                {attendance.label}
            </Tooltip>
        </UniqueFieldForm>
    );

    const bulks = [
        {
            onSubmit: bulkMarkAs,
            options: ATTENDANCE_STATUSES.reduce(
                (h, s) => ({ ...h, [s.value]: s.value }),
                {},
            ),
            title: 'Mark as',
        },
    ];
    const cta = {
        icon: 'plus',
        onClick: onCreateAttendance,
        title: 'Take attendance',
    };
    const columns = {
        columns: state.attendances,
        keyExtractor: (attendance: IEventAttendance): Key => attendance.label,
        renderCell,
        renderHeading,
    };
    const filtering = {
        attribute: 'status',
        values: ['Yes', 'Pending', 'No'],
    };
    const grouping = {
        'Cohort': 'user.batch',
        'School location': 'user.batch.school_location',
    };
    const searchBy = ['user.full_name', 'user.id'];
    const sorting = { ID: 'user.id', Name: 'user.full_name' };

    return (
        <DataTable
            bulks={bulks}
            columns={columns}
            csrfToken={csrfToken}
            cta={cta}
            filtering={filtering}
            grouping={grouping}
            items={eventRsvps}
            keyExtractor={(item: IEventRsvp): Key => item.id}
            renderItem={(item: IEventRsvp): ReactElement => (
                <EventRsvpDataTableCell eventRsvp={item} />
            )}
            searchBy={searchBy}
            sorting={sorting}
        />
    );
}
