import {
    ILtiContentItem,
    ILtiDeepLinkResponse,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

export type OnSelect = (item: ILtiContentItem) => void;

export function isSelected(
    i1: ILtiContentItem,
    i2: ILtiContentItem | null,
): boolean {
    if (!i2) {
        return false;
    }
    return i1.type === i2.type && i1.title === i2.title;
}

interface IProps {
    items: ILtiDeepLinkResponse['https://purl.imsglobal.org/spec/lti-dl/claim/content_items'];
    onSelect: OnSelect;
    selectedItem: ILtiContentItem | null;
}

export default function ContentItemsTable({
    items,
    onSelect,
    selectedItem,
}: IProps): ReactElement {
    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Custom</th>
                    <th>URL</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, idx) => (
                    <tr
                        className={
                            isSelected(item, selectedItem)
                                ? 'bg-success'
                                : undefined
                        }
                        key={[item.type, idx].join('-')}
                        onClick={(): void => onSelect(item)}
                        style={{ cursor: 'pointer' }}
                    >
                        <td>
                            <span className="badge">{item.type}</span>
                        </td>
                        <td>{item.title}</td>
                        <td>
                            {Object.entries(item.custom || {}).map(([k, v]) => (
                                <span className="d-block" key={k}>
                                    {k}: {v}
                                </span>
                            ))}
                        </td>
                        <td>{item.url}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
