import {
    IUserContainer,
    IUserContainerStartRequest,
    IUserContainerStartResponse,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { del, get, post, put } from '../../api/utils';
import { gtmUserCustomEvent, pluralize } from '../../utils';
import CopyOnClick from '../common/CopyOnClick';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

import {
    IAction,
    IUserContainerState,
    RestartContainer,
    SetError,
    SetLoading,
    StopContainer,
    UpdateContainer,
} from './reducer';

interface IProps {
    containerModelName: string;
    csrfToken: string;
    dispatch: (value: IAction) => void;
    startStatusURI: string;
    startURI: string;
    state: IUserContainerState;
}

export default function UserContainerActions({
    containerModelName,
    csrfToken,
    dispatch,
    startStatusURI,
    startURI,
    state,
}: IProps): ReactElement {
    const id = state.containerSpec.id;
    const sshHost = state.containerSpec.container?.host;
    const sshUser = state.containerSpec.container?.container_id?.slice(0, 12);
    const restartURL = state.containerSpec.container?.restart_uri;
    const restartNotAvailableTooltip = restartURL
        ? `Restart your ${containerModelName}`
        : `Restart is available only on new ${pluralize(2, containerModelName, {
              strOnly: true,
          })}. To access this feature, please make sure all your data is backed up or pushed to GitHub before destroying this ${containerModelName} and requesting a new one`;

    const onClickRun = async (): Promise<void> => {
        if (state.loading || !state.containerSpec.available) {
            return;
        }

        dispatch(new SetLoading(id, `Spinning up ${containerModelName} ...`));

        gtmUserCustomEvent('sandbox_run', {
            sandboxContainerSpecId: id,
        });

        try {
            const request: IUserContainerStartRequest = {
                /* eslint-disable @typescript-eslint/camelcase */
                container_spec_id: state.containerSpec.id,
                /* eslint-enable @typescript-eslint/camelcase */
            };
            await post<IUserContainerStartResponse, IUserContainerStartRequest>(
                startURI,
                csrfToken,
                request,
            );

            let container: IUserContainer | null = null;
            const maxRetries = 5;
            const retryInterval = 4000;
            let retryCount = 0;

            while (retryCount < maxRetries) {
                const response = await get<
                    IUserContainerStartResponse,
                    IUserContainerStartRequest
                >(startStatusURI, csrfToken, request);
                retryCount += 1;
                if (response.container) {
                    container = {
                        /* eslint-disable @typescript-eslint/camelcase */
                        container_id: response.container.container_id,
                        host: response.container.public_ip,
                        id: response.container.id,
                        password: response.container.ssh_password,
                        ports: response.container.ports,
                        restart_uri: response.container.restart_uri,
                        status: response.container.status,
                        uri: response.container.uri,
                        wake_uri: response.container.wake_uri,
                        webterm_uri: response.container.webterm_uri,
                        /* eslint-enable @typescript-eslint/camelcase */
                    };
                    dispatch(new UpdateContainer(id, container));
                    break;
                }

                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, retryInterval);
                });
            }

            if (!container) {
                throw new Error(
                    `Could not get the ${containerModelName} status. Please refresh the page...`,
                );
            }
        } catch (err) {
            dispatch(new SetError(id, err.message));

            gtmUserCustomEvent('sandbox_error', {
                sandboxAction: 'run',
                sandboxContainerSpecId: id,
                sandboxMessage: err.message,
            });
        }
    };

    const onClickWake = async (): Promise<void> => {
        if (state.loading) {
            return;
        }

        dispatch(new SetLoading(id, `Waking up container ...`));

        gtmUserCustomEvent('sandbox_wake', {
            sandboxContainerSpecId: id,
        });

        try {
            await put<IUserContainerStartResponse, IUserContainerStartRequest>(
                state.containerSpec.container.wake_uri,
                csrfToken,
            );

            let container: IUserContainer | null = null;
            const maxRetries = 5;
            const retryInterval = 4000;
            let retryCount = 0;

            while (retryCount < maxRetries) {
                container = await get<IUserContainer>(
                    state.containerSpec.container.uri,
                    csrfToken,
                );
                retryCount += 1;

                if (container?.status === 'running') {
                    dispatch(new UpdateContainer(id, container));
                    break;
                }

                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, retryInterval);
                });
            }

            if (!container) {
                throw new Error(
                    `Could not get container. Please refresh the page...`,
                );
            }
        } catch (err) {
            dispatch(new SetError(id, err.message));

            gtmUserCustomEvent('sandbox_error', {
                sandboxAction: 'wake',
                sandboxContainerSpecId: id,
                sandboxMessage: err.message,
            });
        }
    };

    const onClickStop = async (): Promise<void> => {
        const confirmMsg = `All data in this ${containerModelName} will be lost. Are you sure you want to destroy it?`;
        if (state.loading || !confirm(confirmMsg)) {
            return;
        }

        dispatch(new SetLoading(id, `Stopping ${containerModelName} ...`));

        gtmUserCustomEvent('sandbox_stop', {
            sandboxContainerSpecId: id,
        });

        try {
            await del(state.containerSpec.container.uri, csrfToken);
            dispatch(new StopContainer(id));
        } catch (err) {
            dispatch(new SetError(id, err.message));

            gtmUserCustomEvent('sandbox_error', {
                sandboxAction: 'stop',
                sandboxContainerSpecId: id,
                sandboxMessage: err.message,
            });
        }
    };

    const onClickRestart = async (): Promise<void> => {
        const confirmMsg = `Are you sure you want to restart this ${containerModelName}?`;
        if (state.loading || !restartURL || !confirm(confirmMsg)) {
            return;
        }

        dispatch(new SetLoading(id, `Restarting ${containerModelName} ...`));

        gtmUserCustomEvent('sandbox_restart', {
            sandboxContainerSpecId: id,
        });

        try {
            await put(restartURL, csrfToken);
            dispatch(new RestartContainer(id));
        } catch (err) {
            dispatch(new SetError(id, err.message));

            gtmUserCustomEvent('sandbox_error', {
                sandboxAction: 'restart',
                sandboxContainerSpecId: id,
                sandboxMessage: err.message,
            });
        }
    };

    const onSandboxCopyCommand = (
        action: 'ssh' | 'sftp',
        command: string,
    ): void => {
        gtmUserCustomEvent('sandbox_copy_command', {
            sandboxAction: action,
            sandboxContainerSpecId: id,
            sandboxMessage: command,
        });
    };

    return (
        <div className="d-flex flex-wrap gap-5">
            {!state.loading &&
                state.containerSpec.container?.status === 'running' && (
                    <div className="align-items-center d-flex gap-3">
                        <div>
                            <CopyOnClick
                                copyText={`ssh ${sshUser}@${sshHost}`}
                                title="Copy SSH command"
                                tooltipPlacement="top"
                            >
                                <button
                                    className="btn btn-default"
                                    onClick={(): void =>
                                        onSandboxCopyCommand(
                                            'ssh',
                                            `ssh ${sshUser}@${sshHost}`,
                                        )
                                    }
                                >
                                    SSH
                                </button>
                            </CopyOnClick>
                        </div>

                        <div>
                            <CopyOnClick
                                copyText={`sftp ${sshUser}@${sshHost}`}
                                title="Copy SFTP command"
                                tooltipPlacement="top"
                            >
                                <button
                                    className="btn btn-default"
                                    onClick={(): void =>
                                        onSandboxCopyCommand(
                                            'sftp',
                                            `sftp ${sshUser}@${sshHost}`,
                                        )
                                    }
                                >
                                    SFTP
                                </button>
                            </CopyOnClick>
                        </div>
                        <a
                            className="btn btn-webterm"
                            href={state.containerSpec.container.webterm_uri}
                            onClick={(): void =>
                                gtmUserCustomEvent('sandbox_start_webterm', {
                                    sandboxContainerSpecId: id,
                                })
                            }
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Icon name="terminal" text="Webterm" />
                        </a>
                    </div>
                )}

            {state.containerSpec.container?.status === 'running' && (
                <Tooltip title={restartNotAvailableTooltip}>
                    <a
                        className={`btn btn-warning ${
                            state.loading ? ' disabled' : ''
                        }${restartURL ? '' : ' disabled'}`}
                        onClick={onClickRestart}
                    >
                        <Icon
                            name={state.loading ? 'spinner' : 'power-off'}
                            pulse={state.loading}
                            text={state.loading ? 'Loading' : 'Restart'}
                        />
                    </a>
                </Tooltip>
            )}

            {state.containerSpec.container?.status !== 'running' && (
                <a
                    className={`btn btn-success${
                        state.loading || !state.containerSpec.available
                            ? ' disabled'
                            : ''
                    }`}
                    onClick={
                        state.containerSpec.container?.status === 'asleep'
                            ? onClickWake
                            : state.containerSpec.available
                            ? onClickRun
                            : undefined
                    }
                >
                    <Icon
                        name={state.loading ? 'spinner' : 'play'}
                        pulse={state.loading}
                        text={
                            state.loading
                                ? 'Loading'
                                : state.containerSpec.container?.status ===
                                  'asleep'
                                ? 'Wake'
                                : state.containerSpec.available
                                ? 'Run'
                                : 'Not available'
                        }
                    />
                </a>
            )}

            {state.containerSpec.container && (
                <a
                    className={`btn btn-danger${
                        state.loading ? ' disabled' : ''
                    }`}
                    onClick={onClickStop}
                >
                    <Icon
                        name={state.loading ? 'spinner' : 'trash'}
                        pulse={state.loading}
                        text={state.loading ? 'Loading' : 'Destroy'}
                    />
                </a>
            )}
        </div>
    );
}
