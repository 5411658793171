import {
    ICheck,
    IManualSelfCorrectionResponse,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';

import Icon from '../common/Icon';

import ManualCheck from './ManualCheck';

interface IProps {
    checks: ICheck[];
    csrfToken: string;
    taskID: number;
}

export default function ManualReview({
    checks,
    csrfToken,
    taskID,
}: IProps): ReactElement {
    const [checkList, setCheckList] = useState<ICheck[]>(checks);
    const carouselID = `task-${taskID}-carousel`;
    const nextRef = useRef<HTMLAnchorElement>();
    const slideToRef = useRef<Array<HTMLLIElement>>(new Array(checks.length));

    // Automatically slide to the next pending check
    const slideToPendingCheck = (current: ICheck, list: ICheck[]): void => {
        if (!current.isLoading && current.passed) {
            const targetCheck = list.find(
                (c) => !c.passed && c.position > current.position,
            );
            const targetRef =
                slideToRef.current[list.indexOf(targetCheck)] ||
                slideToRef.current[list.length - 1];
            targetRef && targetRef.click();
        }
    };

    const onCheckChange = (
        check: ICheck,
        response: IManualSelfCorrectionResponse | null,
    ): void => {
        let list = checkList.filter((c) => c.id !== check.id);
        list = [...list, check].sort((c1, c2) => c1.position - c2.position);
        setCheckList(list);
        slideToPendingCheck(check, list);

        if (!response) {
            return;
        }

        const taskProgress = response.progress.tasks.find(
            (t) => t.id === taskID,
        );
        const { passed, score } = taskProgress.score_info;

        // Update task footer
        const scoreElement = document.getElementById(
            `task-${taskID}-score-info-score`,
        );
        if (scoreElement) {
            scoreElement.parentElement.className = passed
                ? 'text-success'
                : 'text-primary';
            scoreElement.innerHTML = score.toString();
        }

        // Notify React components so they can do whatever they want
        document.dispatchEvent(
            new CustomEvent('project_progress_changed', {
                detail: response.progress,
            }),
        );
    };

    useEffect(() => {
        slideToPendingCheck(checks[0], checks);
    }, []);

    return (
        <>
            {checks[0].update_correction_uri ? (
                <div className="alert alert-info">
                    Please review your task manually with the following
                    checklist
                </div>
            ) : (
                <div className="alert alert-warning">Reviews are closed</div>
            )}
            <div
                className="carousel slide"
                data-interval={false}
                data-keyboard={false}
                data-ride="carousel"
                data-wrap={false}
                id={carouselID}
                style={{ flexGrow: 1 }}
            >
                {/* Carousel indicators */}
                <ol
                    className="carousel-indicators"
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: 0,
                        position: 'static',
                        width: 'auto',
                    }}
                >
                    {checkList.map((check, i) => (
                        <li
                            className={`text-info ${i === 0 ? 'active' : ''}`}
                            data-slide-to={i}
                            data-target={`#${carouselID}`}
                            key={`check-indicator-${i}`}
                            ref={(el): HTMLLIElement =>
                                (slideToRef.current[i] = el)
                            }
                            role="button"
                        >
                            {check.isLoading ? (
                                <Icon fixedWidth name="circle-notch" spin />
                            ) : (
                                <Icon
                                    fixedWidth
                                    name={check.passed ? 'check' : 'circle-dot'}
                                    style={check.passed ? 'solid' : 'regular'}
                                />
                            )}
                        </li>
                    ))}
                </ol>

                <div
                    style={{
                        alignItems: 'stretch',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <a
                        className="text-info"
                        data-slide="prev"
                        href={`#${carouselID}`}
                        role="button"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingRight: '24px',
                            textDecoration: 'none',
                        }}
                    >
                        <Icon name="chevron-left" size="lg" />
                    </a>

                    {/* Wrapper for slides */}
                    <div className="carousel-inner" role="listbox">
                        {checkList.map((check, i) => (
                            <ManualCheck
                                check={check}
                                csrfToken={csrfToken}
                                index={i}
                                key={check.id}
                                onCheckChange={onCheckChange}
                            />
                        ))}
                    </div>
                    <a
                        className="text-info"
                        data-slide="next"
                        href={`#${carouselID}`}
                        ref={nextRef}
                        role="button"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingLeft: '24px',
                            textDecoration: 'none',
                        }}
                    >
                        <Icon name="chevron-right" size="lg" />
                    </a>
                </div>
            </div>
        </>
    );
}
