// Exception because the API in ruby returns snake case fields
/* eslint @typescript-eslint/camelcase: "off" */

/**
 * We might plan to switch to WebSockets in the future, so all the calls to the REST API
 * need to be performed from here. This way, our components are transport-agnostic and it
 * will be easier to switch the adapter if necessary
 */

import {
    EvaluationQuizNavigationWay,
    IEvaluationQuizSubmitRequest,
    IEvaluationQuizSubmitResponse,
} from 'holberton-school-intranet-api';

import { post } from '../../api/utils';
import { gtmUserCustomEvent } from '../../utils';

import { IContainerContext } from './ContainerContext';
import { isInProgress } from './api-utils';

async function send(
    context: IContainerContext,
    body: IEvaluationQuizSubmitRequest,
    showIndicator = true,
): Promise<void> {
    const {
        csrfToken,
        setError,
        setNotFocusDuration,
        setProgression,
        setSubmitting,
        submitURI,
    } = context;

    if (showIndicator) {
        setSubmitting(true);
    }

    setError(null);

    try {
        const response = await post<IEvaluationQuizSubmitResponse>(
            submitURI,
            csrfToken,
            body,
        );
        if (showIndicator) {
            setNotFocusDuration(0);
            setProgression(response);
        }
    } catch (err) {
        if (showIndicator) {
            setError(err.message);
        }
    }

    if (showIndicator) {
        setSubmitting(false);
    }
}

export async function fetchInitialData(
    context: IContainerContext,
): Promise<void> {
    await send(context, {
        bp_action: false,
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
    });
}

export async function retake(context: IContainerContext): Promise<void> {
    await send(context, {
        bp_action: true,
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
        navigate: 'Retake',
    });
}

export async function doneWithRetake(
    context: IContainerContext,
): Promise<void> {
    await send(context, {
        bp_action: true,
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
        navigate: 'DoneWithRetake',
    });
}

export async function start(context: IContainerContext): Promise<void> {
    await send(context, {
        bp_action: true,
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
    });
}

export async function skip(context: IContainerContext): Promise<void> {
    await send(context, {
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
        skip: true,
    });
}

export async function submitAnswers(
    context: IContainerContext,
    answers: string[],
): Promise<void> {
    if (!isInProgress(context.progression)) {
        return;
    }

    gtmUserCustomEvent('evaluation_quiz_submit', {
        evaluationQuizAnswers: answers,
        evaluationQuizId: context.evaluationQuiz.id,
        evaluationQuizQCategory: context.progression.question.category,
        evaluationQuizQuestionId: context.progression.question.id,
    });

    await send(context, {
        answers,
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
        not_focus_duration: context.notFocusDuration,
        question_id: context.progression.question.id,
    });
}

export async function navigate(
    context: IContainerContext,
    way: EvaluationQuizNavigationWay,
): Promise<void> {
    if (!isInProgress(context.progression)) {
        return;
    }

    gtmUserCustomEvent('evaluation_quiz_navigate', {
        evaluationQuizId: context.evaluationQuiz.id,
        evaluationQuizNavigateTo: way,
        evaluationQuizQCategory: context.progression.question.category,
        evaluationQuizQuestionId: context.progression.question.id,
    });

    await send(context, {
        evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
        is_tmp: true,
        navigate: way,
        question_id: context.progression.question.id,
    });
}

export async function notifyNotFocused(
    context: IContainerContext,
    incrementedNotFocusDuration: number,
): Promise<void> {
    if (!isInProgress(context.progression)) {
        return;
    }

    await send(
        context,
        {
            evaluation_quiz_correction_id: context.evaluationQuizCorrection.id,
            is_tmp: true,
            not_focus_duration: incrementedNotFocusDuration,
            question_id: context.progression.question.id,
        },
        false,
    );
}
