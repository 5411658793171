import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Search, { match } from '../common/Search';

import VideoThumbnail from './VideoThumbnail';
import { IVideo } from './types';

interface IProps {
    canManageVideos: boolean;
    onVideoClick?: (video: IVideo) => void;
    search: string;
    video?: IVideo;
    videos: IVideo[];
}

export default function Library({
    canManageVideos,
    onVideoClick,
    search,
    video,
    videos,
}: IProps): ReactElement {
    const [videosList, setVideosList] = useState<IVideo[]>(videos);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        if (search) {
            setSearchQuery(search);
        }
    }, [search]);

    useEffect(() => {
        let filtered = videos;
        if (searchQuery) {
            filtered = videos.filter((vr) => match(searchQuery, vr.title));
        }

        setVideosList(filtered);
    }, [searchQuery]);

    return (
        <div className="panel panel-default sm-gap">
            <div className="panel-heading panel-heading-actions">
                <h3 className="panel-title">
                    Video library
                    <small style={{ marginLeft: '8px' }}>
                        ({videosList.length} total)
                    </small>
                </h3>
                {canManageVideos && (
                    <div className="btn-group">
                        <a className="btn btn-default" href="/videos">
                            Manage videos
                        </a>
                    </div>
                )}
            </div>
            <div className="list-group">
                <div className="list-group-item">
                    <Search
                        onSearch={setSearchQuery}
                        placeholder="Search by title"
                        search={searchQuery}
                    />
                </div>
            </div>
            <div className="list-group">
                <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                    {videosList.map((vr) => (
                        <VideoThumbnail
                            height="126px"
                            key={vr.id}
                            onClick={onVideoClick}
                            selected={vr.id === video?.id}
                            video={vr}
                            width="224px"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
