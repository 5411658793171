import * as React from 'react';
import { ReactElement, useState } from 'react';
import Rheostat from 'rheostat';

import { durationToString } from '../../utils';

interface IValues {
    max: number | undefined;
    min: number | undefined;
}

type Formater = 'duration' | 'percentage';

interface IProps extends IValues {
    current: IValues;
    max: number;
    min: number;
    parameterName: string;
    formater?: Formater;
}

const formatValue = (value: number, formater: Formater): string => {
    switch (formater) {
        case 'duration':
            return durationToString(value);
        case 'percentage':
            return `${value}%`;
        default:
            return value.toString();
    }
};

export default function RangeSlider({
    current,
    formater,
    max,
    min,
    parameterName,
}: IProps): ReactElement | null {
    const [live, setLive] = useState<IValues>({
        max: current.max || max,
        min: current.min || min,
    });

    return (
        <div className="mx-2">
            <Rheostat
                disabled={max === min}
                max={max}
                min={min}
                onValuesUpdated={({ values: [min, max] }): void => {
                    setLive({ max, min });
                }}
                values={[current.min, current.max]}
            />
            <div className="d-flex justify-content-between">
                {live.min && live.min !== min ? (
                    <small>min: {formatValue(live.min, formater)}</small>
                ) : (
                    <small className="text-muted">no min</small>
                )}

                {live.max && live.max !== max ? (
                    <small>max: {formatValue(live.max, formater)}</small>
                ) : (
                    <small className="text-muted">no max</small>
                )}
            </div>
            <div style={{ display: 'none' }}>
                <input
                    name={`q[${parameterName}_gt]`}
                    readOnly
                    value={live.min !== min ? live.min : ''}
                />
                <input
                    name={`q[${parameterName}_lteq]`}
                    readOnly
                    value={live.max !== max ? live.max : ''}
                />
            </div>
        </div>
    );
}
