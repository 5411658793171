import {
    ISurveyQuestion,
    ISurveyQuestionAnswer,
} from 'holberton-school-intranet-api';
import produce from 'immer';

export interface IState {
    questions: ISurveyQuestion[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAction {}

export class AddAnswer implements IAction {
    constructor(
        public question: ISurveyQuestion,
        public answer: ISurveyQuestionAnswer,
    ) {}
}

export class AddQuestion implements IAction {
    constructor(public question: ISurveyQuestion) {}
}

export class RemoveAnswer implements IAction {
    constructor(public answer: ISurveyQuestionAnswer) {}
}

export class RemoveQuestion implements IAction {
    constructor(public question: ISurveyQuestion) {}
}

export class UpdateAnswer implements IAction {
    constructor(public answer: ISurveyQuestionAnswer) {}
}

export class UpdateQuestion implements IAction {
    constructor(public question: ISurveyQuestion) {}
}

export default function reduce(state: IState, action: IAction): IState {
    if (action instanceof AddAnswer) {
        return produce(state, (draft) => {
            const question = draft.questions.find(
                (q) => q.key === action.question.key,
            );
            question.survey_question_answers.push({ ...action.answer });
        });
    }

    if (action instanceof AddQuestion) {
        return produce(state, (draft) => {
            draft.questions.push({ ...action.question });
        });
    }

    if (action instanceof RemoveAnswer) {
        return produce(state, (draft) => {
            draft.questions.some((q) => {
                const index = q.survey_question_answers.findIndex(
                    (a) => a.key === action.answer.key,
                );
                if (index !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    q.survey_question_answers = q.survey_question_answers.filter(
                        (a) => a.key !== action.answer.key,
                    );
                    return true;
                }
            });
        });
    }

    if (action instanceof RemoveQuestion) {
        return produce(state, (draft) => {
            draft.questions = draft.questions.filter(
                (q) => q.key !== action.question.key,
            );
        });
    }

    if (action instanceof UpdateAnswer) {
        return produce(state, (draft) => {
            draft.questions.some((q) => {
                const index = q.survey_question_answers.findIndex(
                    (a) => a.key === action.answer.key,
                );
                if (index !== undefined) {
                    q.survey_question_answers[index] = { ...action.answer };
                    return true;
                }
            });
        });
    }

    if (action instanceof UpdateQuestion) {
        return produce(state, (draft) => {
            const index = draft.questions.findIndex(
                (q) => q.key === action.question.key,
            );
            draft.questions[index] = { ...action.question };
        });
    }

    return state;
}
