import * as React from 'react';
import { Key, ReactElement, useEffect, useState } from 'react';

import Icon from './Icon';
import Select from './select/Select';

interface IItem {
    key: Key;
    label: string;
    value: string;
}

interface IProps {
    allowBlank?: boolean;
    className: string;
    hasHiddenInput?: boolean;
    id?: string;
    items: IItem[];
    name?: string;
    onChange?: (v: string) => void;
    placeholder: string;
}

export default function SearchInSelect({
    allowBlank = true,
    className,
    hasHiddenInput = true,
    id,
    items,
    name,
    onChange,
    placeholder,
}: IProps): ReactElement {
    const [selected, setSelected] = useState<IItem | null>(
        allowBlank || items?.length === 0 ? null : items[0],
    );

    useEffect(() => {
        onChange?.(selected?.value);
    }, [selected]);

    return (
        <span className="align-items-center d-flex">
            <span className="w-full" style={{ flex: 1 }}>
                <Select
                    onSubmit={setSelected}
                    options={items.map((i) => ({
                        key: i.key,
                        label: i.label,
                        value: i,
                    }))}
                    placement="full-width"
                >
                    <div
                        className={`${className} align-items-center d-flex gap-3 justify-content-between`}
                        role="button"
                    >
                        {selected ? (
                            <span className="truncate">{selected.label}</span>
                        ) : (
                            <span className="truncate text-muted">
                                {placeholder}
                            </span>
                        )}
                        <small>
                            <Icon name="angle-down" />
                        </small>
                    </div>
                </Select>
            </span>

            {allowBlank && selected && (
                <Icon
                    className="ml-2 text-muted"
                    fixedWidth
                    name="circle-xmark"
                    onClick={(): void => setSelected(null)}
                />
            )}

            {hasHiddenInput && (
                <input
                    id={id}
                    name={name}
                    type="hidden"
                    value={selected?.value}
                />
            )}
        </span>
    );
}
