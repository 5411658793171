import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import DateTime from '../common/DateTime';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

interface IProps {
    endsAt: string;
    expended?: boolean;
    name: string;
    startsAt: string;
}

export default function ScorePeriodNameAndDates({
    endsAt,
    expended = false,
    name,
    startsAt,
}: IProps): ReactElement {
    let dates;
    if (expended) {
        dates = (
            <em className="p-2">
                <span className="mr-1">Between</span>
                <DateTime value={startsAt} />
                <span className="ml-1 mr-1">to</span>
                <DateTime value={endsAt} />
            </em>
        );
    } else {
        dates = (
            <Tooltip
                title={`
                ${moment(startsAt)
                    .utcOffset(startsAt)
                    .format('YYYY-MM-DD HH:mm ([GMT]Z)')}
                to
                ${moment(endsAt)
                    .utcOffset(endsAt)
                    .format('YYYY-MM-DD HH:mm ([GMT]Z)')}`}
            >
                <Icon className="ml-2" fixedWidth name="calendar" />
            </Tooltip>
        );
    }
    return (
        <>
            {name}
            {dates}
        </>
    );
}
