import { IError } from 'holberton-school-intranet-api';

type CSRFToken = string;
type URI = string;

//#region private methods

function buildHeaders(csrfToken?: CSRFToken): Record<string, string> {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    if (csrfToken) {
        headers['X-CSRF-TOKEN'] = csrfToken;
    }

    return headers;
}

async function throwErrorIfApplicable(response: Response): Promise<void> {
    if (response.ok || response.redirected) {
        // 2xx || 3xx
        return;
    }

    if (response.status >= 500) {
        // 5xx
        throw new Error('An error occurred. Please retry later.');
    } else {
        // 4xx
        let data: IError | IError[];

        try {
            data = (await response.json()) as IError;
        } catch (err) {
            // Something returned by the server that is not valid JSON
            throw new Error(
                'An error occurred. Check the browser logs for more details.',
            );
        }

        if (Array.isArray(data.error)) {
            if (data.error.length > 0) {
                // i.e. A set of errors sent by ActiveRecord validation
                throw new Error(data.error[0]);
            } else {
                // This should never happen
                throw new Error(
                    'An error occurred. Check the browser logs for more details.',
                );
            }
        } else {
            // i.e. Standalone error sent directly by the server
            throw new Error(data.error);
        }
    }
}

async function formatResponse<Res>(response: Response): Promise<Res> {
    await throwErrorIfApplicable(response);

    // The edge cases below are not ideal but necessary because the endpoints are not consistent in what they return.
    // Reviewing ALL of them would take too much time at the moment.

    // Endpoints returning { head: no_content }
    if (response.status === 204) {
        return {} as Res;
    }

    // Endpoints returning anything else (HTML, etc.) (for example the endpoints executing a .find throwing an HTML error)
    try {
        return (await response.json()) as Res;
    } catch (err) {
        return {} as Res;
    }
}

//#endregion

export async function del<Res = void>(
    uri: URI,
    csrfToken: CSRFToken,
    body?: Record<string, unknown>,
): Promise<Res> {
    const response = await fetch(uri, {
        body: JSON.stringify(body),
        headers: buildHeaders(csrfToken),
        method: 'DELETE',
    });

    return formatResponse(response);
}

export async function get<
    Res = void,
    QP = Record<
        string,
        boolean | number | string | boolean[] | number[] | string[]
    >
>(uri: URI, csrfToken: CSRFToken, params?: QP): Promise<Res> {
    let fullURI = uri;

    if (params) {
        // Not using URLSearchParams because Rails wants arrays to be passed like ids[]=1&ids[]=2 while URLSearchParams passes them like ids=1,2
        const queryParams: [string, string][] = [];

        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((v) =>
                        queryParams.push([`${key}[]`, encodeURI(v)]),
                    );
                } else {
                    // We don't check the type of value, but we assume valid types are passed
                    // For example passing an object will probably don't work as expected
                    queryParams.push([key, encodeURI(value)]);
                }
            }
        });

        fullURI += `?${queryParams
            .map((entries) => entries.join('='))
            .join('&')}`;
    }

    const response = await fetch(fullURI.toString(), {
        headers: buildHeaders(csrfToken),
        method: 'GET',
    });

    return formatResponse(response);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function patch<Res = void, Req = Record<string, any>>(
    uri: URI,
    csrfToken: CSRFToken,
    body: Req,
): Promise<Res> {
    const response = await fetch(uri, {
        body: JSON.stringify(body),
        headers: buildHeaders(csrfToken),
        method: 'PATCH',
    });

    return formatResponse(response);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function post<Res = void, Req = Record<string, any>>(
    uri: URI,
    csrfToken: CSRFToken,
    body: Req,
): Promise<Res> {
    const response = await fetch(uri, {
        body: JSON.stringify(body),
        headers: buildHeaders(csrfToken),
        method: 'POST',
    });

    return formatResponse(response);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function put<Res = void, Req = Record<string, any>>(
    uri: URI,
    csrfToken: CSRFToken,
    body: Req = {} as Req,
): Promise<Res> {
    const response = await fetch(uri, {
        body: JSON.stringify(body),
        headers: buildHeaders(csrfToken),
        method: 'PUT',
    });

    return formatResponse(response);
}

export async function upload<Res = void, Req = Record<string, string>>(
    uri: URI,
    csrfToken: CSRFToken,
    file: File,
    metadata: Req,
): Promise<Res> {
    const headers = buildHeaders(csrfToken);
    delete headers['Content-Type'];

    const formData = new FormData();
    Object.keys(metadata).forEach((key) => {
        formData.append(key, metadata[key]);
    });
    formData.append('file', file);

    const response = await fetch(uri, {
        body: formData,
        headers: headers,
        method: 'POST',
    });

    return formatResponse(response);
}
