import React from 'react';
import { ReactElement, useEffect } from 'react';

import { useSidebar } from '../sidebar/SidebarContext';

import EhubHeader from './EhubHeader';
import Logo from './Logo';

interface IProps {
    ehubHeaderSdkJsUrl: string | null;
    logoUrl: string | null;
    logoClickUrl: string | null;
}

export default function SidebarToggle({
    ehubHeaderSdkJsUrl,
    logoUrl,
    logoClickUrl,
}: IProps): ReactElement {
    const { isCollapsed, toggleSidebar } = useSidebar();

    useEffect(() => {
        document.body.classList.toggle('sidebar-collapsed', isCollapsed);
    }, [isCollapsed]);

    return (
        <>
            <div
                className="header d-flex align-items-center bg-white border-bottom position-fixed top-0 w-full"
                style={{
                    backgroundColor: 'white',
                    borderColor: '#e5e6e6',
                    zIndex: 1000,
                }}
            >
                <div
                    className="header-left align-items-center d-flex position-fixed px-2"
                    style={{ zIndex: 2 }}
                >
                    <div
                        className="hamburger mb-0 p-2"
                        onClick={toggleSidebar}
                        style={{ cursor: 'pointer', fontSize: '24px' }}
                    >
                        <i className="fa-solid fa-fas fa-bars"></i>
                    </div>
                    <Logo logoClickUrl={logoClickUrl} logoUrl={logoUrl} />
                </div>
                <div
                    className="header-right d-flex align-items-center flex-grow-1 justify-content-center"
                    style={{ zIndex: 1 }}
                >
                    {ehubHeaderSdkJsUrl && (
                        <EhubHeader sdkJsUrl={ehubHeaderSdkJsUrl} />
                    )}
                </div>
            </div>
        </>
    );
}
