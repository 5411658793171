import { gantt } from 'dhtmlx-gantt';
import { IBatchTimeOff, ITimeChartEvent } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

import Toolbar from './Toolbar';

export const GANTT_TIME_OFF_CLASS = 'bg-info';

interface IProps {
    data: ITimeChartEvent[];
    showColumns?: boolean;
    showTimeOffs?: boolean;
    showToolbar?: boolean;
    timeOffs?: IBatchTimeOff[];
}

const cellClass = (
    date: Date,
    highlightTimeOffs: boolean,
    timeOffs?: IBatchTimeOff[],
): string => {
    if (
        highlightTimeOffs &&
        timeOffs?.find((v) =>
            moment(date).isBetween(v.start_date, v.end_date, 'day', '[]'),
        )
    ) {
        return GANTT_TIME_OFF_CLASS;
    }

    return '';
};

const setupGantt = (
    hasColumns: boolean,
    highlightTimeOffs: boolean,
    timeOffs?: IBatchTimeOff[],
): void => {
    /* eslint-disable @typescript-eslint/camelcase */
    gantt.config.columns = hasColumns
        ? [
              {
                  label: 'Project',
                  name: 'text',
                  tree: true,
              },
              {
                  align: 'center',
                  label: 'Start time',
                  max_width: 100,
                  name: 'start_date',
              },
              {
                  align: 'center',
                  label: 'Duration',
                  max_width: 100,
                  name: 'duration',
                  template: (event: ITimeChartEvent): string =>
                      event.durationHours || `${event.duration}`,
              },
          ]
        : [];
    gantt.config.autofit = true;
    gantt.config.autosize = 'y';
    gantt.config.details_on_create = false;
    gantt.config.details_on_dblclick = false;
    gantt.config.drag_links = false;
    gantt.config.drag_move = false;
    gantt.config.drag_progress = false;
    gantt.config.grid_width = 500;
    gantt.config.open_tree_initially = true;
    gantt.config.show_links = false;
    gantt.config.min_column_width = 45;
    gantt.config.drag_resize = false;
    gantt.config.scale_height = 50;
    gantt.config.scales = [
        { format: '%F, %Y', step: 1, unit: 'month' },
        {
            css: (date: Date): string => {
                return cellClass(date, highlightTimeOffs, timeOffs);
            },
            format: '%D %j',
            step: 1,
            unit: 'day',
        },
    ];
    gantt.templates.timeline_cell_class = (_task, date): string => {
        return cellClass(date, highlightTimeOffs, timeOffs);
    };
    gantt.templates.grid_file = (): string => '';
    /* eslint-enable @typescript-eslint/camelcase */
};

export default function Gantt({
    data,
    showColumns = true,
    showTimeOffs = true,
    showToolbar = true,
    timeOffs,
}: IProps): ReactElement {
    const [hasColumns, setHasColumns] = useState<boolean>(showColumns);
    const [highlightTimeOffs, setHighlightTimeOffs] = useState<boolean>(
        showTimeOffs,
    );

    const ganttContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => setHasColumns(showColumns), [showColumns]);
    useEffect(() => setHighlightTimeOffs(showTimeOffs), [showTimeOffs]);

    useEffect(() => {
        setupGantt(hasColumns, highlightTimeOffs, timeOffs);
        gantt.init(ganttContainerRef.current);
        gantt.parse({ data });
        gantt.attachEvent(
            'onTaskClick',
            (id) => {
                const task = gantt.getTask(id) as ITimeChartEvent;
                if (task.uri) {
                    const location: string = task.uri;
                    document.location.href = location;
                }
            },
            {},
        );
    }, []);

    useEffect(() => {
        setupGantt(hasColumns, highlightTimeOffs, timeOffs);
        gantt.render();
    }, [hasColumns, highlightTimeOffs]);

    return (
        <div>
            {showToolbar && (
                <Toolbar
                    highlightTimeOffs={highlightTimeOffs}
                    onToggleHighlightTimeOffs={(): void =>
                        setHighlightTimeOffs((v) => !v)
                    }
                    onToggleShowColumns={(): void => setHasColumns((v) => !v)}
                    showColumns={hasColumns}
                />
            )}
            <div ref={ganttContainerRef} />
        </div>
    );
}
