import { ICheatingReport } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { pluralize } from '../../utils';
import DateTime from '../common/DateTime';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

interface IProps {
    canCreate: boolean;
    cheatingReports: ICheatingReport[];
    loading: boolean;
    onCreateCheatingReport: () => void;
    onSelectCheatingReport: (cheatingReport: ICheatingReport) => void;
    selectedCheatingReport: ICheatingReport | null;
}

export default function Explorer({
    canCreate,
    cheatingReports,
    loading,
    onCreateCheatingReport,
    onSelectCheatingReport,
    selectedCheatingReport,
}: IProps): ReactElement {
    return (
        <div className="panel panel-default">
            <div className="panel-heading panel-heading-actions">
                <h3 className="panel-title">
                    {pluralize(cheatingReports.length, 'report')}
                </h3>
                {canCreate &&
                    cheatingReports.every(
                        (cheatingReport) =>
                            cheatingReport.status === 'Completed',
                    ) && (
                        <div>
                            <button
                                className="btn btn-sm btn-primary"
                                disabled={loading}
                                onClick={onCreateCheatingReport}
                            >
                                <Icon className="mr-1" name="plus" />
                                Request a new report
                            </button>
                        </div>
                    )}
            </div>

            <div className="list-group" style={{ flexShrink: 0 }}>
                {cheatingReports.map((cheatingReport) => (
                    <button
                        className={`list-group-item${
                            cheatingReport.id === selectedCheatingReport?.id
                                ? ' active'
                                : ''
                        }`}
                        key={cheatingReport.id}
                        onClick={(): void =>
                            onSelectCheatingReport(cheatingReport)
                        }
                    >
                        <div className="hstack gap-2">
                            <Tooltip title={cheatingReport.status}>
                                <Icon
                                    fixedWidth
                                    name={
                                        cheatingReport.status === 'Completed'
                                            ? 'circle-check'
                                            : cheatingReport.status ===
                                              'Started'
                                            ? 'circle-notch'
                                            : 'clock'
                                    }
                                    spin
                                    style={
                                        cheatingReport.status === 'Started'
                                            ? 'solid'
                                            : 'regular'
                                    }
                                />
                            </Tooltip>
                            <DateTime
                                showTooltip={false}
                                value={cheatingReport.created_at}
                            />
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
}
