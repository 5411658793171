import moment, { Duration } from 'moment';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Icon from '../common/Icon';
import ProgressBar from '../common/ProgressBar';
import useInterval from '../lib/useInterval';

import { useContainerContext } from './ContainerContext';
import { isInProgress } from './api-utils';

export default function TimeProgress(): ReactElement {
    const TIMER_UPDATE_INTERVAL = 1000;

    const { evaluationQuiz, progression } = useContainerContext();

    const [timeLeft, setTimeLeft] = useState<Duration | null>(null);

    useEffect(() => {
        if (isInProgress(progression)) {
            setTimeLeft(moment.duration(progression.time_left, 'seconds'));
        }
    }, [progression]);

    useInterval(() => {
        if (timeLeft && timeLeft.asSeconds() > 0) {
            setTimeLeft(
                timeLeft
                    .clone()
                    .subtract(TIMER_UPDATE_INTERVAL, 'milliseconds'),
            );
        }
    }, TIMER_UPDATE_INTERVAL);

    if (!progression || !isInProgress(progression) || !timeLeft) {
        return null;
    }

    let timeClass = '';
    if (timeLeft.minutes() < 5) {
        timeClass = 'text-danger time-alert';
    } else if (timeLeft.minutes() < 10) {
        timeClass = 'text-danger';
    }

    const timeAllowed = evaluationQuiz.timeAllowed * 60;
    const timeProgress =
        ((timeAllowed - timeLeft.asSeconds()) / timeAllowed) * 100;

    return (
        <div className="time-progress">
            {timeAllowed > 0 && (
                <div>
                    <div className={timeClass}>
                        <Icon name="clock" style="regular" />{' '}
                        <span>
                            {timeLeft
                                .hours()
                                .toString()
                                .padStart(2, '0')}
                            :
                            {timeLeft
                                .minutes()
                                .toString()
                                .padStart(2, '0')}
                            :
                            {timeLeft
                                .seconds()
                                .toString()
                                .padStart(2, '0')}
                        </span>
                    </div>

                    <ProgressBar value={timeProgress} />
                </div>
            )}
        </div>
    );
}
