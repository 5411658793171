import { IUserJourneySession } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import { durationToString, pluralize } from '../../utils';
import Icon from '../common/Icon';

import SessionItem from './SessionItem';

export interface IProps {
    activityDate: string;
    csrfToken: string;
    session: IUserJourneySession;
}

export default function Session({
    activityDate,
    csrfToken,
    session,
}: IProps): ReactElement {
    return (
        <>
            <h3 className="fs-3 mb-2 mt-0">
                {moment(activityDate).format('dddd, MMMM Do YYYY')}:{' '}
                {pluralize(session.items.length, 'session')}
                <Icon className="ml-2" name="clock" style="regular" />{' '}
                {durationToString(session.total_duration)}
                <Icon className="ml-2" name="chart-line" />{' '}
                {session.total_user_engagements}
            </h3>
            <div>
                {session.items.map((si) => (
                    <SessionItem
                        csrfToken={csrfToken}
                        key={si.session_id}
                        sessionItem={si}
                    />
                ))}
            </div>
        </>
    );
}
