import * as React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { gtmUserCustomEvent } from '../../utils';
import ErrorAlert from '../common/ErrorAlert';
import ErrorInline from '../common/ErrorInline';
import Spinner from '../common/Spinner';

import { useContainerContext } from './ContainerContext';
import FocusSupervisor from './FocusSupervisor';
import QuestionProgress from './QuestionProgress';
import Time from './Time';
import TimeProgress from './TimeProgress';
import { doneWithRetake } from './api-rest-adapter';
import { isInProgress } from './api-utils';

export default function Header(): ReactElement {
    const [headerTopPosition, setHeaderTopPosition] = useState<number>(0);
    const [firstUpdatePosition, setFirstUpdatePosition] = useState<boolean>(
        true,
    );
    const context = useContainerContext();
    const {
        error,
        evaluationQuiz,
        firstAttempt,
        progression,
        submitting,
    } = context;
    const headerReference = useRef(null);

    // Run once to get the top position of the header
    useEffect(() => {
        if (headerReference.current) {
            setHeaderTopPosition(
                headerReference.current.getBoundingClientRect().top,
            );
        }
    }, []);

    // On progression / navigation change, scroll to the top of the page
    useEffect(() => {
        if (progression === null) {
            return;
        }

        if (!firstUpdatePosition) {
            document
                .getElementsByTagName('main')[0]
                .scrollTo(0, headerTopPosition);
        } else {
            setFirstUpdatePosition(false);
        }
    }, [progression]);

    const onDoneWithRetakeClick = async (): Promise<void> => {
        await doneWithRetake(context);
        context.setDone(true);
        gtmUserCustomEvent('evaluation_quiz_done_with_retake', {
            evaluationQuizId: evaluationQuiz.id,
        });
    };

    return (
        <>
            <div
                className="vstack gap-2 w-full position-sticky pb-1"
                ref={headerReference}
                style={{ backgroundColor: 'white', top: 0, zIndex: 1 }}
            >
                <h1>{evaluationQuiz.name}</h1>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1" style={{ maxWidth: '250px' }}>
                        {isInProgress(progression) && (
                            <div>
                                {firstAttempt ? (
                                    <TimeProgress />
                                ) : (
                                    <button
                                        className="btn btn-primary"
                                        disabled={submitting}
                                        onClick={onDoneWithRetakeClick}
                                    >
                                        Done with retake!
                                    </button>
                                )}
                                <div className="message text-danger">
                                    <FocusSupervisor />
                                    {error && <ErrorInline error={error} />}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="right">
                        {isInProgress(progression) && <QuestionProgress />}

                        {!isInProgress(progression) &&
                            evaluationQuiz.timeAllowed > 0 && (
                                <Time
                                    label="time allowed"
                                    value={evaluationQuiz.timeAllowed}
                                />
                            )}
                    </div>
                </div>
            </div>

            <div className="pb-5 pt-3">
                {isInProgress(progression) && (
                    <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: progression.html }}
                    />
                )}

                {(!progression || !isInProgress(progression)) && error && (
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <ErrorAlert error={error} />
                        </div>
                    </div>
                )}

                {!progression && submitting && (
                    <div className="my-5">
                        <Spinner />
                    </div>
                )}
            </div>
        </>
    );
}
