import { ICurriculum } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';
import UniqueFieldForm from '../common/UniqueFieldForm';

interface IProps {
    canEdit: boolean;
    csrfToken: string;
    curriculum: ICurriculum;
    updateCurriculumURI: string;
}

export default function CurriculumName({
    canEdit,
    csrfToken,
    curriculum: { certified, name: initialName, status },
    updateCurriculumURI,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [name, setName] = useState<string>(initialName);

    return (
        <>
            <h1 className="align-items-center d-flex gap-3">
                {certified && (
                    <Tooltip title="This curriculum is certified">
                        <Icon className="text-primary" name="circle-check" />
                    </Tooltip>
                )}

                <UniqueFieldForm
                    canEdit={canEdit}
                    csrfToken={csrfToken}
                    name="name"
                    onError={setError}
                    onUpdate={(res: ICurriculum): void => setName(res.name)}
                    requestBodyContainer="curriculum"
                    updateURI={updateCurriculumURI}
                    value={name}
                />

                <span
                    className={`fs-3 label label-${
                        status.variants[status.key]
                    } ml-2`}
                >
                    {status.human}
                </span>
            </h1>

            {error && <ErrorInline error={error} />}
        </>
    );
}
