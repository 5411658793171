import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import Tooltip from './Tooltip';

interface IProps {
    showDate?: boolean;
    showSeconds?: boolean;
    showTime?: boolean;
    showTooltip?: boolean;
    value: string;
}

export default function DateTime({
    showDate = true,
    showSeconds = false,
    showTime = true,
    showTooltip = true,
    value,
}: IProps): ReactElement {
    const renderContent = (): ReactElement => (
        <span className={showTooltip ? `datetime` : ''}>
            {moment(value).format(
                // format('LTS') was added in 2.8.4
                // Currently moment.js version => 2.29.4
                `${showDate ? 'll' : ''} ${
                    showTime && !showSeconds ? 'LT' : ''
                }${showTime && showSeconds ? 'HH:mm:ss' : ''}`,
            )}
        </span>
    );

    if (!showTooltip) {
        return renderContent();
    }

    return (
        <Tooltip
            title={moment(value)
                .utcOffset(value)
                .format(
                    `${showDate ? 'YYYY-MM-DD ' : ''}${
                        showTime
                            ? `HH:mm${showSeconds ? ':ss' : ''} ([GMT]Z)`
                            : ''
                    }`,
                )}
        >
            {renderContent()}
        </Tooltip>
    );
}
