import * as React from 'react';
import { ReactElement } from 'react';

import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';

import UserContainerActions from './UserContainerActions';
import UserContainerDetail from './UserContainerDetail';
import { IAction, IUserContainerState } from './reducer';

export interface IProps {
    containerModelName: string;
    csrfToken: string;
    dispatch: (value: IAction) => void;
    startStatusURI: string;
    startURI: string;
    state: IUserContainerState;
}

export default function UserContainer({
    containerModelName,
    csrfToken,
    dispatch,
    startStatusURI,
    startURI,
    state,
}: IProps): ReactElement {
    return (
        <div className="panel panel-default">
            <div
                className={`panel-body${
                    state.containerSpec.container?.status === 'asleep'
                        ? ' border-left-info'
                        : state.containerSpec.container
                        ? ' border-left-success'
                        : ''
                }`}
                style={{
                    borderLeft: state.containerSpec.container
                        ? '6px solid'
                        : undefined,
                }}
            >
                <div className="align-items-center d-flex flex-wrap justify-content-between">
                    <div>
                        <h3 className="mt-0">
                            <Icon
                                className={
                                    state.containerSpec.container?.status ===
                                    'asleep'
                                        ? 'text-info'
                                        : state.containerSpec.container
                                        ? 'text-success'
                                        : 'text-danger'
                                }
                                name="terminal"
                            />
                            <span className="ml-2">
                                {state.containerSpec.name}
                            </span>
                            {!state.containerSpec.online && (
                                <span className="fs-4 label label-danger ml-2">
                                    Offline
                                </span>
                            )}
                            {!state.containerSpec.available && (
                                <span className="fs-4 label label-warning ml-2">
                                    Unavailable
                                </span>
                            )}
                            {state.containerSpec.container?.status ===
                                'asleep' && (
                                <span className="fs-4 label label-info ml-2">
                                    Asleep
                                </span>
                            )}
                        </h3>

                        <div
                            className="mt-2 text-muted"
                            dangerouslySetInnerHTML={{
                                __html: state.containerSpec.description,
                            }}
                        />
                    </div>

                    {state.loading && (
                        <div className="text-info">{state.info}</div>
                    )}
                    {state.error && <ErrorInline error={state.error} />}

                    <UserContainerActions
                        containerModelName={containerModelName}
                        csrfToken={csrfToken}
                        dispatch={dispatch}
                        startStatusURI={startStatusURI}
                        startURI={startURI}
                        state={state}
                    />
                </div>

                {!state.loading &&
                    state.containerSpec.container?.status === 'running' && (
                        <UserContainerDetail
                            container={state.containerSpec.container}
                            key={state.containerSpec.container.id}
                        />
                    )}
            </div>
        </div>
    );
}
