import {
    GroupNaming,
    IGroupActivityGroupUser,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import SearchAndSelect, { OnSelectHandler } from '../search/SearchAndSelect';

export type OnCancelClickHandler = () => void;
export type OnMoveClickHandler = (
    user: IGroupActivityGroupUser,
) => Promise<void>;

interface IProps {
    csrfToken: string;
    disabled: boolean;
    editing: boolean;
    groupNaming: GroupNaming;
    onCancelMovingToAnotherGroupClick: OnCancelClickHandler;
    onMoveToAnotherGroupClick: OnMoveClickHandler;
    onMoveToNewGroupClick: OnMoveClickHandler;
    onRemoveFromGroupClick?: OnMoveClickHandler;
    onSelectPeerToMoveWith: OnSelectHandler;
    searchURI: string;
    user: IGroupActivityGroupUser;
}

export default function UserControls({
    csrfToken,
    disabled,
    editing,
    groupNaming,
    onCancelMovingToAnotherGroupClick,
    onMoveToAnotherGroupClick,
    onMoveToNewGroupClick,
    onRemoveFromGroupClick,
    onSelectPeerToMoveWith,
    searchURI,
    user,
}: IProps): ReactElement {
    return (
        <div>
            {!editing && (
                <div className="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        className="btn btn-sm btn-default dropdown-toggle"
                        data-toggle="dropdown"
                        disabled={disabled}
                        type="button"
                    >
                        <Icon fixedWidth name="ellipsis-vertical" />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                            <a
                                onClick={(): Promise<void> =>
                                    onMoveToAnotherGroupClick(user)
                                }
                                role="button"
                            >
                                Move with another peer
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(): Promise<void> =>
                                    onMoveToNewGroupClick(user)
                                }
                                role="button"
                            >
                                Move to a new {groupNaming}
                            </a>
                        </li>
                        {onRemoveFromGroupClick && (
                            <>
                                <li className="divider" role="separator" />
                                <li>
                                    <a
                                        onClick={(): Promise<void> =>
                                            onRemoveFromGroupClick(user)
                                        }
                                        role="button"
                                    >
                                        <span className="text-danger">
                                            Remove from {groupNaming}
                                        </span>
                                    </a>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            )}

            {editing && (
                <div className="d-flex">
                    <div
                        /* Handle overlaps on search */
                        style={{
                            backgroundColor: '#FFF',
                            borderRadius: '4px',
                            marginLeft: '-180px',
                            position: 'absolute',
                            width: '180px',
                        }}
                    >
                        <SearchAndSelect
                            csrfToken={csrfToken}
                            model="users"
                            name="user_id"
                            onSelect={onSelectPeerToMoveWith}
                            placeholder="Search for peers"
                            searchURI={searchURI}
                            selectedItem={null}
                        />
                    </div>

                    <button
                        className="btn btn-default text-muted"
                        onClick={onCancelMovingToAnotherGroupClick}
                    >
                        <Icon color="#9EB2B9" fixedWidth name="xmark" />
                    </button>
                </div>
            )}
        </div>
    );
}
