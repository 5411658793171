import * as React from 'react';
import { ReactElement, useState } from 'react';

import { patch } from '../../api/utils';
import { gtmUserCustomEvent } from '../../utils';
import ErrorAlert from '../common/ErrorAlert';
import Icon from '../common/Icon';

import ProjectReadyForReviewBody, {
    IProjectPeerReview,
} from './ProjectReadyForReviewBody';

export interface IProps {
    csrfToken: string;
    firstReview: boolean;
    peerReview: IProjectPeerReview;
    toggled: boolean;
}

export default function ProjectReadyForReview({
    csrfToken,
    firstReview,
    peerReview,
    toggled,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [readyForReview, setReadyForReview] = useState<boolean>(toggled);

    const sendRequest = async (uri: string): Promise<void> => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            await patch(uri, csrfToken, {});
            setReadyForReview(!readyForReview);
            setError(null);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const onUpdateReadyForReview = async (): Promise<void> => {
        sendRequest(peerReview.readyForReviewURI);
    };

    const onCancelReadyForReview = async (): Promise<void> => {
        sendRequest(peerReview.cancelReadyForReviewURI);
    };

    return (
        <div className="row gap">
            <div className="col-md-12">
                {!readyForReview && (
                    <div className="text-center">
                        <button
                            className="btn btn-lg btn-primary"
                            disabled={loading}
                            onClick={(): void => {
                                if (
                                    confirm(
                                        'Are you sure? your project will become available for review',
                                    )
                                ) {
                                    onUpdateReadyForReview();
                                    gtmUserCustomEvent(
                                        'project_ready_for_review',
                                    );
                                } else {
                                    gtmUserCustomEvent(
                                        'project_ready_for_review_cancel',
                                    );
                                }
                            }}
                        >
                            {loading && (
                                <Icon fixedWidth name="spinner" pulse />
                            )}
                            Ready for a {firstReview ? '' : 'new'} review
                        </button>
                    </div>
                )}

                {readyForReview && (
                    <>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">
                                    Ready for review
                                </h3>
                            </div>

                            <div className="panel-body">
                                <ProjectReadyForReviewBody
                                    csrfToken={csrfToken}
                                    peerReview={peerReview}
                                />
                            </div>
                        </div>
                        {firstReview ? (
                            <p>
                                <button
                                    className="btn btn-link"
                                    disabled={loading}
                                    onClick={(): void => {
                                        if (
                                            confirm(
                                                'Are you sure? Your project will no longer be available for peer review',
                                            )
                                        ) {
                                            onCancelReadyForReview();
                                            gtmUserCustomEvent(
                                                'project_ready_for_review_revert',
                                            );
                                        } else {
                                            gtmUserCustomEvent(
                                                'project_ready_for_review_revert_cancel',
                                            );
                                        }
                                    }}
                                >
                                    Still some work to do? Cancel readiness for
                                    peer review here.
                                </button>
                            </p>
                        ) : null}
                    </>
                )}

                {error && (
                    <div className="sm-gap">
                        <ErrorAlert error={error} />
                    </div>
                )}
            </div>
        </div>
    );
}
