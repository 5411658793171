import { SearchModel } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Icon from '../common/Icon';

import { ISearchResult } from './SearchBar';
import SearchBarWithResults from './SearchBarWithResults';

export type OnSelectHandler = (item: ISearchResult) => Promise<void>;

export interface IProps {
    csrfToken: string;
    id?: string;
    model: SearchModel;
    name?: string;
    onClear?: () => Promise<void>;
    onSelect?: OnSelectHandler;
    placeholder: string;
    searchURI: string;
    selectedItem: ISearchResult | null;
}

export default function SearchAndSelect({
    csrfToken,
    id,
    name,
    model,
    onClear,
    onSelect,
    placeholder,
    searchURI,
    selectedItem,
}: IProps): ReactElement {
    const [selected, setSelected] = useState<ISearchResult | null>(
        selectedItem,
    );

    useEffect(() => {
        // The value of the local state needs to change if the prop changes
        setSelected(selectedItem);
    }, [selectedItem]);

    const renderItem = (item: ISearchResult): ReactElement => {
        return (
            <div
                className="list-group-item"
                key={item.id}
                onClick={(): void => {
                    setSelected(item);
                    if (onSelect) {
                        onSelect(item);
                    }
                }}
                role="button"
            >
                <code>{item.id}</code>
                <span className="ml-2">{item.label}</span>
            </div>
        );
    };

    return (
        <>
            {name && id && (
                <input
                    id={id}
                    name={name}
                    type="hidden"
                    value={selected?.id || ''}
                />
            )}

            {selected ? (
                <div className="input-group">
                    <div className="form-control">
                        <code>{selected.id}</code>
                        <span className="ml-2">{selected.label}</span>
                    </div>
                    <span
                        className="input-group-addon"
                        onClick={(): void => {
                            setSelected(null);
                            if (onClear) {
                                onClear();
                            }
                        }}
                        role="button"
                    >
                        <Icon color="#9EB2B9" fixedWidth name="xmark" />
                    </span>
                </div>
            ) : (
                <SearchBarWithResults
                    csrfToken={csrfToken}
                    model={model}
                    placeholder={placeholder}
                    renderItem={renderItem}
                    searchURI={searchURI}
                />
            )}
        </>
    );
}
