import * as React from 'react';
import {
    ChangeEvent,
    Key,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from 'react';

import ErrorInline from '../ErrorInline';
import Icon from '../Icon';

import SelectOption from './SelectOption';

export interface IOption<T> {
    destroyable?: boolean;
    key?: Key;
    label: string;
    value: T;
}

interface IProps<T> {
    children: ReactNode;
    disabled?: boolean;
    inputId?: string;
    onCreateOption?: (option: string) => Promise<void>;
    onDestroyOption?: (option: T) => Promise<void>;
    onEditOption?: (option: T, name: string) => Promise<void>;
    onHide?: () => void;
    onShow?: () => void;
    onSubmit: (option: T) => void;
    options: IOption<T>[];
    placement?: 'full-width' | 'left' | 'right';
    searchable?: boolean;
    value?: T | T[];
}

function isSelected<T>(option: IOption<T>, value?: T | T[]): boolean {
    if (value instanceof Array) {
        return value.some((v) => option.value === v);
    }
    return option.value === value;
}

export default function Select<T>({
    children,
    disabled = false,
    inputId,
    onCreateOption,
    onDestroyOption,
    onEditOption,
    onHide,
    onShow,
    onSubmit,
    options,
    placement = 'left',
    searchable = true,
    value,
}: IProps<T>): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [popupElement, setPopupElement] = useState<HTMLDivElement>(null);

    const checkClickOutside = (e: MouseEvent): void => {
        if (popupElement && !popupElement.contains(e.target as Node)) {
            setShowDialog(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', checkClickOutside);

        return (): void => {
            document.removeEventListener('mousedown', checkClickOutside);
        };
    }, [checkClickOutside, popupElement]);

    useEffect(() => {
        showDialog ? onShow?.() : onHide?.();
    }, [showDialog]);

    const onClickOption = (option: IOption<T>): void => {
        setQuery('');
        setShowDialog(false);
        onSubmit(option.value);
    };

    const onClickCreateOption = async (): Promise<void> => {
        if (loading) {
            return;
        }

        setError(null);
        setLoading(true);
        try {
            await onCreateOption(query);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setError(null);
        setQuery(e.target.value);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div
                onClick={(): void => !disabled && setShowDialog(true)}
                role={disabled ? undefined : 'button'}
            >
                {children}
            </div>
            {!disabled && showDialog && (
                <div
                    className="panel panel-default w-full"
                    ref={setPopupElement}
                    style={{
                        left: placement === 'left' ? 0 : undefined,
                        maxWidth:
                            placement !== 'full-width' ? '500px' : undefined,
                        minWidth: '180px',
                        position: 'absolute',
                        right: placement === 'right' ? 0 : undefined,
                        top: '110%',
                        zIndex: 2,
                    }}
                >
                    {searchable && (
                        <div className="border-bottom border-gray p-2">
                            <input
                                autoComplete="off" // To avoid browser's suggestions to be displayed on top of our suggestions
                                autoFocus
                                className="form-control"
                                disabled={disabled}
                                id={inputId}
                                onChange={onInputChange}
                                placeholder="Search ..."
                                type="search"
                                value={query}
                            />
                        </div>
                    )}

                    <div
                        className="list-group"
                        style={{ maxHeight: '20rem', overflowY: 'auto' }}
                    >
                        {options
                            .filter((opt) => {
                                const words = query.split(/ +/);
                                return words.every((w) => {
                                    return opt.label
                                        .toLowerCase()
                                        .includes(w.toLowerCase());
                                });
                            })
                            .map((option) => (
                                <SelectOption
                                    key={option.key || option.label}
                                    onClickOption={onClickOption}
                                    onDestroyOption={onDestroyOption}
                                    onEditOption={onEditOption}
                                    option={option}
                                    selected={isSelected(option, value)}
                                />
                            ))}
                        {onCreateOption &&
                            query.length > 0 &&
                            !options.some(
                                (opt) =>
                                    opt.label.toLocaleLowerCase() ===
                                    query.toLocaleLowerCase(),
                            ) && (
                                <a
                                    className="list-group-item"
                                    onClick={onClickCreateOption}
                                    role={loading ? undefined : 'button'}
                                >
                                    <div className="text-center">
                                        {loading ? (
                                            <Icon name="spinner" pulse />
                                        ) : (
                                            <span className="fw-bold text-primary">
                                                Create &quot;{query}&quot;
                                            </span>
                                        )}
                                    </div>

                                    {error && <ErrorInline error={error} />}
                                </a>
                            )}
                    </div>
                </div>
            )}
        </div>
    );
}
