import { IUserJourneyWeeklyWorkHabit } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { durationToString } from '../../utils';
import Icon from '../common/Icon';

export interface IProps {
    weeklyWorkHabit: IUserJourneyWeeklyWorkHabit;
}

export default function WeeklyWorkHabitSummary({
    weeklyWorkHabit,
}: IProps): ReactElement {
    return (
        <div className="d-flex flex-column gap-2">
            <h3 className="fs-3 mb-2 mt-1">Weekly Work Habit </h3>
            <span className="align-items-center d-flex gap-2 text-muted">
                <Icon fixedWidth name="calendar" style="regular" /> Favorite
                weekday: {weeklyWorkHabit.favorite_weekday_name || '--'}
            </span>
            <span className="align-items-center d-flex gap-2 text-muted">
                <Icon fixedWidth name="clock" style="regular" /> Favorite time
                of day:{' '}
                {weeklyWorkHabit.favorite_time_of_day ||
                weeklyWorkHabit.favorite_time_of_day === 0
                    ? `${weeklyWorkHabit.favorite_time_of_day
                          .toString()
                          .padStart(2, '0')}:00 UTC`
                    : '--'}
            </span>
            <span className="align-items-center d-flex gap-2 text-muted">
                <Icon fixedWidth name="hourglass-half" /> Avg. duration per time
                slot:{' '}
                {weeklyWorkHabit.average_duration
                    ? durationToString(weeklyWorkHabit.average_duration)
                    : '--'}
            </span>
            <span className="align-items-center d-flex gap-2 text-muted">
                <Icon fixedWidth name="chart-pie" /> Distribution rate:{' '}
                {weeklyWorkHabit.distribution_rate || '--'}
            </span>
            <span className="align-items-center d-flex gap-2 text-muted">
                <Icon fixedWidth name="gauge-simple-high" /> Weekly duration:{' '}
                {weeklyWorkHabit.total_hours || '--'}
                {'h'}
            </span>
        </div>
    );
}
