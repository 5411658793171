import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';

interface IProps {
    fontSize?: number;
    label: string;
    onDeleteClick?: () => void;
    variant?: 'default' | 'info' | 'primary';
}

export default function Tag({
    fontSize = 14,
    label,
    onDeleteClick,
    variant = 'primary',
}: IProps): ReactElement {
    return (
        <span className={`label label-${variant}`} style={{ fontSize }}>
            {label}

            {onDeleteClick && (
                <span
                    className="ml-2"
                    onClick={onDeleteClick}
                    style={{ cursor: 'pointer' }}
                >
                    <Icon name="xmark" />
                </span>
            )}
        </span>
    );
}
