import {
    IProject,
    IProjectMetadata,
    IProjectProgress,
    ITag,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Icon from '../common/Icon';
import { ILink } from '../common/types';
import Tags from '../tags/Tags';

import ProjectMetadata from './ProjectMetadata';
import ProjectCircle from './pathway/ProjectCircle';

interface IProps {
    metadata: IProjectMetadata;
    project: IProject;
    tags: ITag[];
    videoRoomLink: ILink | null;
}

export default function ProjectHeader({
    metadata,
    project,
    tags,
    videoRoomLink,
}: IProps): ReactElement {
    const [updatedProject, setUpdatedProject] = useState<IProject>(project);

    const onProgressChanged = (e: CustomEvent<IProjectProgress>): void => {
        setUpdatedProject((prev) => ({
            ...prev,
            completion: e.detail.summary.completion,
            score: e.detail.summary.score,
        }));
    };

    useEffect(() => {
        document.addEventListener(
            'project_progress_changed',
            onProgressChanged,
        );

        return (): void => {
            document.removeEventListener(
                'project_progress_changed',
                onProgressChanged,
            );
        };
    }, []);

    return (
        <div className="panel panel-tile project-header">
            <div
                className="panel-body"
                style={{ alignItems: 'center', display: 'flex' }}
            >
                <div className="pathway" style={{ padding: '16px' }}>
                    <ProjectCircle
                        placement="tile"
                        project={updatedProject}
                        scale={1.5}
                        withShadow={false}
                    />
                </div>
                <div
                    className="project-info"
                    style={{ flexBasis: '100%', margin: '16px' }}
                >
                    <h1 style={{ margin: 0 }}>{updatedProject.name}</h1>

                    <Tags tags={tags} />

                    <ProjectMetadata metadata={metadata} />

                    {videoRoomLink && (
                        <div
                            className="actions"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '5px',
                            }}
                        >
                            {videoRoomLink && (
                                <a
                                    className="btn btn-outline-primary"
                                    href={videoRoomLink.href}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <Icon
                                        name="video"
                                        text={videoRoomLink.text}
                                    />
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
