import * as React from 'react';
import { CSSProperties, ReactElement } from 'react';

import { IAction, IState, ToggleFilter } from './reducer';
import { styleAt } from './utils';

interface IProps {
    dispatch: (action: IAction) => void;
    state: IState;
}

export default function Legend({ dispatch, state }: IProps): ReactElement {
    const styleAtIdx = (
        cIdx: number,
        lIdx: number,
        sat = 75,
    ): CSSProperties => {
        return {
            color: styleAt(cIdx, state.data.cohorts.length, lIdx, {
                saturation: sat,
            }).backgroundColor,
        };
    };

    return (
        <div className="d-flex flex-column gap-5">
            <span className="fs-3 fw-600 mb-2 sm-gap">Legend</span>

            <div className="d-flex flex-column gap-2">
                <span className="fs-small fw-600">Global</span>
                <label className="align-items-center d-flex gap-2">
                    <input
                        checked={state.filters.events}
                        className="colored-checkbox text-primary"
                        onChange={(): void =>
                            dispatch(new ToggleFilter('events'))
                        }
                        type="checkbox"
                    />
                    <span className="fw-400">Events</span>
                </label>
            </div>

            {state.filters.cohorts.map((cohort, cIdx) => (
                <div className="d-flex flex-column gap-2" key={cohort.title}>
                    <span className="fs-small fw-600">{cohort.title}</span>

                    <div className="d-flex flex-column gap-2">
                        {cohort.locations.map((location, lIdx) => (
                            <div
                                className="d-flex flex-column gap-2"
                                key={location.id}
                            >
                                {cohort.locations.length > 1 && (
                                    <span className="fs-small fw-500">
                                        {location.name}
                                    </span>
                                )}

                                <label className="align-items-center d-flex gap-2">
                                    <input
                                        checked={
                                            state.filters.cohorts[cIdx]
                                                .locations[lIdx].sessions
                                        }
                                        className="colored-checkbox"
                                        onChange={(): void =>
                                            dispatch(
                                                new ToggleFilter(
                                                    'sessions',
                                                    cIdx,
                                                    lIdx,
                                                ),
                                            )
                                        }
                                        style={styleAtIdx(cIdx, lIdx)}
                                        type="checkbox"
                                    />
                                    <span className="fw-400">Sessions</span>
                                </label>

                                <label className="align-items-center d-flex gap-2">
                                    <input
                                        checked={
                                            state.filters.cohorts[cIdx]
                                                .locations[lIdx].timeOffs
                                        }
                                        className="colored-checkbox"
                                        onChange={(): void =>
                                            dispatch(
                                                new ToggleFilter(
                                                    'timeOffs',
                                                    cIdx,
                                                    lIdx,
                                                ),
                                            )
                                        }
                                        style={styleAtIdx(cIdx, lIdx, 45)}
                                        type="checkbox"
                                    />
                                    <span className="fw-400">Time off</span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
